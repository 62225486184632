import RewardServices, { TCreateRewardData } from "services/reward";
import { useNavigate, useParams } from "react-router-dom";

import FieldList from "components/common/FieldList";
import FileServices from "services/file";
import Header from "components/common/Header";
import { TCreateCouponData } from "services/coupon";
import useAlert from "hooks/useAlert";
import { useEffect } from "react";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

type TCreateReward = {
  image: FileList;
  image_disable: FileList;
  spend: string;
  cashback: string;
};

const CreateReward = () => {
  let line_oa_code = "ONLY_GOLF";
  const navigate = useNavigate();
  const { token } = useToken();
  const { onAlert } = useAlert();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {};

  const onSubmit = async (values: TCreateReward) => {
    let data: TCreateRewardData = {
      image: "",
      image_disable: "",
      spend: parseInt(values.spend),
      cashback: parseInt(values.cashback),
    };

    let isError = false;
    let formDataImage = new FormData();
    formDataImage.append("file", values.image[0]);
    formDataImage.append("prefix", "reward");
    formDataImage.append("path", "/reward");
    await FileServices.create(token as string, formDataImage)
      .then((res) => {
        data.image = res.data.data;
      })
      .catch((err) => {
        isError = true;
        onAlert("error", err.response.data.message);
      });
    let formDataImageDisable = new FormData();
    formDataImageDisable.append("file", values.image_disable[0]);
    formDataImageDisable.append("prefix", "reward");
    formDataImageDisable.append("path", "/reward");
    await FileServices.create(token as string, formDataImageDisable)
      .then((res) => {
        data.image_disable = res.data.data;
      })
      .catch((err) => {
        isError = true;
        onAlert("error", err.response.data.message);
      });
    if (!isError) {
      RewardServices.create(token as string, line_oa_code as string, data)
        .then((res) => {
          onAlert("success", "สร้างรางวัลสำเร็จ");
          navigate(`/reward/only-golf/list`);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    }
  };

  return (
    <>
      <Header
        title="Create a new reward"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: `/dashboard`,
          },
          {
            label: "Only Golf",
            href: `/dashboard/only-golf`,
          },
          {
            label: "Reward",
            href: "/reward/only-golf/list",
          },
          {
            label: "New reward",
          },
        ]}
      />
      <FieldList
        type="create"
        onSubmit={onSubmit}
        fields={[
          {
            label: "Cover Image (Available)",
            type: "image",
            name: "image",
            inputOptions: {
              required: "กรุณาใส่รูปภาพประกอบ",
            },
            col: 6,
            tooltip:
              "สำหรับกำหนดรูปภาพแสดงผลของรางวัล เมื่อลูกค้ามียอดสั่งซื้อถึงจำนวนกำหนด",
          },
          {
            label: "Cover Image (Disable)",
            type: "image",
            name: "image_disable",
            inputOptions: {
              required: "กรุณาใส่รูปภาพประกอบ",
            },
            col: 6,
            tooltip:
              "สำหรับกำหนดรูปภาพแสดงผลของรางวัล เมื่อลูกค้ายอดสั่งซื้อไม่ถึงจำนวนที่กำหนด",
          },
          {
            label: "Spending",
            type: "number",
            name: "spend",
            inputOptions: {
              required: "กรุณากรอกยอดสั่งซื้อสะสมที่ต้องใช้",
            },
            col: 6,
            tooltip:
              "สำหรับกำหนดยอดสั่งซื้อสะสมของรางวัล ที่ลูกค้าต้องสะสมให้ถึงจำนวนเพื่อรับรางวัล",
          },
          {
            label: "Cashback",
            type: "number",
            name: "cashback",
            inputOptions: {
              required: "กรุณากรอกเงินรางวัล",
            },
            col: 6,
            tooltip: "สำหรับกำหนดจำนวนเงินรางวัลที่ลูกค้าจะได้รับ",
          },
        ]}
        onBack={() => navigate(`/reward/only-golf/list`)}
      />
    </>
  );
};

export default withAuth(withPermission(CreateReward, "MANAGE_ONLY_GOLF"));
