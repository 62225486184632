import CreateRole from "pages/manage-admin/role/create";
import EditRole from "pages/manage-admin/role/edit";
import ListRole from "pages/manage-admin/role/list";
import RoleDetail from "pages/manage-admin/role/detail";
import { RouteObject } from "react-router-dom";

const RoleRouters: RouteObject[] = [
  {
    path: "/role/list",
    element: <ListRole />,
  },
  {
    path: "/role/create",
    element: <CreateRole />,
  },
  {
    path: "/role/:id",
    element: <RoleDetail />,
  },
  {
    path: "/role/:id/edit",
    element: <EditRole />,
  },
];

export default RoleRouters;
