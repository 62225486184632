import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  Modal,
} from "@mui/material";
import { IPagination, IPaginationRequest } from "interfaces/pagination";
import RewardRedemptionServices, {
  TDoneRewardRedemptionData,
} from "services/rewardRedemption";
import { useCallback, useEffect, useState } from "react";

import AvatarLetter from "components/common/AvatarLetter";
import FileServices from "services/file";
import Header from "components/common/Header";
import { IReward } from "interfaces/reward";
import { IRewardRedemption } from "interfaces/rewardRedemption";
import { OpenInNew } from "@mui/icons-material";
import Swal from "sweetalert2";
import Table from "components/common/Table";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

interface IRewardRedemptionData {
  id: number;
  code: string;
  name: string;
  reward: IReward;
  // bank_name: string;
  // bank_account_name: string;
  // bank_account_number: string;
  redeemed_at: string;
  // image: string | null;
  // is_pending: boolean;
  // last_edited_at: string;
  // edited_by: string;
}

const ListRewardRedemption = () => {
  const line_oa_code = "ONLY_GOLF";
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [data, setData] = useState<{
    data: IRewardRedemptionData[];
    pagination: IPagination;
  }>({
    data: [],
    pagination: {
      page: 1,
      total_item: 0,
      total_page: 0,
    },
  });
  const [search, setSearch] = useState<string>("");
  const [filter, setFilter] = useState<{
    status: string;
    date: {
      start: Date | null;
      end: Date | null;
    };
  }>({ status: "all", date: { start: null, end: null } });
  const [pagination, setPagination] = useState<IPaginationRequest>({
    page: 1,
    per_page: 10,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchData = useCallback(() => {
    RewardRedemptionServices.getAll(
      token as string,
      line_oa_code as string,
      {
        per_page: pagination.per_page,
        page: pagination.page,
      },
      search,
      filter.status,
      {
        start: filter.date.start
          ? dayjs(filter.date.start.toString()).format("YYYY-MM-DD")
          : "",
        end: filter.date.end
          ? dayjs(filter.date.end.toString()).format("YYYY-MM-DD")
          : "",
      }
    )
      .then((res) => {
        let paginationRes: IPagination = res.data.pagination;
        setData({
          data: res.data.data.map((d: IRewardRedemption) => {
            return {
              id: d.id,
              code: d.code,
              name: `${d.member.first_name} ${d.member.last_name}`,
              reward: d.reward,
              // bank_name: d.bank.bank.name,
              // bank_account_name: d.bank.account_name,
              // bank_account_number: d.bank.account_number,
              redeemed_at: d.redeemed_at,
              // image: d.image,
              // is_pending: d.is_pending,
              // last_edited_at: d.last_edited_at,
              // edited_by: d.admin_edited.name,
            } as IRewardRedemptionData;
          }),
          pagination: {
            page: paginationRes.page,
            total_item: paginationRes.total_item,
            total_page: paginationRes.total_page,
          },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
      });
  }, [pagination, onAlert, token, search, filter]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onExort = useCallback(() => {
    RewardRedemptionServices.export(
      token as string,
      line_oa_code as string,
      search,
      filter.status,
      {
        start: filter.date.start
          ? dayjs(filter.date.start.toString()).format("YYYY-MM-DD")
          : "",
        end: filter.date.end
          ? dayjs(filter.date.end.toString()).format("YYYY-MM-DD")
          : "",
      }
    )
      .then((res) => {
        window.location.href = process.env.REACT_APP_FILE + res.data.data;
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
      });
  }, [token, search, filter, onAlert]);

  return (
    <>
      <Header
        title="Reward Redemption"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: `/dashboard`,
          },
          {
            label: "Only Golf",
            href: `/dashboard/only-golf`,
          },
          {
            label: "Reward Redemption",
            href: "/reward-redemption/only-golf/list",
          },
          {
            label: "List",
          },
        ]}
      />
      <Table
        headers={[
          {
            key: "name",
            label: "Member",
            // render: (value) => (
            //   <Box
            //     sx={{
            //       display: "flex",
            //       alignItems: "center",
            //     }}
            //   >
            //     <AvatarLetter label={value} />
            //     <Typography sx={{ ml: 2 }}>{value}</Typography>
            //   </Box>
            // ),
          },
          {
            key: "reward",
            label: "Cashback",
            render: (value, index, item: IRewardRedemptionData) => (
              <Typography sx={{ ml: 2 }}>
                {item.reward.cashback.toLocaleString()}
              </Typography>
            ),
          },
          {
            key: "code",
            label: "Code",
          },
          // {
          //   key: "bank_name",
          //   label: "Bank",
          //   render: (
          //     value,
          //     index,
          //     rewardRedemtionData: IRewardRedemptionData
          //   ) => {
          //     return (
          //       <>
          //         <Typography>
          //           <b>Bank:</b>
          //           <br />
          //           <span>{rewardRedemtionData.bank_name}</span>
          //         </Typography>
          //         <Typography>
          //           <b>Account:</b>
          //           <br />
          //           <span>{rewardRedemtionData.bank_account_name}</span>
          //         </Typography>
          //         <Typography>
          //           <b>Account NO.:</b>
          //           <br />
          //           <span>{rewardRedemtionData.bank_account_number}</span>
          //         </Typography>
          //       </>
          //     );
          //   },
          // },
          {
            key: "redeemed_at",
            label: "Redeemed At",
            render: (value) => (
              <Typography>{new Date(value).toLocaleString("th-TH")}</Typography>
            ),
          },
          // {
          //   key: "is_pending",
          //   label: "Status",
          //   render: (
          //     value,
          //     index,
          //     rewardRedemtionData: IRewardRedemptionData
          //   ) => {
          //     return (
          //       <>
          //         <Chip
          //           label={value ? "PENDING" : "SUCCESS"}
          //           color={value ? "error" : "success"}
          //         />
          //         <br />
          //         {rewardRedemtionData.image && (
          //           <a
          //             href={
          //               process.env.REACT_APP_FILE + rewardRedemtionData.image
          //             }
          //             target="_blank"
          //             rel="noreferrer"
          //           >
          //             <Typography sx={{ mt: 1 }} color="blue">
          //               <u>
          //                 see image <OpenInNew sx={{ fontSize: 10 }} />
          //               </u>
          //             </Typography>
          //           </a>
          //         )}
          //       </>
          //     );
          //   },
          // },
          // {
          //   key: "edited_by",
          //   label: "Edited By",
          //   render: (
          //     value,
          //     index,
          //     rewardRedemtionData: IRewardRedemptionData
          //   ) => {
          //     if (rewardRedemtionData.edited_by === "") {
          //       return <Typography>NULL</Typography>;
          //     }
          //     return <AvatarLetter label={value} tooltip />;
          //   },
          // },
          // {
          //   key: "last_edited_at",
          //   label: "Last Edited At",
          //   render: (value) => (
          //     <Typography>{new Date(value).toLocaleString("th-TH")}</Typography>
          //   ),
          // },
          // {
          //   key: "is_pending",
          //   label: "Action",
          //   render: (
          //     value,
          //     index,
          //     rewardRedemtionData: IRewardRedemptionData
          //   ) => {
          //     if (value) {
          //       return (
          //         <ButtonSuccess
          //           value={rewardRedemtionData}
          //           fetchData={fetchData}
          //         />
          //       );
          //     }
          //     return (
          //       <ButtonPending
          //         value={rewardRedemtionData}
          //         fetchData={fetchData}
          //       />
          //     );
          //   },
          // },
        ]}
        onSearch={(searchText) => setSearch(searchText)}
        onExport={onExort}
        filters={[
          // {
          //   type: "select",
          //   name: "Status",
          //   value: filter.status,
          //   items: [
          //     {
          //       label: "All",
          //       value: "all",
          //     },
          //     {
          //       label: "Pending",
          //       value: "pending",
          //     },
          //     {
          //       label: "Success",
          //       value: "success",
          //     },
          //   ],
          //   onChange: (value) =>
          //     setFilter((filter) => {
          //       return { ...filter, status: value as string };
          //     }),
          // },
          {
            type: "daterange",
            name: "Created At",
            value: filter.date,
            onChange: (value: any) =>
              setFilter((filter) => {
                return { ...filter, date: value };
              }),
          },
        ]}
        data={data.data}
        isLoading={isLoading}
        pagination={
          data.pagination
            ? {
                ...data.pagination,
                onChange: (page) =>
                  setPagination((pagination) => {
                    return {
                      ...pagination,
                      page,
                    };
                  }),
              }
            : undefined
        }
      />
    </>
  );
};

const ButtonSuccess = ({
  value,
  fetchData,
}: {
  value: IRewardRedemptionData;
  fetchData: () => void;
}) => {
  const line_oa_code = "ONLY_GOLF";
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [image, setImage] = useState<File | null>(null);
  const [isError, setIsError] = useState<boolean>(false);

  const onSubmit = () => {
    if (!image) {
      setIsError(true);
    } else {
      setIsError(false);
      Swal.fire({
        icon: "warning",
        html: `ต้องการเปลี่ยนสถานะใช่ หรือไม่?`,
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let imagePath = "";
          let formData = new FormData();
          formData.append("file", image);
          formData.append("prefix", "reward-redemption");
          formData.append("path", "/reward-redemption");
          await FileServices.create(token as string, formData)
            .then((res) => {
              imagePath = res.data.data;
            })
            .catch((err) => {
              onAlert("error", err.response.data.message);
            });
          if (imagePath !== "") {
            let data: TDoneRewardRedemptionData = {
              image: imagePath,
            };
            RewardRedemptionServices.done(
              token as string,
              line_oa_code,
              value.id + "",
              data
            )
              .then((res) => {
                setIsOpen(false);
                fetchData();
                onAlert("success", "เปลี่ยนสถานะสำเร็จ");
              })
              .catch((err) => {
                onAlert("error", err.response.data.message);
              });
          }
        }
      });
    }
  };
  return (
    <>
      <Button
        variant="contained"
        color="success"
        onClick={() => setIsOpen(true)}
      >
        To Success
      </Button>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            width: "350px",
            display: "inline",
            mx: "auto",
            py: 2,
            px: 4,
            borderRadius: "12px",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              mb: 2,
            }}
          >
            Upload image
          </Typography>
          {image && (
            <img
              src={window.URL.createObjectURL(image)}
              alt="preview"
              width="100%"
            />
          )}
          <FormControl fullWidth error={isError}>
            <input
              type="file"
              accept="image/*"
              onChange={(e) =>
                setImage(e.target.files && (e.target.files[0] as File))
              }
              style={{
                border: `1px solid ${isError ? "#d32f2f" : "#e8ecee"}`,
                padding: "16px",
                borderRadius: "8px",
                width: "100%",
                cursor: "pointer",
              }}
            />
            {isError && <FormHelperText>กรุณาเลือกอกรูปภาพ</FormHelperText>}
          </FormControl>
          <Divider sx={{ my: 2 }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              onClick={() => setIsOpen(false)}
              color="error"
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={onSubmit}>
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const ButtonPending = ({
  value,
  fetchData,
}: {
  value: IRewardRedemptionData;
  fetchData: () => void;
}) => {
  const line_oa_code = "ONLY_GOLF";
  const { token } = useToken();
  const { onAlert } = useAlert();

  const onSubmit = () => {
    Swal.fire({
      icon: "warning",
      html: `ต้องการเปลี่ยนสถานะใช่ หรือไม่?`,
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        RewardRedemptionServices.pending(
          token as string,
          line_oa_code,
          value.id + ""
        )
          .then((res) => {
            fetchData();
            onAlert("success", "เปลี่ยนสถานะสำเร็จ");
          })
          .catch((err) => {
            onAlert("error", err.response.data.message);
          });
      }
    });
  };
  return (
    <>
      <Button variant="contained" color="error" onClick={onSubmit}>
        To Pending
      </Button>
    </>
  );
};

export default withAuth(
  withPermission(ListRewardRedemption, "MANAGE_ONLY_GOLF")
);
