import { Close, Create } from "@mui/icons-material";
import { IPagination, IPaginationRequest } from "interfaces/pagination";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import Header from "components/common/Header";
import { IBooking } from "interfaces/booking";
import Swal from "sweetalert2";
import Table from "components/common/Table";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import CobraBookingServices from "services/cobraBooking";

interface IBookingData {
  id: number;
  code: string;
  name: string;
  tel: string;
  email: string;
  booking_date: string;
  start_end_time: string;
  status: "Active" | "Canceled";
}

const ListService = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [data, setData] = useState<{
    data: IBookingData[];
    pagination: IPagination;
  }>({
    data: [],
    pagination: {
      page: 1,
      total_item: 0,
      total_page: 0,
    },
  });
  const [pagination, setPagination] = useState<IPaginationRequest>({
    page: 1,
    per_page: 10,
  });
  const [search, setSearch] = useState<string>("");
  const [filter, setFilter] = useState<{
    date: {
      start: Date | null;
      end: Date | null;
    };
  }>({ date: { start: null, end: null } });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    CobraBookingServices.getAll(
      token as string,
      {
        per_page: pagination.per_page,
        page: pagination.page,
      },
      search,
      {
        start: filter.date.start
          ? dayjs(filter.date.start.toString()).format("YYYY-MM-DD")
          : "",
        end: filter.date.end
          ? dayjs(filter.date.end.toString()).format("YYYY-MM-DD")
          : "",
      }
    )
      .then((res) => {
        let paginationRes: IPagination = res.data.pagination;
        setData({
          data: res.data.data.map((d: IBooking) => {
            return {
              id: d.id,
              code: d.code,
              name: d.first_name + " " + d.last_name,
              tel: d.tel,
              email: d.email || "-",
              booking_date: d.booking_date,
              start_end_time: `${d.booking_start_time.substring(
                0,
                5
              )} น. - ${d.booking_end_time.substring(0, 5)} น.`,
              status: d.status,
            } as IBookingData;
          }),
          pagination: {
            page: paginationRes.page,
            total_item: paginationRes.total_item,
            total_page: paginationRes.total_page,
          },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
        setIsLoading(false);
      });
  }, [token, onAlert, pagination, search, filter]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onCancel = (item: IBooking) => {
    Swal.fire({
      icon: "warning",
      html: `ต้องการยกเลิกการจองใช่ หรือไม่?`,
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        CobraBookingServices.cancel(token as string, item.id)
          .then((res) => {
            fetchData();
            onAlert("success", "ยกเลิกการจองสำเร็จ");
            setIsLoading(false);
          })
          .catch((err) => {
            onAlert("error", err.response.data.message);
            setIsLoading(false);
          });
      }
    });
  };

  return (
    <>
      <Header
        title="Booking"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: `/dashboard`,
          },
          {
            label: "Cobra",
            href: `/calendar/cobra`,
          },
          {
            label: "Booking",
            href: "/booking/cobra/list",
          },
          {
            label: "List",
          },
        ]}
        actions={[
          <Link to={`/booking/cobra/create`}>
            <Button variant="contained" size="large">
              <AddIcon
                sx={{
                  fontSize: 14,
                  mr: 1,
                }}
              />
              <Typography>Create</Typography>
            </Button>
          </Link>,
        ]}
      />
      <Table
        headers={[
          {
            key: "code",
            label: "Booking ID",
          },
          {
            key: "name",
            label: "Name",
          },
          {
            key: "tel",
            label: "Phone Number",
          },
          {
            key: "email",
            label: "E-mail",
          },
          {
            key: "booking_date",
            label: "Booking date",
          },
          {
            key: "start_end_time",
            label: "Start-End time",
          },
          {
            key: "status",
            label: "Status",
            render(value, index, item) {
              return (
                <Typography
                  sx={{
                    color: value === "Active" ? "green" : "red",
                  }}
                >
                  {value}
                </Typography>
              );
            },
          },
        ]}
        actions={{
          onDetail: (item: any) => navigate(`/booking/cobra/${item.id}`),
          others: [
            {
              icon(item, index) {
                return <Create />;
              },
              label: () => "",
              onClick(item: IBooking, index) {
                navigate(`/booking/cobra/${item.id}/edit`);
              },
              hidden(item: IBooking, index) {
                if (item.status === "Canceled") {
                  return true;
                }
                return false;
              },
            },
            {
              icon(item, index) {
                return <Close />;
              },
              label: () => "",
              onClick(item: IBooking, index) {
                onCancel(item);
              },
              hidden(item: IBooking, index) {
                if (item.status === "Canceled") {
                  return true;
                }
                return false;
              },
            },
          ],
        }}
        data={data.data}
        isLoading={isLoading}
        onSearch={(searchText) => setSearch(searchText)}
        filters={[
          {
            type: "daterange",
            name: "Created At",
            value: filter.date,
            onChange: (value: any) =>
              setFilter((filter) => {
                return { ...filter, date: value };
              }),
          },
        ]}
        pagination={
          data.pagination
            ? {
                ...data.pagination,
                onChange: (page) =>
                  setPagination((pagination) => {
                    return {
                      ...pagination,
                      page,
                    };
                  }),
              }
            : undefined
        }
      />
    </>
  );
};

export default withAuth(withPermission(ListService, "MANAGE_COBRA"));
