import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import FieldList from "components/common/FieldList";
import Header from "components/common/Header";
import { IStore } from "interfaces/store";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import CobraStoreServices from "services/cobraStore";

type TEditStore = {
  name: string;
  lat: number;
  lng: number;
  address: string;
  tel: string;
  url: string;
};

const EditStore = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useToken();
  const { onAlert } = useAlert();

  const [store, setStore] = useState<IStore | null>(null);

  useEffect(() => {
    const fetchData = () => {
      CobraStoreServices.getDetail(
        token as string,
        id as string
      )
        .then((res) => {
          setStore(res.data.data);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    };

    fetchData();
  }, [id, token, onAlert]);

  const onSubmit = (data: any) => {
    let dataProps: TEditStore = {
      ...data,
      lat: parseFloat(data.lat),
      lng: parseFloat(data.lng),
    };
    CobraStoreServices.edit(
      token as string,
      id as string,
      dataProps
    )
      .then((res) => {
        onAlert("success", "แก้ไข Store สำเร็จ");
        navigate(`/store/cobra/list`);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
      });
  };

  if (store) {
    return (
      <>
        <Header
          title="Edit Store"
          breadcrumbs={[
            {
              label: "Dashboard",
              href: `/dashboard`,
            },
            {
              label: "Cobra",
              href: `/dashboard/cobra`,
            },
            {
              label: "Store",
              href: `/store/cobra/list`,
            },
            {
              label: store.name,
            },
          ]}
        />
        <FieldList
          type="edit"
          onSubmit={onSubmit}
          fields={[
            {
              label: "Store Name",
              type: "text",
              name: "name",
              inputOptions: {
                required: "กรุณากรอกชื่อร้าน",
              },
              defaultValue: store.name,
              col: 6,
              tooltip: "สำหรับกำหนดชื่อที่จะแสดงผลของร้านค้า",
            },
            {
              label: "",
              type: "box",
              name: "",
              col: 6,
            },
            
            {
              label: "Latitude",
              type: "number",
              name: "lat",
              inputOptions: {
                required: "กรุณากรอก Latitude",
              },
              defaultValue: store.lat,
              col: 6,
              tooltip: "สำหรับกรอกตำแหน่งละติจูดของร้านค้า",
            },
            {
              label: "Longitude",
              type: "number",
              name: "lng",
              inputOptions: {
                required: "กรุณากรอก Logitude",
              },
              defaultValue: store.lng,
              col: 6,
              tooltip: "สำหรับกรอกตำแหน่งลองจิจูดของร้านค้า",
            },
            {
              label: "Address",
              type: "text",
              name: "address",
              inputOptions: {
                required: "กรุณากรอกที่อยู่",
              },
              defaultValue: store.address,
              col: 6,
              tooltip: "สำหรับกำหนดที่อยู่ที่จะแสดงผลของร้านค้า",
            },
            {
              label: "Store Phone Number",
              type: "text",
              name: "tel",
              inputOptions: {
                required: "กรุณากรอกเบอร์โทรศัพท์",
              },
              defaultValue: store.tel,
              col: 6,
              tooltip: "สำหรับกำหนดหมายเลขโทรศัพท์ของร้านค้า",
            },
            {
              label: "Map URL",
              type: "text",
              name: "url",
              inputOptions: {
                required: "กรุณากรอก Map URL from google map",
              },
              defaultValue: store.url,
              col: 6,
              tooltip: "สำหรับกำหนด URL เพื่อไปยัง Google Map ของร้านค้า",
            },
          ]}
          onBack={() => navigate(`/store/cobra/list`)}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default withAuth(withPermission(EditStore, "MANAGE_COBRA"));
