import { IPagination, IPaginationRequest } from "interfaces/pagination";
import { useCallback, useEffect, useState } from "react";

import Header from "components/common/Header";
import Table from "components/common/Table";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import CobraContactTypeServices from "services/cobraContactType";
import { ICobraContactType } from "interfaces/cobraContactType";

interface IContactTypeData {
  id: number;
  name: string;
  image: string;
}

const ListContactType = () => {
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [data, setData] = useState<{
    data: IContactTypeData[];
    pagination: IPagination;
  }>({
    data: [],
    pagination: {
      page: 1,
      total_item: 0,
      total_page: 0,
    },
  });
  const [pagination, setPagination] = useState<IPaginationRequest>({
    page: 1,
    per_page: 10,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchData = useCallback(() => {
    CobraContactTypeServices.getAll(token as string, {
      per_page: pagination.per_page,
      page: pagination.page,
    })
      .then((res) => {
        let paginationRes: IPagination = res.data.pagination;
        setData({
          data: res.data.data.map((d: ICobraContactType) => {
            return {
              id: d.id,
              name: d.name,
              image: d.image,
            };
          }),
          pagination: {
            page: paginationRes.page,
            total_item: paginationRes.total_item,
            total_page: paginationRes.total_page,
          },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
        setIsLoading(false);
      });
  }, [pagination, onAlert, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Header
        title="Contact Type"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: "/dashboard",
          },
          {
            label: "Contact Type",
            href: "/contact-type/cobra/list",
          },
          {
            label: "List",
          },
        ]}
      />
      <Table
        headers={[
          {
            key: "image",
            label: "Image",
            render: (value: string, index: number, item: ICobraContactType) => (
              <img
                style={{
                  borderRadius: "8px",
                  width: "50px",
                  height: "50px",
                  maxHeight: "80px",
                  objectFit: "cover",
                }}
                src={process.env.REACT_APP_FILE + value}
                alt={item.name}
              />
            ),
          },
          {
            key: "name",
            label: "Name",
          },
        ]}
        data={data.data}
        isLoading={isLoading}
        pagination={
          data.pagination
            ? {
                ...data.pagination,
                onChange: (page) =>
                  setPagination((pagination) => {
                    return {
                      ...pagination,
                      page,
                    };
                  }),
              }
            : undefined
        }
      />
    </>
  );
};

export default withAuth(withPermission(ListContactType, "MANAGE_COBRA"));
