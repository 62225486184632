import { IPaginationRequest } from "interfaces/pagination";
import axios from "axios";

const MemberLogService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/member-log`,
});

const MemberLogServices = {
  getAll: (
    token: string,
    line_oa_code: string,
    pagination: IPaginationRequest,
    search: string,
    date: {
      start: string;
      end: string;
    }
  ) =>
    MemberLogService.get(
      `/${line_oa_code}/list?perPage=${pagination.per_page}&page=${pagination.page}&search=${search}&startDate=${date.start}&endDate=${date.end}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ),
};

export default MemberLogServices;
