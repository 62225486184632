import { IPagination, IPaginationRequest } from "interfaces/pagination";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import AvatarLetter from "components/common/AvatarLetter";
import { Button } from "@mui/material";
import CouponTypeServices from "services/couponType";
import Header from "components/common/Header";
import { ICouponType } from "interfaces/couponType";
import { IPromotion } from "interfaces/promotion";
import PromotionServices from "services/promotion";
import Swal from "sweetalert2";
import Table from "components/common/Table";
import Typography from "@mui/material/Typography";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

interface ICouponTypeData {
  id: number;
  name: string;
  image: string;
  last_edited_at: string;
  created_by: string;
  edited_by: string;
}

const ListCouponType = () => {
  let line_oa_code = "ONLY_GOLF";
  const navigate = useNavigate();
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [data, setData] = useState<{
    data: ICouponTypeData[];
    pagination: IPagination;
  }>({
    data: [],
    pagination: {
      page: 1,
      total_item: 0,
      total_page: 0,
    },
  });
  const [pagination, setPagination] = useState<IPaginationRequest>({
    page: 1,
    per_page: 10,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchData = useCallback(() => {
    CouponTypeServices.getAll(token as string, line_oa_code as string, {
      per_page: pagination.per_page,
      page: pagination.page,
    })
      .then((res) => {
        let paginationRes: IPagination = res.data.pagination;
        setData({
          data: res.data.data.map((d: ICouponType) => {
            return {
              id: d.id,
              name: d.name,
              image: d.image,
              last_edited_at: d.last_edited_at,
              created_by: d.admin_created.name,
              edited_by: d.admin_edited.name,
            };
          }),
          pagination: {
            page: paginationRes.page,
            total_item: paginationRes.total_item,
            total_page: paginationRes.total_page,
          },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
      });
  }, [pagination, onAlert, token, line_oa_code]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // const onDelete = (item: IPromotion) => {
  //   Swal.fire({
  //     icon: "warning",
  //     html: `ต้องการลบคูปองประเภท <b>${item.name}</b> ใช่ หรือไม่?`,
  //     showCancelButton: true,
  //     confirmButtonText: "ลบ",
  //     cancelButtonText: "ยกเลิก",
  //     reverseButtons: true,
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       CouponTypeServices.delete(
  //         token as string,
  //         line_oa_code as string,
  //         item.id
  //       )
  //         .then((res) => {
  //           fetchData();
  //           onAlert("success", "ลบคูปองประเภทนี้สำเร็จ");
  //         })
  //         .catch((err) => {
  //           onAlert("error", err.response.data.message);
  //         });
  //     }
  //   });
  // };

  return (
    <>
      <Header
        title="Coupon Type"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: `/dashboard`,
          },
          {
            label: "Only Golf",
            href: `/dashboard/only-golf`,
          },
          {
            label: "Coupon Type",
            href: "/coupon-type/only-golf/list",
          },
          {
            label: "List",
          },
        ]}
        // actions={[
        //   <Link to={`/coupon-type/only-golf/create`}>
        //     <Button
        //       variant="contained"
        //       size="large"
        //       disabled={data.data.length >= 5}
        //     >
        //       <AddIcon
        //         sx={{
        //           fontSize: 14,
        //           mr: 1,
        //         }}
        //       />
        //       <Typography>Create</Typography>
        //     </Button>
        //   </Link>,
        // ]}
      />
      <Table
        headers={[
          {
            key: "image",
            label: "Image",
            render: (value: string, index: number, item: ICouponTypeData) => (
              <img
                style={{
                  borderRadius: "8px",
                  width: "50px",
                  height: "50px",
                  maxHeight: "80px",
                  objectFit: "cover",
                }}
                src={process.env.REACT_APP_FILE + value}
                alt={item.name}
              />
            ),
          },
          {
            key: "name",
            label: "Name",
          },
          {
            key: "created_by",
            label: "Created By",
            render: (value) => <AvatarLetter label={value} tooltip />,
          },
          {
            key: "edited_by",
            label: "Edited By",
            render: (value) => <AvatarLetter label={value} tooltip />,
          },
          {
            key: "last_edited_at",
            label: "Last Edited At",
            render: (value) => (
              <Typography>{new Date(value).toLocaleString("th-TH")}</Typography>
            ),
          },
        ]}
        // actions={{
        //   onDetail: (item: any) =>
        //     navigate(`/coupon-type/only-golf/${item.id}`),
        //   onEdit: (item: any) =>
        //     navigate(`/coupon-type/only-golf/${item.id}/edit`),
        //   onDelete: (item: any) => onDelete(item),
        // }}
        data={data.data}
        isLoading={isLoading}
        pagination={
          data.pagination
            ? {
                ...data.pagination,
                onChange: (page) =>
                  setPagination((pagination) => {
                    return {
                      ...pagination,
                      page,
                    };
                  }),
              }
            : undefined
        }
      />
    </>
  );
};

export default withAuth(withPermission(ListCouponType, "MANAGE_ONLY_GOLF"));
