import { ICCKDaySlot } from "interfaces/cckDaySlot";
import { ICCKTimeSlot } from "interfaces/cckBooking";
import { IPaginationRequest } from "interfaces/pagination";
import axios from "axios";

const CCKProfessionalService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/cck-professional`,
});

const CCKProfessionalServices = {
  getDetail: (token: string, id: string) =>
    CCKProfessionalService.get(`/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getList: (token: string, pagination: IPaginationRequest, search: string) =>
    CCKProfessionalService.get(
      `list?perPage=${pagination.per_page}&page=${pagination.page}&search=${search}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ),
  getAll: (token: string) =>
    CCKProfessionalService.get(`all`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getAllActive: (token: string) =>
    CCKProfessionalService.get(`all/active`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  create: (
    token: string,
    data: {
      profile_url: string | null;
      first_name: string;
      last_name: string;
      tel: string;
      email: string | null;
      age: number | null;
      type: string;
      experience: string | null;
      available_time: string | null;
      price: string;
      // free_slots: {
      //   times: {
      //     time: ICCKTimeSlot;
      //     checked: boolean;
      //   }[];
      //   day: ICCKDaySlot;
      // }[];
    }
  ) =>
    CCKProfessionalService.post(``, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  edit: (
    token: string,
    id: string | number,
    data: {
      profile_url: string | null;
      first_name: string;
      last_name: string;
      tel: string;
      email: string | null;
      age: number | null;
      type: string;
      experience: string | null;
      available_time: string | null;
      price: string;
      // free_slots: {
      //   times: {
      //     time: ICCKTimeSlot;
      //     checked: boolean;
      //   }[];
      //   day: ICCKDaySlot;
      // }[];
    }
  ) =>
    CCKProfessionalService.put(`/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  delete: (token: string, id: string | number) =>
    CCKProfessionalService.delete(`/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  changeStatus: (
    token: string,
    id: string | number,
    data: {
      is_active: boolean;
    }
  ) =>
    CCKProfessionalService.put(`/${id}/status`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default CCKProfessionalServices;
