import { IPaginationRequest } from "interfaces/pagination";
import axios from "axios";

const ServiceService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/service`,
});

const ServiceServices = {
  getDetail: (token: string, line_oa_code: string, id: string) =>
  ServiceService.get(`/${line_oa_code}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getAll: (
    token: string,
    line_oa_code: string,
    pagination: IPaginationRequest
  ) =>
  ServiceService.get(
      `/${line_oa_code}/list?perPage=${pagination.per_page}&page=${pagination.page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ),
  create: (
    token: string,
    line_oa_code: string,
    data: {
      name: string;
      duration: number;
      sequence: number;
    }
  ) =>
  ServiceService.post(`/${line_oa_code}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  edit: (
    token: string,
    line_oa_code: string,
    id: string | number,
    data: {
      name: string;
      duration: number;
      sequence: number;
    }
  ) =>
  ServiceService.put(`/${line_oa_code}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  delete: (token: string, line_oa_code: string, id: string | number) =>
  ServiceService.delete(`/${line_oa_code}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default ServiceServices;
