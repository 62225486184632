import { useEffect, useState } from "react";

import Header from "components/common/Header/Header";
import { IMember } from "interfaces/member";
import MemberServices from "services/member";
import Table from "components/common/Table/Table";
import { Typography } from "@mui/material";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

interface IMemberData {
  id: number;
  name: string;
  spending: number;
  total_spend: number;
  point: number;
}

const Dashboard = () => {
  const line_oa_code = "ONLY_GOLF";
  const { token } = useToken();
  const [memberNumber, setMemberNumber] = useState<number | null>(null);
  const [members, setMembers] = useState<IMemberData[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    MemberServices.getNumber(token as string, line_oa_code)
      .then((res) => {
        setMemberNumber(res.data.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
    MemberServices.getRanking(token as string, line_oa_code)
      .then((res) => {
        setMembers(
          res.data.data.map((member: IMember) => {
            let memberData: IMemberData = {
              id: member.id,
              name: member.first_name + " " + member.last_name,
              spending: member.spend,
              total_spend: member.total_spend,
              point: member.point,
            };
            return memberData;
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <Header
        title="Dashboard"
        breadcrumbs={[
          {
            label: "Dashboard",
          },
        ]}
      />
      <div>
        <div
          style={{
            display: "inline-block",
            padding: "20px",
            background: "rgba(18, 151, 71, 0.08)",
            borderRadius: "16px",
            marginBottom: "30px",
          }}
        >
          <p
            style={{
              fontWeight: "700",
              fontSize: "18px",
              color: "#2B2B2B",
            }}
          >
            จำนวนสมาชิกทั้งหมด
          </p>
          <p
            style={{
              fontWeight: "700",
              fontSize: "24px",
              color: "#129747",
              margin: 0,
            }}
          >
            {`${memberNumber?.toLocaleString() || "NaN"} คน`}
          </p>
        </div>
        <div>
          <p
            style={{
              fontWeight: "700",
              fontSize: "18px",
              color: "#2B2B2B",
              marginBottom: "10px",
            }}
          >
            ตารางแสดงผู้ใช้งานที่มียอดสั่งซื้อสูงสุด 50 อันดับ
          </p>
          {members ? (
            <Table
              headers={[
                {
                  key: "id",
                  label: "Ranking",
                  render(value, index, item) {
                    return <Typography>{index + 1}</Typography>;
                  },
                },
                {
                  key: "name",
                  label: "Name",
                },
                {
                  key: "spending",
                  label: "Spending",
                  render(value, index, item) {
                    return <Typography>{value.toLocaleString()}</Typography>;
                  },
                },
                {
                  key: "total_spend",
                  label: "Total Spend",
                  render(value, index, item) {
                    return <Typography>{value.toLocaleString()}</Typography>;
                  },
                },
                {
                  key: "point",
                  label: "Point",
                  render(value, index, item) {
                    return <Typography>{value.toLocaleString()}</Typography>;
                  },
                },
              ]}
              data={members}
              isLoading={isLoading}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default withAuth(withPermission(Dashboard, "MANAGE_ONLY_GOLF"));
