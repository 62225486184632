import { Avatar, Button, Chip, Switch } from "@mui/material";
import { IPagination, IPaginationRequest } from "interfaces/pagination";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import CCKProfessionalServices from "services/cckProfessional";
import Header from "components/common/Header";
import { ICCKProfessional } from "interfaces/cckProfessional";
import Swal from "sweetalert2";
import Table from "components/common/Table";
import Typography from "@mui/material/Typography";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

interface ICCKProfessionalData {
  id: number;
  profile_url: string | null;
  name: string;
  is_active: boolean;
  remaining_member: number;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
}

const ListProfessional = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [search, setSearch] = useState<string>("");
  const [data, setData] = useState<{
    data: ICCKProfessionalData[];
    pagination: IPagination;
  }>({
    data: [],
    pagination: {
      page: 1,
      total_item: 0,
      total_page: 0,
    },
  });
  const [pagination, setPagination] = useState<IPaginationRequest>({
    page: 1,
    per_page: 10,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchData = useCallback(() => {
    CCKProfessionalServices.getList(
      token as string,
      {
        per_page: pagination.per_page,
        page: pagination.page,
      },
      search
    )
      .then((res) => {
        let paginationRes: IPagination = res.data.pagination;
        setData({
          data: res.data.data.map((d: ICCKProfessional) => {
            let value: ICCKProfessionalData = {
              id: d.id,
              profile_url: d.profile_url,
              name: `${d.first_name} ${d.last_name}`,
              is_active: d.is_active,
              remaining_member: d.remaining_member,
              created_at: d.updated_at,
              updated_at: d.updated_at,
              created_by: d.admin_created.name,
              updated_by: d.admin_edited.name,
            };
            return value;
          }),
          pagination: {
            page: paginationRes.page,
            total_item: paginationRes.total_item,
            total_page: paginationRes.total_page,
          },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
        setIsLoading(false);
      });
  }, [pagination, onAlert, token, search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onChangeStatus = (id: number, status: boolean) => {
    if (!status) {
      Swal.fire({
        icon: "warning",
        html: `Confirm to change status to unavailable?`,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setIsLoading(true);
          CCKProfessionalServices.changeStatus(token as string, id, {
            is_active: status,
          })
            .then((res) => {
              fetchData();
              onAlert("success", "Change status professional success");
              setIsLoading(false);
            })
            .catch((err) => {
              onAlert("error", err.response.data.message);
              setIsLoading(false);
            });
        }
      });
    } else {
      setIsLoading(true);
      CCKProfessionalServices.changeStatus(token as string, id, {
        is_active: status,
      })
        .then((res) => {
          fetchData();
          onAlert("success", "Change status professional success");
          setIsLoading(false);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
          setIsLoading(false);
        });
    }
  };

  const onDelete = (id: number) => {
    Swal.fire({
      icon: "warning",
      html: `Confirm to delete?`,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        CCKProfessionalServices.delete(token as string, id)
          .then((res) => {
            fetchData();
            onAlert("success", "Delete professional success");
            setIsLoading(false);
          })
          .catch((err) => {
            onAlert("error", err.response.data.message);
            setIsLoading(false);
          });
      }
    });
  };

  return (
    <>
      <Header
        title="Professional"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: `/dashboard`,
          },
          {
            label: "Simulator Bay",
            href: `/simulator-bay/dashboard`,
          },
          {
            label: "Professional",
            href: "/simulator-bay/professional/list",
          },
          {
            label: "List",
          },
        ]}
        actions={[
          <Link to={`/simulator-bay/professional/create`}>
            <Button variant="contained" size="large">
              <AddIcon
                sx={{
                  fontSize: 14,
                  mr: 1,
                }}
              />
              <Typography>Create</Typography>
            </Button>
          </Link>,
        ]}
      />
      <Table
        headers={[
          {
            key: "profile_url",
            label: "Picture Profile",
            render: (value, index, member) => {
              return (
                <>
                  <Avatar
                    src={process.env.REACT_APP_FILE + value}
                    sx={{
                      width: 70,
                      height: 70,
                    }}
                  />
                </>
              );
            },
          },
          {
            key: "name",
            label: "Name",
          },
          {
            key: "remaining_member",
            label: "Remaining member",
          },
          {
            key: "is_active",
            label: "Status",
            render: (value, index, member) => {
              return (
                <Chip
                  label={value ? "Active" : "Unavailable"}
                  color={value ? "success" : "error"}
                />
              );
            },
          },
          {
            key: "is_active",
            label: "Change status",
            render: (value, index, bay) => {
              return (
                <Switch
                  color={"success"}
                  checked={value}
                  onChange={() => onChangeStatus(bay.id, !value)}
                />
              );
            },
          },
          // {
          //   key: "created_by",
          //   label: "Created By",
          //   render: (value) => <AvatarLetter label={value} tooltip />,
          // },
          // {
          //   key: "updated_by",
          //   label: "Edited By",
          //   render: (value) => <AvatarLetter label={value} tooltip />,
          // },
          // {
          //   key: "updated_at",
          //   label: "Last Edited At",
          //   render: (value) => (
          //     <Typography>
          //       {dayjs(new Date(value)).format("DD/MM/YYYY HH:mm:ss")}
          //     </Typography>
          //   ),
          // },
        ]}
        actions={{
          onDetail: (item: any) =>
            navigate(`/simulator-bay/professional/${item.id}`),
          onEdit: (item: any) =>
            navigate(`/simulator-bay/professional/${item.id}/edit`),
          onDelete: (item: any) => onDelete(item.id),
        }}
        data={data.data}
        isLoading={isLoading}
        pagination={
          data.pagination
            ? {
                ...data.pagination,
                onChange: (page) =>
                  setPagination((pagination) => {
                    return {
                      ...pagination,
                      page,
                    };
                  }),
              }
            : undefined
        }
      />
    </>
  );
};

export default withAuth(
  withPermission(ListProfessional, "MANAGE_SIMULATOR_BAY")
);
