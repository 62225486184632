import { Button, Chip, Switch } from "@mui/material";
import { IPagination, IPaginationRequest } from "interfaces/pagination";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import AvatarLetter from "components/common/AvatarLetter/AvatarLetter";
import CCKBayServices from "services/cckBay";
import Header from "components/common/Header";
import { ICCKBay } from "interfaces/cckBay";
import Swal from "sweetalert2";
import Table from "components/common/Table";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

interface ICCKBayData {
  id: number;
  bay_number: number;
  name: string;
  is_active: boolean;
  number_of_reservation: number;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
}

const ListBay = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [search, setSearch] = useState<string>("");
  const [data, setData] = useState<{
    data: ICCKBayData[];
    pagination: IPagination;
  }>({
    data: [],
    pagination: {
      page: 1,
      total_item: 0,
      total_page: 0,
    },
  });
  const [pagination, setPagination] = useState<IPaginationRequest>({
    page: 1,
    per_page: 10,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchData = useCallback(() => {
    CCKBayServices.getAll(token as string, {
      per_page: pagination.per_page,
      page: pagination.page,
    })
      .then((res) => {
        let paginationRes: IPagination = res.data.pagination;
        setData({
          data: res.data.data.map((d: ICCKBay) => {
            let value: ICCKBayData = {
              id: d.id,
              bay_number: d.bay_number,
              name: d.name,
              is_active: d.is_active,
              number_of_reservation: d.number_of_reservation,
              created_at: d.created_at,
              updated_at: d.updated_at,
              created_by: d.admin_created.name,
              updated_by: d.admin_edited.name,
            };
            return value;
          }),
          pagination: {
            page: paginationRes.page,
            total_item: paginationRes.total_item,
            total_page: paginationRes.total_page,
          },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
        setIsLoading(false);
      });
  }, [pagination, onAlert, token, search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onChangeStatus = (id: number, status: boolean) => {
    if (!status) {
      Swal.fire({
        icon: "warning",
        html: `Confirm to close this bay?`,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setIsLoading(true);
          CCKBayServices.changeStatus(token as string, id, { status })
            .then((res) => {
              fetchData();
              onAlert("success", "Change status bay success");
              setIsLoading(false);
            })
            .catch((err) => {
              onAlert("error", err.response.data.message);
            });
        }
      });
    } else {
      setIsLoading(true);
      CCKBayServices.changeStatus(token as string, id, { status })
        .then((res) => {
          fetchData();
          onAlert("success", "Change status bay success");
          setIsLoading(false);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    }
  };
  const onDelete = (id: number) => {
    Swal.fire({
      icon: "warning",
      html: `Confirm to delete this bay?`,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        CCKBayServices.delete(token as string, id)
          .then((res) => {
            fetchData();
            onAlert("success", "Delete bay success");
            setIsLoading(false);
          })
          .catch((err) => {
            onAlert("error", err.response.data.message);
            setIsLoading(false);
          });
      }
    });
  };

  return (
    <>
      <Header
        title="Bay"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: `/dashboard`,
          },
          {
            label: "Simulator Bay",
            href: `/simulator-bay/dashboard`,
          },
          {
            label: "Bay",
            href: "/simulator-bay/bay/list",
          },
          {
            label: "List",
          },
        ]}
        actions={[
          <Link to={`/simulator-bay/bay/create`}>
            <Button variant="contained" size="large">
              <AddIcon
                sx={{
                  fontSize: 14,
                  mr: 1,
                }}
              />
              <Typography>Create</Typography>
            </Button>
          </Link>,
        ]}
      />
      <Table
        headers={[
          {
            key: "bay_number",
            label: "Bay number",
          },
          {
            key: "name",
            label: "Bay name",
          },
          {
            key: "number_of_reservation",
            label: "Number of Reservation",
          },
          {
            key: "is_active",
            label: "Status",
            render: (value, index, member) => {
              return (
                <Chip
                  label={value ? "Active" : "Out of service"}
                  color={value ? "success" : "error"}
                />
              );
            },
          },
          {
            key: "is_active",
            label: "Change status",
            render: (value, index, bay) => {
              return (
                <Switch
                  color={"success"}
                  checked={value}
                  onChange={() => onChangeStatus(bay.id, !value)}
                />
              );
            },
          },
          // {
          //   key: "created_by",
          //   label: "Created By",
          //   render: (value) => <AvatarLetter label={value} tooltip />,
          // },
          // {
          //   key: "updated_by",
          //   label: "Edited By",
          //   render: (value) => <AvatarLetter label={value} tooltip />,
          // },
          // {
          //   key: "updated_at",
          //   label: "Last Edited At",
          //   render: (value) => (
          //     <Typography>
          //       {dayjs(new Date(value)).format("DD/MM/YYYY HH:mm:ss")}
          //     </Typography>
          //   ),
          // },
        ]}
        actions={{
          onDetail: (item: any) => navigate(`/simulator-bay/bay/${item.id}`),
          onEdit: (item: any) =>
            navigate(`/simulator-bay/bay/${item.id}/edit`),
          // onDelete: (item: any) => onDelete(item.id),
        }}
        data={data.data}
        isLoading={isLoading}
        pagination={
          data.pagination
            ? {
                ...data.pagination,
                onChange: (page) =>
                  setPagination((pagination) => {
                    return {
                      ...pagination,
                      page,
                    };
                  }),
              }
            : undefined
        }
      />
    </>
  );
};

export default withAuth(withPermission(ListBay, "MANAGE_SIMULATOR_BAY"));
