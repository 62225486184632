import {
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import { Box } from "@mui/material";
import CheckListField from "./CheckListField";
import DateField from "./DateField";
import InputField from "./InputField";
import NumberField from "./NumberField";
import RadioGroup from "./RadioGroup";
import SelectField from "./SelectField";
import TextAreaField from "./TextAreaField";
import TextEditor from "./TextEditor";
import UploadFile from "./UploadImage";

export interface IField {
  fieldListType: "create" | "edit" | "detail";
  form: {
    register: UseFormRegister<any>;
    setValue: UseFormSetValue<any>;
    watch: UseFormWatch<any>;
    setError: UseFormSetError<any>;
    clearErrors: UseFormClearErrors<any>;
  };
  error: boolean;
  errorMessage: string;
  label: string;
  type: string;
  name: string;
  defaultValue?: any;
  disabled?: boolean;
  maxLength?: number;
  pattern?: string;
  inputOptions?: any;
  col?: number;
  items?: {
    label: any;
    value: any;
    checked?: boolean;
  }[];
  tooltip?: string;
}

const Field: React.FC<IField> = (props) => {
  switch (props.type) {
    case "date":
      return (
        <DateField
          {...props}
          disabled={props.fieldListType === "detail" ? true : props.disabled}
        />
      );
    case "check-list":
      return (
        <CheckListField
          {...props}
          disabled={props.fieldListType === "detail" ? true : props.disabled}
        />
      );
    case "select":
      return (
        <SelectField
          {...props}
          disabled={props.fieldListType === "detail" ? true : props.disabled}
        />
      );
    case "radiogroup":
      return (
        <RadioGroup
          {...props}
          disabled={props.fieldListType === "detail" ? true : props.disabled}
        />
      );
    case "box":
      return <Box></Box>;
    case "number":
      return (
        <NumberField
          {...props}
          disabled={props.fieldListType === "detail" ? true : props.disabled}
        />
      );
    case "image":
      return (
        <UploadFile
          {...props}
          disabled={props.fieldListType === "detail" ? true : props.disabled}
        />
      );
    case "textarea":
      return (
        <TextAreaField
          {...props}
          disabled={props.fieldListType === "detail" ? true : props.disabled}
        />
      );
    case "text-editor":
      return (
        <TextEditor
          {...props}
          disabled={props.fieldListType === "detail" ? true : props.disabled}
        />
      );
    default:
      return (
        <InputField
          {...props}
          disabled={props.fieldListType === "detail" ? true : props.disabled}
        />
      );
  }
};

export default Field;
