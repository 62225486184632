import ListPermission from "pages/manage-admin/permission/list";
import { RouteObject } from "react-router-dom";
import adminRouters from "./adminRouters";
import roleRouters from "./roleRouters";

const ManageAdminRouters: RouteObject[] = [
  ...adminRouters,
  ...roleRouters,
  {
    path: "/permission/list",
    element: <ListPermission />,
  },
];

export default ManageAdminRouters;
