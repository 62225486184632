import { Button } from "@mui/material";
import { IPagination, IPaginationRequest } from "interfaces/pagination";
import { useCallback, useEffect, useState } from "react";

import Header from "components/common/Header";
import Table from "components/common/Table";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import { IMember } from "interfaces/member";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import CobraMemberServices from "services/cobraMember";

interface IMemberData {
  id: number;
  line_id: string;
  name: string;
  tel: string;
  spend: number;
  total_spend: number;
  point: number;
  updated_at: string;
}

const ListMember = () => {
  let line_oa_code = "ONLY_GOLF";
  const navigate = useNavigate();
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [search, setSearch] = useState<string>("");
  const [data, setData] = useState<{
    data: IMemberData[];
    pagination: IPagination;
  }>({
    data: [],
    pagination: {
      page: 1,
      total_item: 0,
      total_page: 0,
    },
  });
  const [pagination, setPagination] = useState<IPaginationRequest>({
    page: 1,
    per_page: 10,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchData = useCallback(() => {
    CobraMemberServices.getAll(
      token as string,
      {
        per_page: pagination.per_page,
        page: pagination.page,
      },
      search
    )
      .then((res) => {
        let paginationRes: IPagination = res.data.pagination;
        setData({
          data: res.data.data.map((d: IMember) => {
            let value: IMemberData = {
              id: d.id,
              line_id: d.line_id,
              name: `${d.first_name} ${d.last_name}`,
              tel: d.tel,
              spend: d.spend,
              total_spend: d.total_spend,
              point: d.point,
              updated_at: d.updated_at,
            };
            return value;
          }),
          pagination: {
            page: paginationRes.page,
            total_item: paginationRes.total_item,
            total_page: paginationRes.total_page,
          },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
      });
  }, [pagination, line_oa_code, onAlert, token, search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Header
        title="Member"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: `/dashboard`,
          },
          {
            label: "Cobra",
            href: `/dashboard/cobra`,
          },
          {
            label: "Member",
            href: "/member/cobra/list",
          },
          {
            label: "List",
          },
        ]}
      />
      <Table
        headers={[
          {
            key: "name",
            label: "Member name",
          },
          {
            key: "tel",
            label: "Phone Number",
          },
          {
            key: "name",
            label: "Cancel line",
            render: (value, index, member) => {
              return <ButtonClearLineID value={member} fetchData={fetchData} />;
            },
          },
        ]}
        actions={{
          onDetail: (item: any) => navigate(`/member/cobra/${item.id}`),
          onEdit: (item: any) => navigate(`/member/cobra/${item.id}/edit`),
        }}
        onSearch={(searchText) => setSearch(searchText)}
        data={data.data}
        isLoading={isLoading}
        pagination={
          data.pagination
            ? {
                ...data.pagination,
                onChange: (page) =>
                  setPagination((pagination) => {
                    return {
                      ...pagination,
                      page,
                    };
                  }),
              }
            : undefined
        }
      />
    </>
  );
};

const ButtonClearLineID = ({
  value,
  fetchData,
}: {
  value: IMemberData;
  fetchData: () => void;
}) => {
  const { token } = useToken();
  const { onAlert } = useAlert();

  const onClearLineID = () => {
    Swal.fire({
      icon: "warning",
      html: `ต้องการยกเลิกลงทะเบียนใช่ หรือไม่?`,
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        CobraMemberServices.clearLineID(token as string, value.id + "")
          .then((res) => {
            fetchData();
            onAlert("success", "ยกเลิกลงทะเบียนสำเร็จ");
          })
          .catch((err) => {
            onAlert("error", err.response.data.message);
          });
      }
    });
  };

  return (
    <>
      <Button
        variant="contained"
        color="error"
        onClick={() => onClearLineID()}
        disabled={!value.line_id}
      >
        ยกเลิกลงทะเบียน
      </Button>
    </>
  );
};

export default withAuth(withPermission(ListMember, "MANAGE_COBRA"));
