import { AccountCircle, Add, KeyboardReturn } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import CCKDaySlotServices from "services/cckDaySlot";
import CCKProfessionalServices from "services/cckProfessional";
import CCKTimeSlotServices from "services/cckTimeSlot";
import { Editor } from "react-draft-wysiwyg";
import Header from "components/common/Header";
import { ICCKDaySlot } from "interfaces/cckDaySlot";
import { ICCKProfessional } from "interfaces/cckProfessional";
import { ICCKTimeSlot } from "interfaces/cckBooking";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

const ProfessionalDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [content, setContent] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const [availableTimeContent, setAvailableTimeContent] = useState<EditorState>(
    EditorState.createEmpty()
  );

  const [professional, setProfessional] = useState<ICCKProfessional | null>(
    null
  );
  // const [freeSlots, setFreeSlots] = useState<
  //   {
  //     day: ICCKDaySlot;
  //     times: {
  //       time: ICCKTimeSlot;
  //       checked: boolean;
  //     }[];
  //   }[]
  // >([]);

  useEffect(() => {
    const fetchData = () => {
      CCKProfessionalServices.getDetail(token as string, id as string)
        .then((res) => {
          let professionalData: ICCKProfessional = res.data.data;
          setProfessional(res.data.data);
          setContent(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(professionalData?.experience || "")
                  .contentBlocks
              )
            )
          );
          setAvailableTimeContent(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(professionalData?.available_time || "")
                  .contentBlocks
              )
            )
          );
          // CCKDaySlotServices.getAll(token as string)
          //   .then((res) => {
          //     let daySlots: ICCKDaySlot[] = res.data.data;
          //     CCKTimeSlotServices.getAll(token as string)
          //       .then((resTime) => {
          //         let timeSlots: ICCKTimeSlot[] = resTime.data.data;
          //         let freeTimeSlotData: {
          //           time: ICCKTimeSlot;
          //           checked: boolean;
          //         }[] = timeSlots.map((timeSlot) => {
          //           return {
          //             time: timeSlot,
          //             checked: false,
          //           };
          //         });
          //         let freeSlotsData: {
          //           day: ICCKDaySlot;
          //           times: {
          //             time: ICCKTimeSlot;
          //             checked: boolean;
          //           }[];
          //         }[] = daySlots.map((daySlot) => {
          //           let dayMatch: {
          //             day: ICCKDaySlot;
          //             times: {
          //               time: ICCKTimeSlot;
          //               checked: boolean;
          //             }[];
          //           } | null = null;
          //           professionalData.free_slots.forEach((freeSlot) => {
          //             if (daySlot.id === freeSlot.day.id) {
          //               dayMatch = freeSlot;
          //             }
          //           });
          //           let freeTimeSlotDataClone: {
          //             time: ICCKTimeSlot;
          //             checked: boolean;
          //           }[] = JSON.parse(JSON.stringify(freeTimeSlotData));
          //           if (dayMatch !== null) {
          //             freeTimeSlotDataClone.forEach((freeTimeSlot) => {
          //               dayMatch?.times.forEach((time) => {
          //                 if (freeTimeSlot.time.id === time.time.id) {
          //                   freeTimeSlot.checked = true;
          //                 }
          //               });
          //             });
          //           }
          //           return {
          //             day: daySlot,
          //             times: freeTimeSlotDataClone,
          //           };
          //         });
          //         setFreeSlots(freeSlotsData);
          //       })
          //       .catch(() => {
          //         onAlert("error", "Something went wrong");
          //       });
          //   })
          //   .catch(() => {
          //     onAlert("error", "Something went wrong");
          //   });
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    };

    fetchData();
  }, [id, onAlert, token]);

  if (professional) {
    return (
      <>
        <Header
          title="Professional Details"
          breadcrumbs={[
            {
              label: "Dashboard",
              href: `/dashboard`,
            },
            {
              label: "Simulator Bay",
              href: `/simulator-bay/dashboard`,
            },
            {
              label: "Professional",
              href: "/simulator-bay/professional",
            },
            {
              label: `${professional.first_name} ${professional.last_name}`,
            },
          ]}
        />
        <Paper>
          <Box
            sx={{
              p: 2,
            }}
          >
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "150px",
                  height: "150px",
                  borderRadius: "75px",
                  backgroundColor: "#129747",
                  mb: 3,
                }}
              >
                <>
                  {professional.profile_url ? (
                    <Avatar
                      alt="Profile picture"
                      src={
                        process.env.REACT_APP_FILE + professional.profile_url
                      }
                      sx={{
                        width: "140px",
                        height: "140px",
                      }}
                    />
                  ) : (
                    <AccountCircle
                      style={{
                        color: "white",
                      }}
                      sx={{
                        width: "170px",
                        height: "170px",
                      }}
                    />
                  )}
                </>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="First name *"
                    variant="outlined"
                    fullWidth
                    defaultValue={professional.first_name}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Last name *"
                    variant="outlined"
                    fullWidth
                    defaultValue={professional.last_name}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Phone number"
                    type="numeric"
                    fullWidth
                    inputMode="numeric"
                    variant="outlined"
                    defaultValue={professional.tel}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    defaultValue={professional.email}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Age"
                    variant="outlined"
                    type="number"
                    fullWidth
                    defaultValue={professional.age}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Type *"
                    variant="outlined"
                    fullWidth
                    defaultValue={professional.type}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth disabled>
                    <Editor
                      toolbarHidden
                      stripPastedStyles
                      editorState={content}
                      editorClassName={`text-editor-css-disable`}
                      readOnly
                    />
                    <FormHelperText
                      sx={{
                        position: "absolute",
                        fontSize: 12,
                        left: 9,
                        top: -12,
                        px: 0.5,
                        bgcolor: "white",
                        mx: 0,
                      }}
                    >
                      {"Experience *"}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth disabled>
                    <Editor
                      toolbarHidden
                      stripPastedStyles
                      editorState={availableTimeContent}
                      editorClassName={`text-editor-css-disable`}
                      readOnly
                    />
                    <FormHelperText
                      sx={{
                        position: "absolute",
                        fontSize: 12,
                        left: 9,
                        top: -12,
                        px: 0.5,
                        bgcolor: "white",
                        mx: 0,
                      }}
                    >
                      {"Available Time *"}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Price *"
                    variant="outlined"
                    fullWidth
                    defaultValue={professional.price}
                    disabled
                  />
                </Grid>
              </Grid>
            </>
          </Box>
          {/* {freeSlots.length > 0 ? (
            <TableContainer>
              <Table
                sx={{ minWidth: 650, height: "1px" }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        backgroundColor: "#F4F6F8",
                        width: "50px",
                        borderRadius: "8px 0px 0px 8px",
                      }}
                    >
                      Time slot
                    </TableCell>
                    {freeSlots[0].times.map((timeSlot, index) => {
                      return (
                        <TableCell
                          sx={{
                            backgroundColor: "#F4F6F8",
                            px: "8px",
                            borderRadius:
                              index === freeSlots[0].times.length - 1
                                ? "0px 8px 8px 0px"
                                : "",
                          }}
                          align="center"
                        >
                          {timeSlot.time.time_start.substring(0, 5)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {freeSlots.map((freeSlot, index) => (
                    <TableRow key={`row-${index}`}>
                      <TableCell component="th" scope="row" sx={{}}>
                        <span>{freeSlot.day.name}</span>
                      </TableCell>
                      {freeSlot.times.map(
                        (freeDayTimeSlot, indexFreeDayTimeSlot) => {
                          return (
                            <TableCell
                              key={`column-${indexFreeDayTimeSlot}`}
                              component="th"
                            >
                              <Checkbox
                                disabled
                                checked={freeDayTimeSlot.checked}
                              />
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null} */}
          <Divider />
          <Box
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Link to="/simulator-bay/professional">
              <Button
                type="button"
                variant="contained"
                size="large"
                color="error"
              >
                <KeyboardReturn
                  sx={{
                    fontSize: 16,
                    mr: 1,
                  }}
                />
                <Typography>กลับ</Typography>
              </Button>
            </Link>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="success"
            >
              <Add
                sx={{
                  fontSize: 16,
                  mr: 1,
                }}
              />
              <Typography>สร้าง</Typography>
            </Button>
          </Box>
        </Paper>
      </>
    );
  } else {
    return <></>;
  }
};

export default withAuth(
  withPermission(ProfessionalDetail, "MANAGE_SIMULATOR_BAY")
);
