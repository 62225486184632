import { IPaginationRequest } from "interfaces/pagination";
import axios from "axios";

export type TDoneRewardRedemptionData = {
  image: string;
};

const RewardRedemptionService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/reward-redemption`,
});

const RewardRedemptionServices = {
  getAll: (
    token: string,
    line_oa_code: string,
    pagination: IPaginationRequest,
    search: string,
    filterStatus: string,
    date: {
      start: string;
      end: string;
    }
  ) =>
    RewardRedemptionService.get(
      `/${line_oa_code}/list?perPage=${pagination.per_page}&page=${pagination.page}&search=${search}&filterStatus=${filterStatus}&dateStart=${date.start}&dateEnd=${date.end}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ),
  export: (
    token: string,
    line_oa_code: string,
    search: string,
    filterStatus: string,
    date: {
      start: string;
      end: string;
    }
  ) =>
    RewardRedemptionService.get(
      `/${line_oa_code}/export?search=${search}&filterStatus=${filterStatus}&dateStart=${date.start}&dateEnd=${date.end}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ),
  done: (
    token: string,
    line_oa_code: string,
    id: string,
    data: TDoneRewardRedemptionData
  ) =>
    RewardRedemptionService.put(`/${line_oa_code}/${id}/done`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  pending: (token: string, line_oa_code: string, id: string) =>
    RewardRedemptionService.put(`/${line_oa_code}/${id}/pending`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default RewardRedemptionServices;
