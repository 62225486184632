import { Box, Tooltip, Typography } from "@mui/material";
import { IBooking, IBookingLog } from "interfaces/booking";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import AvatarLetter from "components/common/AvatarLetter/AvatarLetter";
import FieldList from "components/common/FieldList";
import Header from "components/common/Header";
import Table from "components/common/Table/Table";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import { IService } from "interfaces/service";
import { IUser } from "interfaces/user";
import CobraServiceServices from "services/cobraService";
import CobraBookingServices from "services/cobraBooking";

interface IBookingLogData {
  id: number;
  name: string;
  tel: string;
  email: string;
  booking_date: string;
  start_end_time: string;
  services: IService[];
  status: "Active" | "Canceled";
  created_by: IUser | null;
  created_at: string;
}

const BookingDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useToken();
  const { onAlert } = useAlert();

  const [booking, setBooking] = useState<IBooking | null>(null);
  const [bookingLogs, setBookingLogs] = useState<IBookingLogData[] | null>(
    null
  );
  const [services, setServices] = useState<
    {
      label: any;
      value: any;
      checked: boolean;
    }[]
  >([]);

  useEffect(() => {
    let servicesData: {
      label: any;
      value: any;
      checked: boolean;
    }[] = [];
    CobraServiceServices.getAll(token as string, {
      page: 1,
      per_page: 100,
    })
      .then((res) => {
        servicesData = res.data.data.map((service: IService, index: number) => {
          return {
            label: `${service.name} (${service.duration} min)`,
            value: service.id,
          };
        });
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
      });
    const fetchData = () => {
      CobraBookingServices.getDetail(
        token as string,
        id as string
      )
        .then((res) => {
          let bookingData: IBooking = res.data.data;
          setBooking(res.data.data);
          setServices(
            servicesData.map((service, index) => {
              let isChecked = false;
              bookingData.services.forEach((booking_service: IService) => {
                if (booking_service.id === service.value) {
                  isChecked = true;
                }
              });
              return { ...service, checked: isChecked };
            })
          );
          setBookingLogs(
            bookingData.booking_logs.map((d: IBookingLog) => {
              return {
                id: d.id,
                name: d.first_name + " " + d.last_name,
                tel: d.tel,
                email: d.email || "-",
                booking_date: d.booking_date,
                start_end_time: `${d.time_slots[0].time_start.substring(
                  0,
                  5
                )} น. - ${d.time_slots[
                  d.time_slots.length - 1
                ].time_end.substring(0, 5)} น.`,
                services: d.services,
                status: d.status,
                created_by: d.created_by,
                created_at: d.created_at,
              } as IBookingLogData;
            })
          );
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    };

    fetchData();
  }, [id, token, onAlert]);

  if (booking) {
    return (
      <>
        <Header
          title="Booking Details"
          breadcrumbs={[
            {
              label: "Dashboard",
              href: `/dashboard`,
            },
            {
              label: "Cobra",
              href: `/calendar/cobra`,
            },
            {
              label: "Booking",
              href: `/booking/cobra/list`,
            },
            {
              label: booking.code,
            },
          ]}
        />
        <FieldList
          type="detail"
          fields={[
            {
              label: "Code",
              type: "text",
              name: "code",
              defaultValue: booking.code,
              col: 6,
            },
            {
              label: "",
              type: "box",
              name: "",
              col: 6,
            },
            {
              label: "First Name",
              type: "text",
              name: "first_name",
              defaultValue: booking.first_name,
              col: 6,
            },
            {
              label: "Last Name",
              type: "text",
              name: "last_name",
              defaultValue: booking.last_name,
              col: 6,
            },
            {
              label: "Phone Number",
              type: "text",
              name: "tel",
              defaultValue: booking.tel,
              col: 6,
            },
            {
              label: "E-mail",
              type: "text",
              name: "email",
              defaultValue: booking.email,
              col: 6,
            },
            {
              label: "Booking Date",
              type: "date",
              name: "booking_date",
              defaultValue: booking.booking_date,
              col: 6,
            },
            {
              label: "Start Slot Time",
              type: "text",
              name: "start_slot_time",
              defaultValue: `${booking.booking_start_time.substring(0, 5)} น.`,
              col: 6,
            },
            {
              label: "Services",
              type: "check-list",
              name: "services",
              col: 12,
              items: services,
            },
          ]}
          onBack={() => navigate(`/booking/cobra/list`)}
          content={
            bookingLogs ? (
              <Box
                sx={{
                  p: 2,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "20px",
                    mb: 1,
                  }}
                >
                  Booking Logs
                </Typography>
                <Table
                  headers={[
                    {
                      key: "name",
                      label: "Name",
                    },
                    {
                      key: "tel",
                      label: "Phone Number",
                    },
                    {
                      key: "email",
                      label: "E-mail",
                    },
                    {
                      key: "booking_date",
                      label: "Booking date",
                    },
                    {
                      key: "start_end_time",
                      label: "Start-End time",
                    },
                    {
                      key: "start_end_time",
                      label: "Services",
                      render(value, index, item) {
                        return (
                          <Box>
                            {item.services.map((s) => {
                              return (
                                <Box
                                  sx={{
                                    display: "inline-block",
                                    mr: 1,
                                    mb: 1,
                                  }}
                                >
                                  <Tooltip
                                    title={`${s.name} (${s.duration} min)`}
                                  >
                                    <Box
                                      sx={{
                                        cursor: "pointer",
                                        display: "inline-block",
                                        backgroundColor: "#D9D9D9",
                                        borderRadius: "6px",
                                        minWidth: "30px",
                                        minHeight: "30px",
                                        padding: "5px",
                                        textAlign: "center",
                                        color: "#212B36",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {s.id}
                                    </Box>
                                  </Tooltip>
                                </Box>
                              );
                            })}
                          </Box>
                        );
                      },
                    },
                    {
                      key: "status",
                      label: "Status",
                      render(value, index, item) {
                        return (
                          <Typography
                            sx={{
                              color: value === "Active" ? "green" : "red",
                            }}
                          >
                            {value}
                          </Typography>
                        );
                      },
                    },
                    {
                      key: "created_by",
                      label: "Edited By",
                      render: (value: IUser | null, index, item) => (
                        <Box
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          {value ? (
                            <Box
                              sx={{
                                mb: 1,
                              }}
                            >
                              <AvatarLetter label={value.name} tooltip center />
                            </Box>
                          ) : null}
                          <Typography>
                            {new Date(item.created_at).toLocaleString("th-TH")}
                          </Typography>
                        </Box>
                      ),
                    },
                  ]}
                  data={bookingLogs}
                />
              </Box>
            ) : null
          }
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default withAuth(withPermission(BookingDetail, "MANAGE_COBRA"));
