import { IPaginationRequest } from "interfaces/pagination";
import axios from "axios";

const CobraMemberLogService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/cobra/member-log`,
});

const CobraMemberLogServices = {
  getAll: (
    token: string,
    pagination: IPaginationRequest,
    search: string,
    date: {
      start: string;
      end: string;
    }
  ) =>
    CobraMemberLogService.get(
      `/list?perPage=${pagination.per_page}&page=${pagination.page}&search=${search}&startDate=${date.start}&endDate=${date.end}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ),
};

export default CobraMemberLogServices;
