import {
  Box,
  FormControl,
  FormHelperText,
  Tooltip,
  Typography,
} from "@mui/material";

import { IField } from "./Field";
import { Info } from "@mui/icons-material";

const TextAreaField: React.FC<IField> = ({
  form,
  name,
  label,
  type,
  disabled,
  inputOptions,
  error,
  errorMessage,
  defaultValue,
  tooltip,
  maxLength,
}) => {
  return (
    <FormControl key={"field-" + name} fullWidth error={error}>
      <textarea
        aria-label="textarea"
        placeholder=""
        className={`${error ? "error" : ""}`}
        // style={{
        //   width: "100%",
        //   height: "120px",
        //   resize: "none",
        //   padding: "12px",
        //   paddingRight: "44px",
        //   fontSize: "16px",
        //   borderRadius: "8px",
        //   borderColor: error ? "#d32f2f" : "#e8ecee",
        //   outlineColor: error ? "#d32f2f" : "",
        //   fontFamily: "Public Sans, sans-serif",
        // }}
        {...form.register(name, {
          ...inputOptions,
        })}
        maxLength={maxLength}
        defaultValue={defaultValue}
        disabled={disabled}
      />
      <FormHelperText
        sx={{
          position: "absolute",
          fontSize: 12,
          left: 9,
          top: -12,
          px: 0.5,
          bgcolor: "white",
          mx: 0,
        }}
      >
        {inputOptions?.required ? label + " *" : label}
      </FormHelperText>
      {tooltip && (
        <Tooltip title={tooltip}>
          <Info
            sx={{
              color: "#757575",
              position: "absolute",
              right: 14,
              top: 15,
              cursor: "pointer",
            }}
          />
        </Tooltip>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <FormHelperText>{errorMessage}</FormHelperText>
        {maxLength && (
          <Typography color={"#757575"}>
            <>
              {(form.watch(name) && form.watch(name).length) || 0}/{maxLength}
            </>
          </Typography>
        )}
      </Box>
    </FormControl>
  );
};

export default TextAreaField;
