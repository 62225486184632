import { AccountCircle, Add, KeyboardReturn } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import CCKDaySlotServices from "services/cckDaySlot";
import CCKProfessionalServices from "services/cckProfessional";
import CCKTimeSlotServices from "services/cckTimeSlot";
import { Editor } from "react-draft-wysiwyg";
import FileServices from "services/file";
import Header from "components/common/Header";
import { ICCKDaySlot } from "interfaces/cckDaySlot";
import { ICCKTimeSlot } from "interfaces/cckBooking";
import Swal from "sweetalert2";
import draftToHtml from "draftjs-to-html";
import useAlert from "hooks/useAlert";
import { useForm } from "react-hook-form";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

type TCreateProfessional = {
  profile_url: File | null;
  first_name: string;
  last_name: string;
  tel: string;
  email: string;
  age: string;
  type: string;
  experience: string;
  available_time: string;
  experience_clone: string;
  available_time_clone: string;
  price: string;
};

const CreateProfessional = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { onAlert } = useAlert();
  // const [freeSlots, setFreeSlots] = useState<
  //   {
  //     day: ICCKDaySlot;
  //     times: {
  //       time: ICCKTimeSlot;
  //       checked: boolean;
  //     }[];
  //   }[]
  // >([]);
  const [content, setContent] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const [availableTimeContent, setAvailableTimeContent] = useState<EditorState>(
    EditorState.createEmpty()
  );
  // const [isReady, setIsReady] = useState<boolean>(false);

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<TCreateProfessional>();

  const onEnterComment = (editorState: EditorState) => {
    setContent(editorState);
  };

  const onEnterAvailableTime = (editorState: EditorState) => {
    setAvailableTimeContent(editorState);
  };

  useEffect(() => {
    let htmlText = draftToHtml(convertToRaw(content.getCurrentContent()));
    let text = htmlText.replace(/(<([^>]+)>)/gi, "").replace(/[\r\n]/gm, "");
    if (!250 || (250 && text.length <= 250)) {
      setValue("experience_clone", htmlText);
      setValue("experience", text);
    }
  }, [content]);

  useEffect(() => {
    let htmlText = draftToHtml(convertToRaw(availableTimeContent.getCurrentContent()));
    let text = htmlText.replace(/(<([^>]+)>)/gi, "").replace(/[\r\n]/gm, "");
    if (!250 || (250 && text.length <= 250)) {
      setValue("available_time_clone", htmlText);
      setValue("available_time", text);
    }
  }, [availableTimeContent]);

  useEffect(() => {
    clearErrors("experience");
  }, [content]);

  useEffect(() => {
    clearErrors("available_time");
  }, [availableTimeContent]);

  // useEffect(() => {
  //   CCKDaySlotServices.getAll(token as string)
  //     .then((res) => {
  //       let daySlots: ICCKDaySlot[] = res.data.data;
  //       CCKTimeSlotServices.getAll(token as string)
  //         .then((resTime) => {
  //           let timeSlots: ICCKTimeSlot[] = resTime.data.data;
  //           let freeTimeSlotData: {
  //             time: ICCKTimeSlot;
  //             checked: boolean;
  //           }[] = timeSlots.map((timeSlot) => {
  //             return {
  //               time: timeSlot,
  //               checked: false,
  //             };
  //           });
  //           let freeSlotsData: {
  //             day: ICCKDaySlot;
  //             times: {
  //               time: ICCKTimeSlot;
  //               checked: boolean;
  //             }[];
  //           }[] = daySlots.map((daySlot) => {
  //             return {
  //               day: daySlot,
  //               times: freeTimeSlotData,
  //             };
  //           });
  //           setFreeSlots(freeSlotsData);
  //         })
  //         .catch(() => {
  //           onAlert("error", "Something went wrong");
  //         });
  //     })
  //     .catch(() => {
  //       onAlert("error", "Something went wrong");
  //     });
  // }, []);

  const onConfirm = (data: TCreateProfessional) => {
    Swal.fire({
      icon: "warning",
      html: `ต้องการสร้างใช่ หรือไม่?`,
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        onSubmit(data);
      }
    });
  };

  const onSubmit = async (data: TCreateProfessional) => {
    let dataProps: {
      profile_url: string | null;
      first_name: string;
      last_name: string;
      tel: string;
      email: string | null;
      age: number | null;
      type: string;
      experience: string | null;
      available_time: string | null;
      price: string;
    } = {
      profile_url: null,
      first_name: data.first_name,
      last_name: data.last_name,
      tel: data.tel,
      email: data.email || null,
      age: parseInt(data.age) || null,
      type: data.type,
      experience: data.experience_clone || null,
      available_time: data.available_time_clone || null,
      price: data.price,
    };
    let isError = false;
    if (data.profile_url) {
      let formData = new FormData();
      formData.append("file", data.profile_url);
      formData.append("prefix", "professional");
      formData.append("path", "/professional");
      await FileServices.create(token as string, formData)
        .then((res) => {
          dataProps.profile_url = res.data.data;
        })
        .catch((err) => {
          isError = true;
          onAlert("error", err.response.data.message);
        });
    }
    if (!isError) {
      CCKProfessionalServices.create(token as string, {
        ...dataProps,
        // free_slots: freeSlots.map((freeSlot) => {
        //   return {
        //     ...freeSlot,
        //     times: freeSlot.times.filter((time) => time.checked),
        //   };
        // }),
      })
        .then((res) => {
          onAlert("success", "Create professional success");
          navigate(`/simulator-bay/professional`);
        })
        .catch((err) => {
          onAlert("error", "Please try again later");
          navigate(`/simulator-bay/professional`);
        });
    }
  };

  return (
    <>
      <Header
        title="Create a new professional"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: `/dashboard`,
          },
          {
            label: "Simulator Bay",
            href: `/simulator-bay/dashboard`,
          },
          {
            label: "Professional",
            href: "/simulator-bay/professional",
          },
          {
            label: "New professional",
          },
        ]}
      />
      <Paper>
        <form onSubmit={handleSubmit(onConfirm)}>
          <Box
            sx={{
              p: 2,
            }}
          >
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "150px",
                    height: "150px",
                    borderRadius: "75px",
                    backgroundColor: "#129747",
                  }}
                >
                  {watch("profile_url") ? (
                    <Avatar
                      alt="Profile picture"
                      src={window.URL.createObjectURL(
                        watch("profile_url") as File
                      )}
                      sx={{
                        width: "140px",
                        height: "140px",
                      }}
                    />
                  ) : (
                    <AccountCircle
                      style={{
                        color: "white",
                      }}
                      sx={{
                        width: "170px",
                        height: "170px",
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    paddingLeft: "30px",
                  }}
                >
                  <div>
                    <Button
                      variant="contained"
                      component="label"
                      color="success"
                      sx={{
                        mb: 1,
                      }}
                    >
                      Upload picture
                      <input
                        type="file"
                        hidden
                        onChange={(e) =>
                          setValue(
                            "profile_url",
                            e.target.files ? e.target.files[0] : null
                          )
                        }
                      />
                    </Button>
                  </div>
                  <div>
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={() => setValue("profile_url", null)}
                    >
                      Delete picture
                    </Button>
                  </div>
                </Box>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="First name *"
                    variant="outlined"
                    fullWidth
                    {...register("first_name", {
                      required: "Please enter first name",
                    })}
                    error={!!errors.first_name?.message}
                    helperText={errors.first_name?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Last name *"
                    variant="outlined"
                    fullWidth
                    {...register("last_name", {
                      required: "Please enter last name",
                    })}
                    error={!!errors.last_name?.message}
                    helperText={errors.last_name?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Phone number *"
                    type="numeric"
                    fullWidth
                    inputMode="numeric"
                    variant="outlined"
                    {...register("tel", {
                      required: "Please enter phone number for send sms alert",
                    })}
                    error={!!errors.tel?.message}
                    helperText={errors.tel?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    {...register("email")}
                    error={!!errors.email?.message}
                    helperText={errors.email?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Age"
                    variant="outlined"
                    type="number"
                    fullWidth
                    {...register("age")}
                    error={!!errors.age?.message}
                    helperText={errors.age?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Type *"
                    variant="outlined"
                    fullWidth
                    {...register("type", {
                      required: "Please enter type of professional",
                    })}
                    error={!!errors.type?.message}
                    helperText={errors.type?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth error={!!errors.experience?.message}>
                    <Editor
                      toolbarHidden
                      stripPastedStyles
                      editorState={content}
                      onEditorStateChange={onEnterComment}
                      editorClassName={`text-editor-css ${
                        !!errors.experience?.message ? "error" : ""
                      }`}
                    />
                    <input {...register("experience")} hidden />
                    <FormHelperText
                      sx={{
                        position: "absolute",
                        fontSize: 12,
                        left: 9,
                        top: -12,
                        px: 0.5,
                        bgcolor: "white",
                        mx: 0,
                      }}
                    >
                      Experience
                    </FormHelperText>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormHelperText>
                        {errors.experience?.message}
                      </FormHelperText>
                      <Typography color={"#757575"}>
                        <>
                          {(watch("experience") &&
                            watch("experience")
                              .replace(/(<([^>]+)>)/gi, "")
                              .replace(/[\r\n]/gm, "").length) ||
                            0}
                          /250
                        </>
                      </Typography>
                    </Box>
                  </FormControl>
                  {/* <FormControl fullWidth error={!!errors.experience?.message}>
                    <textarea
                      aria-label="textarea"
                      placeholder=""
                      className={`${
                        !!errors.experience?.message ? "error" : ""
                      }`}
                      maxLength={250}
                      {...register("experience")}
                    />
                    <FormHelperText
                      sx={{
                        position: "absolute",
                        fontSize: 12,
                        left: 9,
                        top: -12,
                        px: 0.5,
                        bgcolor: "white",
                        mx: 0,
                      }}
                    >
                      {"Experience"}
                    </FormHelperText>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormHelperText>
                        {errors.experience?.message || ""}
                      </FormHelperText>
                      <Typography color={"#757575"}>
                        {`${
                          watch("experience") ? watch("experience").length : 0
                        }
                            /250`}
                      </Typography>
                    </Box>
                  </FormControl> */}
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth error={!!errors.available_time?.message}>
                    <Editor
                      toolbarHidden
                      stripPastedStyles
                      editorState={availableTimeContent}
                      onEditorStateChange={onEnterAvailableTime}
                      editorClassName={`text-editor-css ${
                        !!errors.available_time?.message ? "error" : ""
                      }`}
                    />
                    <input {...register("available_time")} hidden />
                    <FormHelperText
                      sx={{
                        position: "absolute",
                        fontSize: 12,
                        left: 9,
                        top: -12,
                        px: 0.5,
                        bgcolor: "white",
                        mx: 0,
                      }}
                    >
                      Available Time
                    </FormHelperText>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormHelperText>
                        {errors.available_time?.message}
                      </FormHelperText>
                      <Typography color={"#757575"}>
                        <>
                          {(watch("available_time") &&
                            watch("available_time")
                              .replace(/(<([^>]+)>)/gi, "")
                              .replace(/[\r\n]/gm, "").length) ||
                            0}
                          /250
                        </>
                      </Typography>
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Price *"
                    variant="outlined"
                    fullWidth
                    {...register("price", {
                      required: "Please enter price",
                    })}
                    error={!!errors.price?.message}
                    helperText={errors.price?.message}
                  />
                </Grid>
              </Grid>
            </>
          </Box>
          {/* {freeSlots.length > 0 ? (
            <TableContainer>
              <Table
                sx={{ minWidth: 650, height: "1px" }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        backgroundColor: "#F4F6F8",
                        width: "50px",
                        borderRadius: "8px 0px 0px 8px",
                      }}
                    >
                      Time slot
                    </TableCell>
                    {freeSlots[0].times.map((timeSlot, index) => {
                      return (
                        <TableCell
                          sx={{
                            backgroundColor: "#F4F6F8",
                            px: "8px",
                            borderRadius:
                              index === freeSlots[0].times.length - 1
                                ? "0px 8px 8px 0px"
                                : "",
                          }}
                          align="center"
                        >
                          {timeSlot.time.time_start.substring(0, 5)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {freeSlots.map((freeSlot, index) => (
                    <TableRow key={`row-${index}`}>
                      <TableCell component="th" scope="row" sx={{}}>
                        <span>{freeSlot.day.name}</span>
                      </TableCell>
                      {freeSlot.times.map(
                        (freeDayTimeSlot, indexFreeDayTimeSlot) => {
                          return (
                            <TableCell
                              key={`column-${indexFreeDayTimeSlot}`}
                              component="th"
                            >
                              <Checkbox
                                checked={freeDayTimeSlot.checked}
                                onChange={(e) => {
                                  setFreeSlots((freeSlots) => {
                                    let freeSlotsData = [...freeSlots];
                                    let times = [...freeSlotsData[index].times];
                                    times[indexFreeDayTimeSlot] = {
                                      ...times[indexFreeDayTimeSlot],
                                      checked: e.target.checked,
                                    };
                                    freeSlotsData[index] = {
                                      ...freeSlots[index],
                                      times: times,
                                    };
                                    return freeSlotsData;
                                  });
                                }}
                              />
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null} */}
          <Divider />
          <Box
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Link to="/simulator-bay/professional">
              <Button
                type="button"
                variant="contained"
                size="large"
                color="error"
              >
                <KeyboardReturn
                  sx={{
                    fontSize: 16,
                    mr: 1,
                  }}
                />
                <Typography>กลับ</Typography>
              </Button>
            </Link>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="success"
            >
              <Add
                sx={{
                  fontSize: 16,
                  mr: 1,
                }}
              />
              <Typography>สร้าง</Typography>
            </Button>
          </Box>
        </form>
      </Paper>
    </>
  );
};

export default withAuth(
  withPermission(CreateProfessional, "MANAGE_SIMULATOR_BAY")
);
