import { Box, Grid, Typography } from "@mui/material";

interface Props {
  onChangeStep: (step: number) => void;
}

const StepSelectBooking: React.FC<Props> = ({ onChangeStep }) => {
  return (
    <>
      <Box
        sx={{
          p: 2,
          py: 5,
        }}
      >
        <Grid container spacing={6}>
          <Grid item xs={6} mx="auto">
            <Box
              sx={[
                {
                  "&:hover": {
                    backgroundColor: "#EF2F2C",
                    transition: "all",
                    transitionDuration: "0.3s",
                    ".content": {
                      filter: "brightness(0) invert(1)",
                      transition: "all",
                      transitionDuration: "0.3s",
                    },
                  },
                },
                {
                  cursor: "pointer",
                  background: "#FFFFFF",
                  "box-shadow": "0px 15px 40px rgba(43, 43, 43, 0.1)",
                  "border-radius": "24px",
                  border: "none",
                  p: 4,
                  px: 8,
                  mx: "auto",
                  width: "100%",
                },
              ]}
              component="button"
              onClick={() => onChangeStep(0)}
            >
              <div className="content">
                <img
                  src="/normal.png"
                  height={150}
                  alt="Booking Simulator Bay"
                />
                <Typography
                  sx={{
                    fontWeight: 700,
                    color: "#EF2F2C",
                    mt: 2,
                  }}
                >
                  Booking Simulator Bay
                </Typography>
              </div>
            </Box>
          </Grid>
          <Grid item xs={6} mx="auto">
            <Box
              sx={[
                {
                  "&:hover": {
                    backgroundColor: "#2C7AEF",
                    transition: "all",
                    transitionDuration: "0.3s",
                    ".content": {
                      filter: "brightness(0) invert(1)",
                      transition: "all",
                      transitionDuration: "0.3s",
                    },
                  },
                },
                {
                  cursor: "pointer",
                  background: "#FFFFFF",
                  "box-shadow": "0px 15px 40px rgba(43, 43, 43, 0.1)",
                  "border-radius": "24px",
                  border: "none",
                  p: 4,
                  px: 8,
                  width: "100%",
                },
              ]}
              component="button"
              onClick={() => onChangeStep(4)}
            >
              <div className="content">
                <img
                  src="/training.png"
                  height={150}
                  alt="Training with Professional"
                />
                <Typography
                  sx={{
                    fontWeight: 700,
                    color: "#2C7AEF",
                    mt: 2,
                  }}
                >
                  Training with Professional
                </Typography>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default StepSelectBooking;
