import { IPaginationRequest } from "interfaces/pagination";
import axios from "axios";

export type TCreatePromotionData = {
  name: string;
  description: string;
  image: string;
  price: number;
  url: string;
};

const cobraPromotionService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/cobra/promotion`,
});

const CobraPromotionServices = {
  getDetail: (token: string, id: string) =>
    cobraPromotionService.get(`/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getAll: (
    token: string,
    pagination: IPaginationRequest
  ) =>
    cobraPromotionService.get(
      `/list?perPage=${pagination.per_page}&page=${pagination.page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ),
  create: (token: string, data: TCreatePromotionData) =>
    cobraPromotionService.post("", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  edit: (
    token: string,
    id: string | number,
    data: TCreatePromotionData
  ) =>
    cobraPromotionService.put(`/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  delete: (token: string, id: string | number) =>
    cobraPromotionService.delete(`/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default CobraPromotionServices;
