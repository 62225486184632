import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import FieldList from "components/common/FieldList";
import Header from "components/common/Header";
import { IService } from "interfaces/service";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import CobraServiceServices from "services/cobraService";

const ServiceDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useToken();
  const { onAlert } = useAlert();

  const [service, setService] = useState<IService | null>(null);

  useEffect(() => {
    const fetchData = () => {
      CobraServiceServices.getDetail(
        token as string,
        id as string
      )
        .then((res) => {
          setService(res.data.data);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    };

    fetchData();
  }, [id, token, onAlert]);

  if (service) {
    return (
      <>
        <Header
          title="Service Details"
          breadcrumbs={[
            {
              label: "Dashboard",
              href: `/dashboard`,
            },
            {
              label: "Cobra",
              href: `/calendar/cobra`,
            },
            {
              label: "Service",
              href: `/service/cobra/list`,
            },
            {
              label: service.name,
            },
          ]}
        />
        <FieldList
          type="detail"
          fields={[
            {
              label: "Name",
              type: "text",
              name: "name",
              defaultValue: service.name,
              col: 6,
            },
            {
              label: "Duration (Min)",
              type: "number",
              name: "duration",
              defaultValue: service.duration,
              col: 6,
            },
            {
              label: "Sequence",
              type: "number",
              name: "sequence",
              defaultValue: service.sequence,
              col: 6,
            },
          ]}
          onBack={() => navigate(`/service/cobra/list`)}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default withAuth(withPermission(ServiceDetail, "MANAGE_COBRA"));
