import { IPagination, IPaginationRequest } from "interfaces/pagination";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import AvatarLetter from "components/common/AvatarLetter";
import { Button } from "@mui/material";
import Header from "components/common/Header";
import { IPromotion } from "interfaces/promotion";
import PromotionServices from "services/promotion";
import Swal from "sweetalert2";
import Table from "components/common/Table";
import Typography from "@mui/material/Typography";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

interface IPromotionData {
  id: number;
  name: string;
  description: string;
  image: string;
  price: number;
  last_edited_at: string;
  created_by: string;
  edited_by: string;
}

const ListPromotion = () => {
  let line_oa_code = "ONLY_GOLF";
  const navigate = useNavigate();
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [data, setData] = useState<{
    data: IPromotionData[];
    pagination: IPagination;
  }>({
    data: [],
    pagination: {
      page: 1,
      total_item: 0,
      total_page: 0,
    },
  });
  const [pagination, setPagination] = useState<IPaginationRequest>({
    page: 1,
    per_page: 10,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchData = useCallback(() => {
    PromotionServices.getAll(token as string, line_oa_code as string, {
      per_page: pagination.per_page,
      page: pagination.page,
    })
      .then((res) => {
        let paginationRes: IPagination = res.data.pagination;
        setData({
          data: res.data.data.map((d: IPromotion) => {
            return {
              id: d.id,
              name: d.name,
              description: d.description,
              image: d.image,
              price: d.price,
              last_edited_at: d.last_edited_at,
              created_by: d.admin_created.name,
              edited_by: d.admin_edited.name,
            };
          }),
          pagination: {
            page: paginationRes.page,
            total_item: paginationRes.total_item,
            total_page: paginationRes.total_page,
          },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
      });
  }, [line_oa_code, token, onAlert, pagination]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onDelete = (item: IPromotion) => {
    Swal.fire({
      icon: "warning",
      html: `ต้องการลบโปรโมชั่น <b>${item.name}</b> ใช่ หรือไม่?`,
      showCancelButton: true,
      confirmButtonText: "ลบ",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        PromotionServices.delete(
          token as string,
          line_oa_code as string,
          item.id
        )
          .then((res) => {
            fetchData();
            onAlert("success", "ลบโปรโมชั่นสำเร็จ");
          })
          .catch((err) => {
            onAlert("error", err.response.data.message);
          });
      }
    });
  };

  return (
    <>
      <Header
        title="Promotion"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: `/dashboard`,
          },
          {
            label: "Only Golf",
            href: `/dashboard/only-golf`,
          },
          {
            label: "Promotion",
            href: "/promotion/only-golf/list",
          },
          {
            label: "List",
          },
        ]}
        actions={[
          <Link to={`/promotion/only-golf/create`}>
            <Button
              variant="contained"
              size="large"
              disabled={data.data.length >= 5}
            >
              <AddIcon
                sx={{
                  fontSize: 14,
                  mr: 1,
                }}
              />
              <Typography>Create</Typography>
            </Button>
          </Link>,
        ]}
      />
      <Table
        headers={[
          {
            key: "image",
            label: "Image",
            render: (value: string, index: number, item: IPromotionData) => (
              <img
                style={{
                  borderRadius: "8px",
                  width: "150px",
                  height: "100%",
                  maxHeight: "80px",
                  objectFit: "cover",
                }}
                src={process.env.REACT_APP_FILE + value}
                alt={item.name}
              />
            ),
          },
          {
            key: "name",
            label: "Name",
          },
          {
            key: "description",
            label: "Description",
          },
          {
            key: "price",
            label: "Price",
            render: (value: string) => (
              <Typography sx={{ ml: 2 }}>{value.toLocaleString()}</Typography>
            ),
          },
          {
            key: "created_by",
            label: "Created By",
            render: (value) => <AvatarLetter label={value} tooltip />,
          },
          {
            key: "edited_by",
            label: "Edited By",
            render: (value) => <AvatarLetter label={value} tooltip />,
          },
          {
            key: "last_edited_at",
            label: "Last Edited At",
            render: (value) => (
              <Typography>{new Date(value).toLocaleString("th-TH")}</Typography>
            ),
          },
        ]}
        actions={{
          onDetail: (item: any) => navigate(`/promotion/only-golf/${item.id}`),
          onEdit: (item: any) =>
            navigate(`/promotion/only-golf/${item.id}/edit`),
          onDelete: (item: any) => onDelete(item),
        }}
        data={data.data}
        isLoading={isLoading}
        pagination={
          data.pagination
            ? {
                ...data.pagination,
                onChange: (page) =>
                  setPagination((pagination) => {
                    return {
                      ...pagination,
                      page,
                    };
                  }),
              }
            : undefined
        }
      />
    </>
  );
};

export default withAuth(withPermission(ListPromotion, "MANAGE_ONLY_GOLF"));
