import { Add, KeyboardReturn } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

interface Props {
  step: number;
  onChangeStep: (nextStep: number) => void;
  onSubmitStep1: (tel: string) => void;
}

type FormValues = {
  tel: string;
};

const Step1: React.FC<Props> = ({ step, onChangeStep, onSubmitStep1 }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const onSubmit = handleSubmit((data) => {
    onSubmitStep1(data.tel);
  });

  return (
    <>
      <form onSubmit={onSubmit}>
        <Box
          sx={{
            p: 2,
            py: 5,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6} mx="auto">
              <TextField
                fullWidth
                label="Phone number"
                {...register("tel", {
                  required: true,
                  minLength: 10,
                  maxLength: 10,
                  pattern: {
                    value: /^\d{10}$/,
                    message: "invalid phone number",
                  },
                })}
                error={!!errors.tel}
                helperText={errors.tel?.message}
                type="tel"
              />
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            type="button"
            variant="contained"
            size="large"
            color="error"
            onClick={() => onChangeStep(3)}
          >
            <KeyboardReturn
              sx={{
                fontSize: 16,
                mr: 1,
              }}
            />
            <Typography>Back</Typography>
          </Button>
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="success"
          >
            <Add
              sx={{
                fontSize: 16,
                mr: 1,
              }}
            />
            <Typography>Verify</Typography>
          </Button>
        </Box>
      </form>
    </>
  );
};

export default Step1;
