import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Delete, Remove } from "@mui/icons-material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CCKTimeSlotServices from "services/cckTimeSlot";
import { Dayjs } from "dayjs";
import { ICCKBay } from "interfaces/cckBay";
import { ICCKMember } from "interfaces/cckMember";
import { ICCKTimeSlot } from "interfaces/cckBooking";
import useToken from "hooks/useToken";

interface Props {
  bays: ICCKBay[];
  member: ICCKMember;
  index: number;
  bookingDate: string
  bookingBay: {
    bay_id: number;
    start_time: string;
    total_hour: number;
  };
  onChange: (
    data: {
      bay_id: number;
      start_time: string;
      total_hour: number;
    },
    index: number
  ) => void;
  onRemove: ((index: number) => void) | null;
}

const BookingBay: React.FC<Props> = ({
  bays,
  member,
  bookingDate,
  index,
  bookingBay,
  onChange,
  onRemove,
}) => {
  const { token } = useToken();
  const [timeSlots, setTimeSlots] = useState<ICCKTimeSlot[]>([]);
  const [totalHours, setTotalHours] = useState<
    {
      label: string;
      value: number;
    }[]
  >([]);

  useEffect(() => {
    CCKTimeSlotServices.getByDataAndBayIDAndProID(
      token as string,
      bookingDate,
      bookingBay.bay_id,
      member.professional?.id as number
    )
      .then((res) => {
        let timeSlotsData: ICCKTimeSlot[] = res.data.data;
        setTimeSlots(res.data.data);
        let timeSelected = timeSlotsData.filter(
          (ts) => ts.time_start === bookingBay.start_time
        );
        onChange(
          {
            ...bookingBay,
            start_time:
              timeSelected.length > 0
                ? timeSelected[0].time_start
                : timeSlotsData[0].time_start,
          },
          index
        );
      })
      .catch((err) => {});
  }, [bookingBay.bay_id, bookingDate]);

  useEffect(() => {
    if (timeSlots.length > 0 && bookingBay.start_time) {
      let totalHour = 0;
      let nowEndTime = "";
      timeSlots.forEach((timeSlot, i) => {
        if (timeSlot.time_start === bookingBay.start_time) {
          totalHour += 1;
          nowEndTime = timeSlot.time_end;
        }
        if (nowEndTime === timeSlot.time_start) {
          totalHour += 1;
          nowEndTime = timeSlot.time_end;
        }
      });
      let hourOptions = [];
      for (let i = 0; i < totalHour; i++) {
        hourOptions.push({
          label: i + 1 + "",
          value: i + 1,
        });
      }
      let hourSelected = hourOptions.filter(
        (h) => h.value === bookingBay.total_hour
      );
      onChange(
        {
          ...bookingBay,
          total_hour:
            hourSelected.length > 0
              ? hourSelected[0].value
              : hourOptions[0].value,
        },
        index
      );
      setTotalHours(hourOptions);
    } else {
      setTotalHours([]);
    }
  }, [timeSlots, bookingBay.start_time]);

  return (
    <Box
      sx={{
        mb: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        {onRemove ? (
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              onRemove(index);
            }}
          >
            <Delete />
          </Button>
        ) : null}
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id={`select-bay-${index}`}>Bay number *</InputLabel>
            <Select
              labelId={`select-bay-${index}`}
              value={bookingBay.bay_id}
              onChange={(e) =>
                onChange(
                  {
                    ...bookingBay,
                    bay_id: e.target.value as number,
                  },
                  index
                )
              }
            >
              {bays.map((bay: ICCKBay) => {
                return <MenuItem value={bay.id}>{bay.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}/>
        {timeSlots.length > 0 ? (
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id={`select-start-time-${index}`}>
                Start time *
              </InputLabel>
              <Select
                labelId={`select-start-time-${index}`}
                value={bookingBay.start_time}
                onChange={(e) =>
                  onChange(
                    {
                      ...bookingBay,
                      start_time: e.target.value as string,
                    },
                    index
                  )
                }
              >
                {timeSlots.map((ts) => {
                  return (
                    <MenuItem value={ts.time_start}>
                      {ts.time_start.substring(0, 5)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        ) : null}
        {totalHours.length > 0 ? (
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id={`select-total-hour-${index}`}>
                Total hour *
              </InputLabel>
              <Select
                labelId={`select-total-hour-${index}`}
                value={bookingBay.total_hour}
                onChange={(e) =>
                  onChange(
                    {
                      ...bookingBay,
                      total_hour: e.target.value as number,
                    },
                    index
                  )
                }
              >
                {totalHours.map((th) => {
                  return <MenuItem value={th.value}>{th.label}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

export default BookingBay;
