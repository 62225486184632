import { IPaginationRequest } from "interfaces/pagination";
import axios from "axios";

const CCKBookingService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/cck-booking`,
});

const CCKBookingServices = {
  getDetail: (token: string, id: string) =>
    CCKBookingService.get(`/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getAll: (
    token: string,
    pagination: IPaginationRequest,
    search: string,
    dateRange: {
      start: string;
      end: string;
    },
    enterAt: {
      start: string;
      end: string;
    },
  ) =>
    CCKBookingService.get(
      `list?perPage=${pagination.per_page}&page=${pagination.page}&search=${search}&startDate=${dateRange.start}&endDate=${dateRange.end}&enterStartAt=${enterAt.start}&enterEndAt=${enterAt.end}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ),
  export: (
    token: string,
    search: string,
    dateRange: {
      start: string;
      end: string;
    },
    enterAt: {
      start: string;
      end: string;
    }
  ) =>
    CCKBookingService.get(
      `export?search=${search}&startDate=${dateRange.start}&endDate=${dateRange.end}&enterStartAt=${enterAt.start}&enterEndAt=${enterAt.end}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ),
  getAllActiveByDate: (token: string, date: string) =>
    CCKBookingService.get(`all/active?date=${date}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  create: (
    token: string,
    data: {
      member_id: number;
      professional_id?: number;
      booking_date: string;
      booking_bays: {
        bay_id: number;
        start_time: string;
        total_hour: number;
      }[];
    }
  ) =>
    CCKBookingService.post(`/create`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  edit: (
    token: string,
    id: string | number,
    data: {
      bay_number: number;
      name: string;
    }
  ) =>
    CCKBookingService.put(`/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  delete: (token: string, id: string | number) =>
    CCKBookingService.delete(`/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  cancelBookingBay: (
    token: string,
    id: string | number,
    booking_bay_id: string | number
  ) =>
    CCKBookingService.delete(`/${id}/booking-bay/${booking_bay_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default CCKBookingServices;
