import BayCreate from "pages/simulator-bay/bay/create";
import BayDetail from "pages/simulator-bay/bay/detail";
import BayEdit from "pages/simulator-bay/bay/edit";
import BayList from "pages/simulator-bay/bay/list";
import BookingBayDetail from "pages/simulator-bay/booking-bay/detail";
import BookingCreate from "pages/simulator-bay/booking/create";
import BookingDetail from "pages/simulator-bay/booking/detail";
import BookingList from "pages/simulator-bay/booking/list";
import Dashboard from "pages/simulator-bay/dashboard";
import MemberDetail from "pages/simulator-bay/manage-member/member/detail";
import MemberEdit from "pages/simulator-bay/manage-member/member/edit";
import MemberList from "pages/simulator-bay/manage-member/member/list";
import MemberLogList from "pages/simulator-bay/manage-member/member-log/list";
import ProfessionalCreate from "pages/simulator-bay/professional/create";
import ProfessionalDetail from "pages/simulator-bay/professional/detail";
import ProfessionalEdit from "pages/simulator-bay/professional/edit";
import ProfessionalList from "pages/simulator-bay/professional/list";
import { RouteObject } from "react-router-dom";

const SimulatorBayRouters: RouteObject[] = [
  {
    path: "/simulator-bay/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/simulator-bay/member",
    element: <MemberList />,
  },
  {
    path: "/simulator-bay/member/:id/edit",
    element: <MemberEdit />,
  },
  {
    path: "/simulator-bay/member/:id",
    element: <MemberDetail />,
  },
  {
    path: "/simulator-bay/member-log",
    element: <MemberLogList />,
  },
  {
    path: "/simulator-bay/bay",
    element: <BayList />,
  },
  {
    path: "/simulator-bay/bay/create",
    element: <BayCreate />,
  },
  {
    path: "/simulator-bay/bay/:id",
    element: <BayDetail />,
  },
  {
    path: "/simulator-bay/bay/:id/edit",
    element: <BayEdit />,
  },
  {
    path: "/simulator-bay/professional",
    element: <ProfessionalList />,
  },
  {
    path: "/simulator-bay/professional/create",
    element: <ProfessionalCreate />,
  },
  {
    path: "/simulator-bay/professional/:id",
    element: <ProfessionalDetail />,
  },
  {
    path: "/simulator-bay/professional/:id/edit",
    element: <ProfessionalEdit />,
  },
  {
    path: "/simulator-bay/booking",
    element: <BookingList />,
  },
  {
    path: "/simulator-bay/booking/create",
    element: <BookingCreate />,
  },
  {
    path: "/simulator-bay/booking/:id",
    element: <BookingDetail />,
  },
  {
    path: "/simulator-bay/booking-bay/:id",
    element: <BookingBayDetail />,
  },
];

export default SimulatorBayRouters;
