import { InputAdornment, TextField, Tooltip } from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import { IField } from "./Field";
import { Info } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers";

const DateField: React.FC<IField> = ({
  form,
  name,
  label,
  disabled,
  inputOptions,
  error,
  errorMessage,
  defaultValue,
  tooltip,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileDatePicker
        label={inputOptions?.required ? label + " *" : label}
        inputFormat="YYYY-MM-DD"
        value={form.watch(name) || defaultValue}
        onChange={(newValue: Dayjs | null) =>
          form.setValue(name, newValue?.format("YYYY-MM-DD"))
        }
        disabled={disabled}
        minDate={inputOptions?.minDate}
        maxDate={inputOptions?.maxDate}
        renderInput={(params) => (
          <TextField
            fullWidth
            {...params}
            error={error}
            helperText={errorMessage}
            value={form.watch(name) || defaultValue}
            {...form.register(name, { ...inputOptions })}
            InputProps={
              tooltip
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title={tooltip}>
                          <Info sx={{ cursor: "pointer" }} />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }
                : {}
            }
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DateField;
