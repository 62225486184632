import Header from "components/common/Header/Header";
import withAuth from "hocs/withAuth";

const Dashboard = () => {
  return (
    <>
      <Header
        title="Dashboard"
        breadcrumbs={[
          {
            label: "Dashboard",
          },
        ]}
      />
      <div>Welcome</div>
    </>
  );
};

export default withAuth(Dashboard);
