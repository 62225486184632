import {
  Box,
  Button,
  Divider,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CCKBayServices from "services/cckBay";
import { Close } from "@mui/icons-material";
import FieldList from "components/common/FieldList";
import Header from "components/common/Header";
import { ICCKBay } from "interfaces/cckBay";
import Swal from "sweetalert2";
import Table from "components/common/Table/Table";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

const BayDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useToken();
  const { onAlert } = useAlert();

  const [bay, setBay] = useState<ICCKBay | null>(null);

  useEffect(() => {
    fetchData();
  }, [id, onAlert, token]);

  const fetchData = () => {
    CCKBayServices.getDetail(token as string, id as string)
      .then((res) => {
        setBay(res.data.data);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
      });
  };

  const onCancelCloseDate = (id: number) => {
    Swal.fire({
      icon: "warning",
      html: `Confirm to delete this bay?`,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        CCKBayServices.cancelCloseDate(token as string, bay?.id as number, id)
          .then((res) => {
            fetchData();
            onAlert("success", "Delete close bay date success");
          })
          .catch((err) => {
            onAlert("error", err.response.data.message);
          });
      }
    });
  };

  return (
    <>
      <Header
        title="Bay Details"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: `/dashboard`,
          },
          {
            label: "Simulator Bay",
            href: `/simulator-bay/dashboard`,
          },
          {
            label: "Bay",
            href: `/simulator-bay/bay`,
          },
          {
            label: bay ? bay.name : "",
          },
        ]}
      />
      {bay ? (
        <>
          <Box
            sx={{
              mb: 4,
            }}
          >
            <FieldList
              type="detail"
              fields={[
                {
                  label: "Bay number",
                  type: "text",
                  name: "bay_number",
                  defaultValue: bay?.bay_number,
                  col: 6,
                },
                {
                  label: "Bay name",
                  type: "text",
                  name: "namr",
                  defaultValue: bay?.name,
                  col: 6,
                },
              ]}
              onBack={() => navigate(`/simulator-bay/bay`)}
            />
          </Box>
          <Box>
            <Header
              title="Bay closed date"
              breadcrumbs={[]}
              actions={[
                <ButtonAddClosedDate id={bay.id} fetchData={fetchData} />,
              ]}
            />
            <Table
              headers={[
                {
                  key: "closed_date",
                  label: "Closed date",
                  render: (value, index, item) => (
                    <>{dayjs(value).add(543, "year").format("DD/MM/YYYY")}</>
                  ),
                },
                {
                  key: "created_at",
                  label: "Created at",
                  render: (value, index, item) => (
                    <>{dayjs(value).add(543, "year").format("DD/MM/YYYY")}</>
                  ),
                },
              ]}
              data={bay.bay_closes}
              actions={{
                others: [
                  {
                    icon: () => <Close />,
                    label: () => "Cancel",
                    onClick: (item) => onCancelCloseDate(item.id),
                  },
                ],
              }}
            />
          </Box>
        </>
      ) : (
        <Typography sx={{ textAlign: "center" }}>Loading...</Typography>
      )}
    </>
  );
};

const ButtonAddClosedDate = ({
  id,
  fetchData,
}: {
  id: number;
  fetchData: () => void;
}) => {
  const [closedDate, setClosedDate] = useState<string>(
    dayjs(new Date()).format("YYYY-MM-DD")
  );
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!isOpen) {
      setClosedDate(dayjs(new Date()).format("YYYY-MM-DD"));
    }
  }, [isOpen]);

  const onSubmit = () => {
    Swal.fire({
      icon: "warning",
      html: `Confirm add close bay date?`,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {
          close_date: closedDate,
        };
        CCKBayServices.addCloseDate(token as string, id, data)
          .then((res) => {
            setIsOpen(false);
            fetchData();
            onAlert("success", "Add close bay date success");
          })
          .catch((err) => {
            onAlert("error", err.response.data.message);
          });
      }
    });
  };

  return (
    <>
      <Button
        variant="contained"
        color="success"
        onClick={() => setIsOpen(true)}
      >
        Add Close Date
      </Button>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            width: "350px",
            display: "inline",
            mx: "auto",
            py: 2,
            px: 4,
            borderRadius: "12px",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              mb: 2,
            }}
          >
            Add close date
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              label={"Closed date *"}
              inputFormat="YYYY-MM-DD"
              value={closedDate}
              onChange={(newValue: Dayjs | null) =>
                setClosedDate(newValue?.format("YYYY-MM-DD") as string)
              }
              minDate={new Date() as any}
              renderInput={(params) => (
                <TextField fullWidth {...params} value={closedDate} />
              )}
            />
          </LocalizationProvider>

          <Divider sx={{ my: 2 }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              type="button"
              variant="contained"
              onClick={() => setIsOpen(false)}
              color="error"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={onSubmit}
              disabled={!closedDate}
            >
              Add
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default withAuth(withPermission(BayDetail, "MANAGE_SIMULATOR_BAY"));
