import { IPagination, IPaginationRequest } from "interfaces/pagination";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import AvatarLetter from "components/common/AvatarLetter";
import { Button } from "@mui/material";
import Header from "components/common/Header";
import Swal from "sweetalert2";
import Table from "components/common/Table";
import Typography from "@mui/material/Typography";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import CobraContactServices from "services/cobraContact";
import { ICobraContact } from "interfaces/cobraContact";

interface IContactData {
  id: number;
  label: string;
  value: string;
  image: string;
  contact_type_name: string;
  contact_type_image: string;
  last_edited_at: string;
  created_by: string;
  edited_by: string;
}

const ListContact = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [data, setData] = useState<{
    data: IContactData[];
    pagination: IPagination;
  }>({
    data: [],
    pagination: {
      page: 1,
      total_item: 0,
      total_page: 0,
    },
  });
  const [pagination, setPagination] = useState<IPaginationRequest>({
    page: 1,
    per_page: 10,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchData = useCallback(() => {
    CobraContactServices.getAll(token as string, {
      per_page: pagination.per_page,
      page: pagination.page,
    })
      .then((res) => {
        let paginationRes: IPagination = res.data.pagination;
        setData({
          data: res.data.data.map((d: ICobraContact) => {
            let contactdata: IContactData = {
              id: d.id,
              label: d.label,
              value: d.value,
              image: d.image || d.contact_type.image,
              contact_type_name: d.contact_type.name,
              contact_type_image: d.contact_type.image,
              last_edited_at: d.last_edited_at,
              created_by: d.admin_created.name,
              edited_by: d.admin_edited.name,
            };
            return contactdata;
          }),
          pagination: {
            page: paginationRes.page,
            total_item: paginationRes.total_item,
            total_page: paginationRes.total_page,
          },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
        setIsLoading(false);
      });
  }, [pagination, onAlert, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onDelete = (item: ICobraContact) => {
    Swal.fire({
      icon: "warning",
      html: `ต้องการลบรางวัล <b>${item.label}</b> ใช่ หรือไม่?`,
      showCancelButton: true,
      confirmButtonText: "ลบ",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        CobraContactServices.delete(token as string, item.id)
          .then((res) => {
            fetchData();
            onAlert("success", "ลบ Contact นี้สำเร็จ");
            setIsLoading(true);
          })
          .catch((err) => {
            onAlert("error", err.response.data.message);
            setIsLoading(true);
          });
      }
    });
  };

  return (
    <>
      <Header
        title="Contact"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: "/dashboard",
          },
          {
            label: "Contact",
            href: "/contact/cobra/list",
          },
          {
            label: "List",
          },
        ]}
        actions={[
          <Link to={`/contact/cobra/create`}>
            <Button variant="contained" size="large">
              <AddIcon
                sx={{
                  fontSize: 14,
                  mr: 1,
                }}
              />
              <Typography>Create</Typography>
            </Button>
          </Link>,
        ]}
      />
      <Table
        headers={[
          {
            key: "image",
            label: "Image",
            render: (value: string, index: number, item: IContactData) => (
              <img
                style={{
                  borderRadius: "8px",
                  width: "50px",
                  height: "50px",
                  maxHeight: "80px",
                  objectFit: "cover",
                }}
                src={process.env.REACT_APP_FILE + value}
                alt={item.label}
              />
            ),
          },
          {
            key: "label",
            label: "Label",
          },
          {
            key: "contact_type_image",
            label: "Action Type",
            render: (value: string, index: number, item: IContactData) => (
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    borderRadius: "8px",
                    width: "50px",
                    height: "50px",
                    maxHeight: "80px",
                    objectFit: "cover",
                    marginRight: "5px",
                  }}
                  src={process.env.REACT_APP_FILE + value}
                  alt={item.label}
                />
                <span>{item.contact_type_name}</span>
              </Typography>
            ),
          },
          {
            key: "value",
            label: "Action to",
          },
          {
            key: "created_by",
            label: "Created By",
            render: (value) => <AvatarLetter label={value} tooltip />,
          },
          {
            key: "edited_by",
            label: "Edited By",
            render: (value) => <AvatarLetter label={value} tooltip />,
          },
          {
            key: "last_edited_at",
            label: "Last Edited At",
            render: (value) => (
              <Typography>{new Date(value).toLocaleString("th-TH")}</Typography>
            ),
          },
        ]}
        actions={{
          onDetail: (item: any) => navigate(`/contact/cobra/${item.id}`),
          onEdit: (item: any) => navigate(`/contact/cobra/${item.id}/edit`),
          onDelete: (item: any) => onDelete(item),
        }}
        data={data.data}
        isLoading={isLoading}
        pagination={
          data.pagination
            ? {
                ...data.pagination,
                onChange: (page) =>
                  setPagination((pagination) => {
                    return {
                      ...pagination,
                      page,
                    };
                  }),
              }
            : undefined
        }
      />
    </>
  );
};

export default withAuth(withPermission(ListContact, "MANAGE_COBRA"));
