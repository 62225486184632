import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import FieldList from "components/common/FieldList";
import Header from "components/common/Header";
import { IPromotion } from "interfaces/promotion";
import PromotionServices from "services/promotion";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

const PromotionDetail = () => {
  let line_oa_code = "ONLY_GOLF";
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useToken();
  const { onAlert } = useAlert();

  const [promotion, setPromotion] = useState<IPromotion | null>(null);

  useEffect(() => {
    const fetchData = () => {
      PromotionServices.getDetail(
        token as string,
        line_oa_code as string,
        id as string
      )
        .then((res) => {
          setPromotion(res.data.data);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    };

    fetchData();
  }, [id, token, line_oa_code, onAlert]);

  if (promotion) {
    return (
      <>
        <Header
          title="Promotion Details"
          breadcrumbs={[
            {
              label: "Dashboard",
              href: `/dashboard`,
            },
            {
              label: "Only Golf",
              href: `/dashboard/only-golf`,
            },
            {
              label: "Promotion",
              href: `/promotion/only-golf/list`,
            },
            {
              label: promotion.name,
            },
          ]}
        />
        <FieldList
          type="detail"
          fields={[
            {
              label: "Cover Image",
              type: "image",
              name: "image",
              defaultValue: promotion.image,
              col: 6,
            },
            {
              label: "",
              type: "box",
              name: "",
              col: 6,
            },
            {
              label: "Name",
              type: "text",
              name: "name",
              defaultValue: promotion.name,
              col: 6,
            },
            {
              label: "Price",
              type: "number",
              name: "price",
              defaultValue: promotion.price,
              col: 6,
            },
            {
              label: "Description",
              type: "textarea",
              name: "description",
              defaultValue: promotion.description,
              col: 6,
            },
            {
              label: "URL",
              type: "text",
              name: "url",
              defaultValue: promotion.url,
              col: 6,
            },
          ]}
          onBack={() => navigate(`/promotion/only-golf/list`)}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default withAuth(withPermission(PromotionDetail, "MANAGE_ONLY_GOLF"));
