import axios from "axios";

const CCKTimeSlotService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/cck-time-slot`,
});

const CCKTimeSlotServices = {
  getAll: (token: string) =>
    CCKTimeSlotService.get(`/all`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getByDataAndBayID: (token: string, date: string, bay_id: number) =>
    CCKTimeSlotService.get(`/list?date=${date}&bay_id=${bay_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getByDataAndBayIDAndProID: (token: string, date: string, bay_id: number, pro_id: number) =>
    CCKTimeSlotService.get(`/training/list?date=${date}&bay_id=${bay_id}&pro_id=${pro_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default CCKTimeSlotServices;
