import { AccountCircle, Add, KeyboardReturn } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import CCKDaySlotServices from "services/cckDaySlot";
import CCKProfessionalServices from "services/cckProfessional";
import CCKTimeSlotServices from "services/cckTimeSlot";
import { Editor } from "react-draft-wysiwyg";
import FileServices from "services/file";
import Header from "components/common/Header";
import { ICCKDaySlot } from "interfaces/cckDaySlot";
import { ICCKProfessional } from "interfaces/cckProfessional";
import { ICCKTimeSlot } from "interfaces/cckBooking";
import Swal from "sweetalert2";
import draftToHtml from "draftjs-to-html";
import useAlert from "hooks/useAlert";
import { useForm } from "react-hook-form";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

type TEditProfessional = {
  profile_url: File | null;
  first_name: string;
  last_name: string;
  tel: string;
  email: string;
  age: string;
  type: string;
  experience: string;
  experience_clone: string;
  available_time: string;
  available_time_clone: string;
  price: string;
};

const EditProfessional = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [content, setContent] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const [availableTimecontent, setAvailableTimeContent] = useState<EditorState>(
    EditorState.createEmpty()
  );

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<TEditProfessional>();

  const [isDeleteProfile, setIsDeleteProfile] = useState<boolean>(false);
  const [professional, setProfessional] = useState<ICCKProfessional | null>(
    null
  );
  // const [freeSlots, setFreeSlots] = useState<
  //   {
  //     day: ICCKDaySlot;
  //     times: {
  //       time: ICCKTimeSlot;
  //       checked: boolean;
  //     }[];
  //   }[]
  // >([]);

  const onEnterComment = (editorState: EditorState) => {
    setContent(editorState);
  };

  const onEnterAvailableTime = (editorState: EditorState) => {
    setAvailableTimeContent(editorState);
  };

  useEffect(() => {
    if (professional) {
      let htmlText = draftToHtml(convertToRaw(content.getCurrentContent()));
      let text = htmlText.replace(/(<([^>]+)>)/gi, "").replace(/[\r\n]/gm, "");
      if (!250 || (250 && text.length <= 250)) {
        setValue("experience_clone", htmlText);
        setValue("experience", text);
      }
    }
  }, [content]);

  useEffect(() => {
    clearErrors("experience");
  }, [content]);

  useEffect(() => {
    if (professional) {
      let htmlText = draftToHtml(convertToRaw(availableTimecontent.getCurrentContent()));
      let text = htmlText.replace(/(<([^>]+)>)/gi, "").replace(/[\r\n]/gm, "");
      if (!250 || (250 && text.length <= 250)) {
        setValue("available_time_clone", htmlText);
        setValue("available_time", text);
      }
    }
  }, [availableTimecontent]);

  useEffect(() => {
    clearErrors("available_time");
  }, [availableTimecontent]);

  useEffect(() => {
    const fetchData = () => {
      CCKProfessionalServices.getDetail(token as string, id as string)
        .then((res) => {
          let professionalData: ICCKProfessional = res.data.data;
          setProfessional(res.data.data);
          setContent(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(professionalData?.experience || "")
                  .contentBlocks
              )
            )
          );
          setAvailableTimeContent(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(professionalData?.available_time || "")
                  .contentBlocks
              )
            )
          );
          // CCKDaySlotServices.getAll(token as string)
          //   .then((res) => {
          //     let daySlots: ICCKDaySlot[] = res.data.data;
          //     CCKTimeSlotServices.getAll(token as string)
          //       .then((resTime) => {
          //         let timeSlots: ICCKTimeSlot[] = resTime.data.data;
          //         let freeTimeSlotData: {
          //           time: ICCKTimeSlot;
          //           checked: boolean;
          //         }[] = timeSlots.map((timeSlot) => {
          //           return {
          //             time: timeSlot,
          //             checked: false,
          //           };
          //         });
          //         let freeSlotsData: {
          //           day: ICCKDaySlot;
          //           times: {
          //             time: ICCKTimeSlot;
          //             checked: boolean;
          //           }[];
          //         }[] = daySlots.map((daySlot) => {
          //           let dayMatch: {
          //             day: ICCKDaySlot;
          //             times: {
          //               time: ICCKTimeSlot;
          //               checked: boolean;
          //             }[];
          //           } | null = null;
          //           professionalData.free_slots.forEach((freeSlot) => {
          //             if (daySlot.id === freeSlot.day.id) {
          //               dayMatch = freeSlot;
          //             }
          //           });
          //           let freeTimeSlotDataClone: {
          //             time: ICCKTimeSlot;
          //             checked: boolean;
          //           }[] = JSON.parse(JSON.stringify(freeTimeSlotData));
          //           if (dayMatch !== null) {
          //             freeTimeSlotDataClone.forEach((freeTimeSlot) => {
          //               dayMatch?.times.forEach((time) => {
          //                 if (freeTimeSlot.time.id === time.time.id) {
          //                   freeTimeSlot.checked = true;
          //                 }
          //               });
          //             });
          //           }
          //           return {
          //             day: daySlot,
          //             times: freeTimeSlotDataClone,
          //           };
          //         });
          //         setFreeSlots(freeSlotsData);
          //       })
          //       .catch(() => {
          //         onAlert("error", "Something went wrong");
          //       });
          //   })
          //   .catch(() => {
          //     onAlert("error", "Something went wrong");
          //   });
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    };

    fetchData();
  }, [id, onAlert, token]);

  const onConfirm = (data: TEditProfessional) => {
    Swal.fire({
      icon: "warning",
      html: `ต้องการแก้ไขใช่ หรือไม่?`,
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        onSubmit(data);
      }
    });
  };

  const onSubmit = async (data: TEditProfessional) => {
    let dataProps: {
      profile_url: string | null;
      first_name: string;
      last_name: string;
      tel: string;
      email: string;
      age: number;
      type: string;
      experience: string;
      available_time: string;
      price: string;
    } = {
      ...data,
      profile_url: null,
      age: parseInt(data.age),
      experience: data.experience_clone,
      available_time: data.available_time_clone,
    };
    let isError = false;
    // console.log(data.profile_url,isDeleteProfile);
    if (data.profile_url) {
      let formData = new FormData();
      formData.append("file", data.profile_url);
      formData.append("prefix", "professional");
      formData.append("path", "/professional");
      await FileServices.create(token as string, formData)
        .then((res) => {
          dataProps.profile_url = res.data.data;
        })
        .catch((err) => {
          isError = true;
          onAlert("error", err.response.data.message);
        });
    } else {
      if (isDeleteProfile) {
        dataProps.profile_url = null;
      } else {
        dataProps.profile_url = professional?.profile_url || ""
      }
    }
    if (!isError) {
      CCKProfessionalServices.edit(token as string, id as string, {
        ...dataProps,
        // free_slots: freeSlots.map((freeSlot) => {
        //   return {
        //     ...freeSlot,
        //     times: freeSlot.times.filter((time) => time.checked),
        //   };
        // }),
      })
        .then((res) => {
          onAlert("success", "Edit professional success");
          navigate(`/simulator-bay/professional`);
        })
        .catch((err) => {
          onAlert("error", "Please try again later");
          navigate(`/simulator-bay/professional`);
        });
    }
  };

  if (professional) {
    return (
      <>
        <Header
          title="Edit professional"
          breadcrumbs={[
            {
              label: "Dashboard",
              href: `/dashboard`,
            },
            {
              label: "Simulator Bay",
              href: `/simulator-bay/dashboard`,
            },
            {
              label: "Professional",
              href: "/simulator-bay/professional",
            },
            {
              label: `${professional.first_name} ${professional.last_name}`,
            },
          ]}
        />
        <Paper>
          <form onSubmit={handleSubmit(onConfirm)}>
            <Box
              sx={{
                p: 2,
              }}
            >
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "150px",
                      height: "150px",
                      borderRadius: "75px",
                      backgroundColor: "#129747",
                    }}
                  >
                    {isDeleteProfile ? (
                      <AccountCircle
                        style={{
                          color: "white",
                        }}
                        sx={{
                          width: "170px",
                          height: "170px",
                        }}
                      />
                    ) : (
                      <>
                        {watch("profile_url") ? (
                          <Avatar
                            alt="Profile picture"
                            src={window.URL.createObjectURL(
                              watch("profile_url") as File
                            )}
                            sx={{
                              width: "140px",
                              height: "140px",
                            }}
                          />
                        ) : (
                          <>
                            {professional.profile_url ? (
                              <Avatar
                                alt="Profile picture"
                                src={
                                  process.env.REACT_APP_FILE +
                                  professional.profile_url
                                }
                                sx={{
                                  width: "140px",
                                  height: "140px",
                                }}
                              />
                            ) : (
                              <AccountCircle
                                style={{
                                  color: "white",
                                }}
                                sx={{
                                  width: "170px",
                                  height: "170px",
                                }}
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Box>
                  <Box
                    sx={{
                      paddingLeft: "30px",
                    }}
                  >
                    <div>
                      <Button
                        fullWidth
                        variant="contained"
                        component="label"
                        color="success"
                        sx={{
                          mb: 1,
                        }}
                      >
                        Upload picture
                        <input
                          type="file"
                          hidden
                          onChange={(e) => {
                            setValue(
                              "profile_url",
                              e.target.files ? e.target.files[0] : null
                            );
                            e.target.files = null;
                            setIsDeleteProfile(false);
                          }}
                        />
                      </Button>
                    </div>
                    <div>
                      <Button
                        fullWidth
                        variant="outlined"
                        color="success"
                        onClick={() => {
                          setValue("profile_url", null);
                          setIsDeleteProfile(true);
                        }}
                        sx={{
                          mb: 1,
                        }}
                      >
                        Delete picture
                      </Button>
                    </div>
                    <div>
                      <Button
                        fullWidth
                        variant="text"
                        color="success"
                        onClick={() => {
                          setValue("profile_url", null);
                          setIsDeleteProfile(false);
                        }}
                      >
                        Use old picture
                      </Button>
                    </div>
                  </Box>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="First name *"
                      variant="outlined"
                      fullWidth
                      {...register("first_name", {
                        required: "Please enter first name",
                      })}
                      defaultValue={professional.first_name}
                      error={!!errors.first_name?.message}
                      helperText={errors.first_name?.message}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Last name *"
                      variant="outlined"
                      fullWidth
                      {...register("last_name", {
                        required: "Please enter last name",
                      })}
                      defaultValue={professional.last_name}
                      error={!!errors.last_name?.message}
                      helperText={errors.last_name?.message}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Phone number *"
                      type="numeric"
                      fullWidth
                      inputMode="numeric"
                      variant="outlined"
                      {...register("tel", {
                        required: "Please enter phone number for send sms alert",
                      })}
                      defaultValue={professional.tel}
                      error={!!errors.tel?.message}
                      helperText={errors.tel?.message}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Email"
                      variant="outlined"
                      fullWidth
                      {...register("email")}
                      defaultValue={professional.email}
                      error={!!errors.email?.message}
                      helperText={errors.email?.message}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Age"
                      variant="outlined"
                      type="number"
                      fullWidth
                      {...register("age")}
                      defaultValue={professional.age}
                      error={!!errors.age?.message}
                      helperText={errors.age?.message}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Type *"
                      variant="outlined"
                      fullWidth
                      {...register("type", {
                        required: "Please enter type of professional",
                      })}
                      defaultValue={professional.type}
                      error={!!errors.type?.message}
                      helperText={errors.type?.message}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth error={!!errors.experience?.message}>
                      <Editor
                        toolbarHidden
                        stripPastedStyles
                        editorState={content}
                        onEditorStateChange={onEnterComment}
                        editorClassName={`text-editor-css ${
                          !!errors.experience?.message ? "error" : ""
                        }`}
                      />
                      <input {...register("experience")} hidden />
                      <FormHelperText
                        sx={{
                          position: "absolute",
                          fontSize: 12,
                          left: 9,
                          top: -12,
                          px: 0.5,
                          bgcolor: "white",
                          mx: 0,
                        }}
                      >
                        Experience
                      </FormHelperText>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <FormHelperText>
                          {errors.experience?.message}
                        </FormHelperText>
                        <Typography color={"#757575"}>
                          <>
                            {(watch("experience") &&
                              watch("experience")
                                .replace(/(<([^>]+)>)/gi, "")
                                .replace(/[\r\n]/gm, "").length) ||
                              0}
                            /250
                          </>
                        </Typography>
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth error={!!errors.available_time?.message}>
                      <Editor
                        toolbarHidden
                        stripPastedStyles
                        editorState={availableTimecontent}
                        onEditorStateChange={onEnterAvailableTime}
                        editorClassName={`text-editor-css ${
                          !!errors.available_time?.message ? "error" : ""
                        }`}
                      />
                      <input {...register("available_time")} hidden />
                      <FormHelperText
                        sx={{
                          position: "absolute",
                          fontSize: 12,
                          left: 9,
                          top: -12,
                          px: 0.5,
                          bgcolor: "white",
                          mx: 0,
                        }}
                      >
                        Available Time
                      </FormHelperText>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <FormHelperText>
                          {errors.available_time?.message}
                        </FormHelperText>
                        <Typography color={"#757575"}>
                          <>
                            {(watch("available_time") &&
                              watch("available_time")
                                .replace(/(<([^>]+)>)/gi, "")
                                .replace(/[\r\n]/gm, "").length) ||
                              0}
                            /250
                          </>
                        </Typography>
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Price *"
                      variant="outlined"
                      fullWidth
                      {...register("price", {
                        required: "Please enter price",
                      })}
                      defaultValue={professional.price}
                      error={!!errors.price?.message}
                      helperText={errors.price?.message}
                    />
                  </Grid>
                </Grid>
              </>
            </Box>
            {/* {freeSlots.length > 0 ? (
              <TableContainer>
                <Table
                  sx={{ minWidth: 650, height: "1px" }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          backgroundColor: "#F4F6F8",
                          width: "50px",
                          borderRadius: "8px 0px 0px 8px",
                        }}
                      >
                        Time slot
                      </TableCell>
                      {freeSlots[0].times.map((timeSlot, index) => {
                        return (
                          <TableCell
                            sx={{
                              backgroundColor: "#F4F6F8",
                              px: "8px",
                              borderRadius:
                                index === freeSlots[0].times.length - 1
                                  ? "0px 8px 8px 0px"
                                  : "",
                            }}
                            align="center"
                          >
                            {timeSlot.time.time_start.substring(0, 5)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {freeSlots.map((freeSlot, index) => (
                      <TableRow key={`row-${index}`}>
                        <TableCell component="th" scope="row" sx={{}}>
                          <span>{freeSlot.day.name}</span>
                        </TableCell>
                        {freeSlot.times.map(
                          (freeDayTimeSlot, indexFreeDayTimeSlot) => {
                            return (
                              <TableCell
                                key={`column-${indexFreeDayTimeSlot}`}
                                component="th"
                              >
                                <Checkbox
                                  checked={freeDayTimeSlot.checked}
                                  onChange={(e) => {
                                    setFreeSlots((freeSlots) => {
                                      let freeSlotsData = [...freeSlots];
                                      let times = [
                                        ...freeSlotsData[index].times,
                                      ];
                                      times[indexFreeDayTimeSlot] = {
                                        ...times[indexFreeDayTimeSlot],
                                        checked: e.target.checked,
                                      };
                                      freeSlotsData[index] = {
                                        ...freeSlots[index],
                                        times: times,
                                      };
                                      return freeSlotsData;
                                    });
                                  }}
                                />
                              </TableCell>
                            );
                          }
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null} */}
            <Divider />
            <Box
              sx={{
                p: 2,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Link to="/simulator-bay/professional">
                <Button
                  type="button"
                  variant="contained"
                  size="large"
                  color="error"
                >
                  <KeyboardReturn
                    sx={{
                      fontSize: 16,
                      mr: 1,
                    }}
                  />
                  <Typography>กลับ</Typography>
                </Button>
              </Link>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="success"
              >
                <Add
                  sx={{
                    fontSize: 16,
                    mr: 1,
                  }}
                />
                <Typography>สร้าง</Typography>
              </Button>
            </Box>
          </form>
        </Paper>
      </>
    );
  } else {
    return <></>;
  }
};

export default withAuth(
  withPermission(EditProfessional, "MANAGE_SIMULATOR_BAY")
);
