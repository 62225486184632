import { IPagination, IPaginationRequest } from "interfaces/pagination";
import { useEffect, useState } from "react";

import AvatarLetter from "components/common/AvatarLetter";
import CCKMemberLogServices from "services/cckMemberLog";
import Header from "components/common/Header";
import { ICCKMemberLog } from "interfaces/cckMemberLog";
import Table from "components/common/Table";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

interface ICCKMemberLogData {
  id: number;
  name: string;
  column: string;
  from: string;
  to: string;
  edited_by: string;
  edited_at: string;
}

const ListMemberLog = () => {
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [data, setData] = useState<{
    data: ICCKMemberLogData[];
    pagination: IPagination;
  }>({
    data: [],
    pagination: {
      page: 1,
      total_item: 0,
      total_page: 0,
    },
  });
  const [search, setSearch] = useState<string>("");
  const [filter, setFilter] = useState<{
    date: {
      start: Date | null;
      end: Date | null;
    };
  }>({ date: { start: null, end: null } });
  const [pagination, setPagination] = useState<IPaginationRequest>({
    page: 1,
    per_page: 10,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = () => {
      CCKMemberLogServices.getAll(
        token as string,
        {
          per_page: pagination.per_page,
          page: pagination.page,
        },
        search,
        {
          start: filter.date.start
            ? dayjs(filter.date.start.toString()).format("YYYY-MM-DD")
            : "",
          end: filter.date.end
            ? dayjs(filter.date.end.toString()).format("YYYY-MM-DD")
            : "",
        }
      )
        .then((res) => {
          let paginationRes: IPagination = res.data.pagination;
          setData({
            data: res.data.data.map((d: ICCKMemberLog) => {
              return {
                id: d.id,
                name: `${d.member.first_name} ${d.member.last_name}`,
                column: d.column,
                from: d.from_value,
                to: d.to_value,
                edited_by: d.admin.name,
                edited_at: d.edited_at,
              } as ICCKMemberLogData;
            }),
            pagination: {
              page: paginationRes.page,
              total_item: paginationRes.total_item,
              total_page: paginationRes.total_page,
            },
          });
          setIsLoading(false);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    };

    fetchData();
  }, [pagination, onAlert, token, search, filter]);

  return (
    <>
      <Header
        title="Member Log"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: `/dashboard`,
          },
          {
            label: "Simulator Bay",
            href: `/simulator-bay/dashboard`,
          },
          {
            label: "Member Log",
            href: "/simulator-bay/member-log",
          },
          {
            label: "List",
          },
        ]}
      />
      <Table
        headers={[
          {
            key: "name",
            label: "Member name",
          },
          {
            key: "column",
            label: "Column",
          },
          {
            key: "from",
            label: "From",
            render: (value) => <Typography color="red">{value}</Typography>,
          },
          {
            key: "to",
            label: "To",
            render: (value) => <Typography color="green">{value}</Typography>,
          },
          {
            key: "edited_by",
            label: "Edited By",
            render: (value) => <AvatarLetter label={value} tooltip />,
          },
          {
            key: "edited_at",
            label: "Edited At",
            render: (value) => (
              <Typography>{new Date(value).toLocaleString("th-TH")}</Typography>
            ),
          },
        ]}
        data={data.data}
        isLoading={isLoading}
        pagination={
          data.pagination
            ? {
                ...data.pagination,
                onChange: (page) =>
                  setPagination((pagination) => {
                    return {
                      ...pagination,
                      page,
                    };
                  }),
              }
            : undefined
        }
        onSearch={(searchText) => setSearch(searchText)}
        filters={[
          {
            type: "daterange",
            name: "Created At",
            value: filter.date,
            onChange: (value: any) =>
              setFilter((filter) => {
                return { ...filter, date: value };
              }),
          },
        ]}
      />
    </>
  );
};

export default withAuth(withPermission(ListMemberLog, "MANAGE_SIMULATOR_BAY"));
