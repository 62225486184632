import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import FieldList from "components/common/FieldList";
import Header from "components/common/Header";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import { IBooking } from "interfaces/booking";
import { IService } from "interfaces/service";
import CobraBookingServices from "services/cobraBooking";
import CobraServiceServices from "services/cobraService";

type TEditBooking = {
  first_name: string;
  last_name: string;
  tel: string;
  email: string;
  services: boolean[];
};

const EditService = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useToken();
  const { onAlert } = useAlert();

  const [booking, setBooking] = useState<IBooking | null>(null);
  const [services, setServices] = useState<
    {
      label: any;
      value: any;
    }[]
  >([]);
  useEffect(() => {
    const fetchData = () => {
      let servicesData: {
        label: any;
        value: any;
        checked: boolean;
      }[] = [];
      CobraServiceServices.getAll(token as string, {
        page: 1,
        per_page: 100,
      })
        .then((res) => {
          servicesData = res.data.data.map(
            (service: IService, index: number) => {
              return {
                label: `${service.name} (${service.duration} min)`,
                value: service.id,
              };
            }
          );
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });

      CobraBookingServices.getDetail(
        token as string,
        id as string
      )
        .then((res) => {
          let bookingData: IBooking = res.data.data;
          setServices(
            servicesData.map((service, index) => {
              let isChecked = false;
              bookingData.services.forEach((booking_service: IService) => {
                if (booking_service.id === service.value) {
                  isChecked = true;
                }
              });
              return { ...service, checked: isChecked };
            })
          );
          setBooking(res.data.data);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    };

    fetchData();
  }, [id, token, onAlert]);

  const onSubmit = (data: TEditBooking) => {
    let dataProps: TEditBooking = data;
    let servicesSelected = dataProps.services.map(
      (service: boolean, index: number) => {
        if (service) {
          return services[index].value;
        }
      }
    );
    servicesSelected = servicesSelected.filter(
      (serviceSelected) => serviceSelected !== undefined
    );
    CobraBookingServices.edit(
      token as string,
      id as string,
      {
        ...dataProps,
        email: dataProps.email || undefined,
        services: servicesSelected,
      }
    )
      .then((res) => {
        onAlert("success", "แก้ไขการจองสำเร็จ");
        navigate(`/booking/cobra/list`);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
      });
  };

  if (booking) {
    return (
      <>
        <Header
          title="Edit Booking"
          breadcrumbs={[
            {
              label: "Dashboard",
              href: `/dashboard`,
            },
            {
              label: "Cobra",
              href: `/calendar/cobra`,
            },
            {
              label: "Booking",
              href: `/booking/cobra/list`,
            },
            {
              label: booking.code,
            },
          ]}
        />
        <FieldList
          type="edit"
          onSubmit={onSubmit}
          fields={[
            {
              label: "First Name",
              type: "text",
              name: "first_name",
              inputOptions: {
                required: "กรุณากรอกชื่อ",
              },
              col: 6,
              tooltip: "สำหรับแสดงชื่อผู้จอง",
              defaultValue: booking.first_name,
            },
            {
              label: "Last Name",
              type: "text",
              name: "last_name",
              inputOptions: {
                required: "กรุณากรอกนามสกุล",
              },
              col: 6,
              tooltip: "สำหรับแสดงนามสกุลผู้จอง",
              defaultValue: booking.last_name,
            },
            {
              label: "Tel",
              type: "text",
              name: "tel",
              inputOptions: {
                required: "กรุณากรอกเบอร์โทรศัพท์",
              },
              col: 6,
              tooltip: "สำหรับแสดงเบอร์โทรศัพท์ผู้จอง",
              defaultValue: booking.tel,
            },
            {
              label: "Email",
              type: "text",
              name: "email",
              col: 6,
              tooltip: "สำหรับแสดงอีเมลผู้จอง",
              defaultValue: booking.email,
            },
            {
              label: "Booking Date",
              type: "date",
              name: "booking_date",
              defaultValue: booking.booking_date,
              col: 6,
              disabled: true,
            },
            {
              label: "Start Slot Time",
              type: "text",
              name: "start_slot_time",
              defaultValue: `${booking.booking_start_time.substring(0, 5)} น.`,
              col: 6,
              disabled: true,
            },
            {
              label: "Services",
              type: "check-list",
              name: "services",
              inputOptions: {
                atLeastOne: "กรุณาเลือกอย่างน้อย 1 บริการ",
              },
              col: 12,
              items: services,
            },
          ]}
          onBack={() => navigate(`/booking/cobra/list`)}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default withAuth(withPermission(EditService, "MANAGE_COBRA"));
