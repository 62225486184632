import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import CCKBookingBayServices from "services/cckBookingBay";
import FieldList from "components/common/FieldList";
import Header from "components/common/Header";
import { ICCKBookingBay } from "interfaces/cckBookingBay";
import dayjs from "dayjs";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

const BookingBayDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useToken();
  const { onAlert } = useAlert();

  const [bookingBay, setBookingBay] = useState<ICCKBookingBay | null>(null);

  useEffect(() => {
    fetchData();
  }, [id, token]);

  const fetchData = () => {
    CCKBookingBayServices.getDetail(token as string, id as string)
      .then((res) => {
        setBookingBay(res.data.data);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
      });
  };

  return (
    <>
      <Header
        title="Booking Bay Details"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: `/dashboard`,
          },
          {
            label: "Simulator Bay",
            href: `/simulator-bay/dashboard`,
          },
          {
            label: "Booking",
            href: `/simulator-bay/booking`,
          },
          {
            label: bookingBay?.booking.code || "",
            href: `/simulator-bay/booking/${bookingBay?.booking.id}`,
          },
          {
            label: "Booking Bay",
          },
          {
            label: bookingBay ? bookingBay.code : "",
          },
        ]}
      />
      {bookingBay ? (
        <>
          <Box
            sx={{
              my: 4,
            }}
          >
            <FieldList
              type="detail"
              fields={[
                {
                  label: "First name",
                  type: "text",
                  name: "first_name",
                  defaultValue: bookingBay?.booking.first_name,
                  col: 6,
                },
                {
                  label: "Last name",
                  type: "text",
                  name: "last_name",
                  defaultValue: bookingBay?.booking.last_name,
                  col: 6,
                },
                {
                  label: "Phone number",
                  type: "text",
                  name: "tel",
                  defaultValue: bookingBay?.booking.tel,
                  col: 6,
                },
                {
                  label: "Email",
                  type: "text",
                  name: "email",
                  defaultValue: bookingBay?.booking.email,
                  col: 6,
                },
                {
                  label: "Bay number",
                  type: "text",
                  name: "email",
                  defaultValue: bookingBay?.bay.bay_number,
                  col: 6,
                },
                {
                  label: "Booking date",
                  type: "text",
                  name: "email",
                  defaultValue: dayjs(bookingBay?.booking.booking_date).format("DD/MM/YYYY"),
                  col: 6,
                },
                {
                  label: "Booking time",
                  type: "text",
                  name: "email",
                  defaultValue: `${bookingBay.booking_start_time.substring(0,5)} - ${bookingBay.booking_end_time.substring(0,5)}`,
                  col: 6,
                },
              ]}
              onBack={() => navigate(`/simulator-bay/booking/${bookingBay.booking.id}`)}
            />
          </Box>
        </>
      ) : (
        <Typography sx={{ textAlign: "center" }}>Loading...</Typography>
      )}
    </>
  );
};

export default withAuth(withPermission(BookingBayDetail, "MANAGE_SIMULATOR_BAY"));
