import { IPaginationRequest } from "interfaces/pagination";
import axios from "axios";

const cobraContactTypeService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/cobra/contact-type`,
});

const CobraContactTypeServices = {
  getAll: (token: string, pagination: IPaginationRequest) =>
    cobraContactTypeService.get(
      `/list?perPage=${pagination.per_page}&page=${pagination.page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ),
};

export default CobraContactTypeServices;
