import axios from "axios";

const LineOAService = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
});

const LineOAServices = {
  getDetail: (token: string, line_oa_code: string) =>
    LineOAService.get(`/${line_oa_code}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  edit: (
    token: string,
    line_oa_code: string,
    data: {
      is_used_point: boolean;
      spend_per_point: number;
      point_multiplier: number;
    }
  ) =>
    LineOAService.put(`/${line_oa_code}/edit`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default LineOAServices;
