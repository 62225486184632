import { useNavigate } from "react-router-dom";

import FieldList from "components/common/FieldList";
import Header from "components/common/Header";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import { useEffect } from "react";
import CobraProductServices, {
  TCreateProductData,
} from "services/cobraProduct";
import FileServices from "services/file";

type TCreateProduct = {
  name: string;
  category: string;
  year: string;
  season: string;
  image: FileList;
};

const CreateProduct = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { onAlert } = useAlert();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {};

  const onSubmit = async (values: TCreateProduct) => {
    let data: TCreateProductData = {
      name: values.name,
      category: values.category,
      year: values.year,
      season: values.season,
      image: "",
    };
    let isError = false;
    let formDataImage = new FormData();
    formDataImage.append("name", data.name);
    formDataImage.append("file", values.image[0]);
    formDataImage.append("prefix", "cobra-product");
    formDataImage.append("path", "/cobra-product");
    await FileServices.create(token as string, formDataImage)
      .then((res) => {
        data.image = res.data.data;
      })
      .catch((err) => {
        isError = true;
        onAlert("error", err.response.data.message);
      });
    if (!isError) {
      CobraProductServices.create(token as string, data)
        .then((res) => {
          onAlert("success", "สร้างสินค้าสำเร็จ");
          navigate(`/product/cobra/list`);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    }
  };

  return (
    <>
      <Header
        title="Create a new product"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: `/dashboard`,
          },
          {
            label: "Cobra",
            href: `/dashboard/cobra`,
          },
          {
            label: "Product",
            href: "/product/cobra/list",
          },
          {
            label: "New product",
          },
        ]}
      />
      <FieldList
        type="create"
        onSubmit={onSubmit}
        fields={[
          {
            label: "Product Category",
            type: "select",
            name: "category",
            inputOptions: {
              required: "กรุณาเลือกประเภทของ Product",
            },
            col: 6,
            items: [
              {
                label: "Driver",
                value: "Driver",
              },
              {
                label: "Fairways",
                value: "Fairways",
              },
              {
                label: "Irons",
                value: "Irons",
              },
              {
                label: "Hybrid",
                value: "Hybrid",
              },
            ],
            defaultValue: "",
            tooltip: "ตัวเลือกสำหรับกำหนดประเภทของ Product",
          },
          {
            label: "Model Name",
            type: "text",
            name: "name",
            inputOptions: {
              required: "กรุณากรอกชื่อ",
            },
            col: 6,
            tooltip: "สำหรับกำหนดชื่อแสดงผลของรายการโปรโมชั่น",
          },
          {
            label: "Year",
            type: "text",
            name: "year",
            inputOptions: {
              required: "กรุณากรอกปีของ Product",
            },
            col: 6,
            tooltip: "สำหรับกำหนดปีของ Product",
          },
          {
            label: "Season",
            type: "text",
            name: "season",
            inputOptions: {
              required: "กรุณากรอก Season",
            },
            col: 6,
            maxLength: 150,
            tooltip: "สำหรับกำหนด Season ของ Product",
          },
          {
            label: "Image",
            type: "image",
            name: "image",
            inputOptions: {
              required: "กรุณาใส่รูปภาพประกอบ",
            },
            col: 6,
            tooltip: "สำหรับกำหนดรูปภาพแสดงผลของสินค้่า",
          },
        ]}
        onBack={() => navigate(`/product/cobra/list`)}
      />
    </>
  );
};

export default withAuth(withPermission(CreateProduct, "MANAGE_COBRA"));
