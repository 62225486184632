import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { IPagination, IPaginationRequest } from "interfaces/pagination";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect, useState } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CCKMemberServices from "services/cckMember";
import CCKProfessionalServices from "services/cckProfessional";
import Header from "components/common/Header";
import { ICCKMember } from "interfaces/cckMember";
import { ICCKProfessional } from "interfaces/cckProfessional";
import Swal from "sweetalert2";
import Table from "components/common/Table";
import Typography from "@mui/material/Typography";
import { set } from "react-hook-form";
import useAlert from "hooks/useAlert";
import { useNavigate } from "react-router-dom";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

interface ICCKMemberData {
  id: number;
  name: string;
  tel: string;
  credits: number;
  member: ICCKMember;
  professional: string;
  training_credits: number;
  updated_at: string;
}

const ListMember = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [search, setSearch] = useState<string>("");
  const [data, setData] = useState<{
    data: ICCKMemberData[];
    pagination: IPagination;
  }>({
    data: [],
    pagination: {
      page: 1,
      total_item: 0,
      total_page: 0,
    },
  });
  const [pagination, setPagination] = useState<IPaginationRequest>({
    page: 1,
    per_page: 10,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchData = useCallback(() => {
    CCKMemberServices.getAll(
      token as string,
      {
        per_page: pagination.per_page,
        page: pagination.page,
      },
      search
    )
      .then((res) => {
        let paginationRes: IPagination = res.data.pagination;
        setData({
          data: res.data.data.map((d: ICCKMember) => {
            let value: ICCKMemberData = {
              id: d.id,
              name: `${d.first_name} ${d.last_name}`,
              tel: d.tel,
              credits: d.credits,
              professional: d.professional
                ? `${d.professional.first_name} ${d.professional.last_name}`
                : "-",
              training_credits: d.training_credits,
              member: d,
              updated_at: d.updated_at,
            };
            return value;
          }),
          pagination: {
            page: paginationRes.page,
            total_item: paginationRes.total_item,
            total_page: paginationRes.total_page,
          },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
        setIsLoading(false);
      });
  }, [pagination, onAlert, token, search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Header
        title="Member"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: `/dashboard`,
          },
          {
            label: "Simulator Bay",
            href: `/simulator-bay/dashboard`,
          },
          {
            label: "Member",
            href: "/simulator-bay/member",
          },
          {
            label: "List",
          },
        ]}
      />
      <Table
        headers={[
          {
            key: "name",
            label: "Member name",
          },
          {
            key: "tel",
            label: "Phone Number",
          },
          {
            key: "credits",
            label: "Credits",
            render: (value: string) => (
              <Typography>{value.toLocaleString()}</Typography>
            ),
          },
          {
            key: "name",
            label: "Add credits",
            render: (value, index, member) => {
              return <ButtonAddCredits value={member} fetchData={fetchData} />;
            },
          },
          {
            key: "professional",
            label: "Professional",
          },
          {
            key: "training_credits",
            label: "Training Credits",
            render: (value: string) => (
              <Typography>{value.toLocaleString()}</Typography>
            ),
          },
          {
            key: "name",
            label: "Add credits",
            render: (value, index, member) => {
              return (
                <ButtonAddTrainingCredits
                  member={member.member}
                  fetchData={fetchData}
                />
              );
            },
          },
        ]}
        actions={{
          onDetail: (item: any) => navigate(`/simulator-bay/member/${item.id}`),
          onEdit: (item: any) =>
            navigate(`/simulator-bay/member/${item.id}/edit`),
        }}
        onSearch={(searchText) => setSearch(searchText)}
        data={data.data}
        isLoading={isLoading}
        pagination={
          data.pagination
            ? {
                ...data.pagination,
                onChange: (page) =>
                  setPagination((pagination) => {
                    return {
                      ...pagination,
                      page,
                    };
                  }),
              }
            : undefined
        }
      />
    </>
  );
};

const ButtonAddCredits = ({
  value,
  fetchData,
}: {
  value: ICCKMemberData;
  fetchData: () => void;
}) => {
  const [credits, setCredits] = useState<number>(0);
  const [expiredAt, setExpiredAt] = useState<string>(
    dayjs(new Date()).format("YYYY-MM-DD")
  );
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!isOpen) {
      setCredits(0);
      setExpiredAt(
        dayjs(
          new Date().setTime(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)
        ).format("YYYY-MM-DD")
      );
    }
  }, [isOpen]);

  const onSubmit = () => {
    Swal.fire({
      icon: "warning",
      html: `Confirm add credits?`,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {
          credits: credits,
          expired_at: expiredAt,
        };
        CCKMemberServices.addCredits(token as string, value.id + "", data)
          .then((res) => {
            setIsOpen(false);
            fetchData();
            onAlert("success", "Add credits success");
          })
          .catch((err) => {
            onAlert("error", err.response.data.message);
          });
      }
    });
  };

  return (
    <>
      <Button
        variant="contained"
        color="success"
        onClick={() => setIsOpen(true)}
      >
        Add
      </Button>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            width: "350px",
            display: "inline",
            mx: "auto",
            py: 2,
            px: 4,
            borderRadius: "12px",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              mb: 2,
            }}
          >
            Add credits
          </Typography>
          <TextField
            label={"credits *"}
            type="number"
            inputProps={{
              step: "any",
            }}
            sx={{ mb: 2, width: "100%" }}
            value={credits}
            onChange={(e) => setCredits(parseInt(e.target.value))}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              label={"expired at *"}
              inputFormat="YYYY-MM-DD"
              value={expiredAt}
              onChange={(newValue: Dayjs | null) =>
                setExpiredAt(newValue?.format("YYYY-MM-DD") as string)
              }
              // minDate={(new Date()).setTime((new Date()).getTime() + (1 * 24 * 60 * 60 * 1000)) as any}
              renderInput={(params) => (
                <TextField fullWidth {...params} value={expiredAt} />
              )}
            />
          </LocalizationProvider>
          <Box
            sx={{
              py: 2,
            }}
          >
            <Typography
              sx={{
                mb: 2,
              }}
            >
              Set default expired date by package
            </Typography>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Button
                color="warning"
                variant="outlined"
                fullWidth
                sx={{
                  mr: 1,
                }}
                onClick={() => {
                  let today = new Date();
                  let expiredAtDate = new Date(
                    today.setTime(
                      new Date().getTime() + 30 * 24 * 60 * 60 * 1000
                    )
                  );
                  setExpiredAt(dayjs(expiredAtDate).format("YYYY-MM-DD"));
                }}
              >
                Month
              </Button>
              <Button
                color="warning"
                variant="outlined"
                fullWidth
                sx={{
                  ml: 1,
                }}
                onClick={() => {
                  let today = new Date();
                  let expiredAtDate = new Date(
                    today.setTime(
                      new Date().getTime() + 365 * 24 * 60 * 60 * 1000
                    )
                  );
                  setExpiredAt(dayjs(expiredAtDate).format("YYYY-MM-DD"));
                }}
              >
                Year
              </Button>
            </Box>
          </Box>

          <Divider sx={{ my: 2 }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              type="button"
              variant="contained"
              onClick={() => setIsOpen(false)}
              color="error"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={onSubmit}
              disabled={credits <= 0 || !credits}
            >
              Add
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const ButtonAddTrainingCredits = ({
  member,
  fetchData,
}: {
  member: ICCKMember;
  fetchData: () => void;
}) => {
  const [professionals, setProfessionals] = useState<ICCKProfessional[]>([]);
  const [professional, setProfessional] = useState<number | null>(
    member.professional ? member.professional.id : null
  );
  const [credits, setCredits] = useState<number>(0);
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!isOpen) {
      setCredits(0);
      setProfessional(member.professional ? member.professional.id : null);
    } else {
      fetchProfessionals();
    }
  }, [isOpen]);

  const fetchProfessionals = useCallback(() => {
    CCKProfessionalServices.getAllActive(token as string)
      .then((res) => {
        setProfessionals(res.data.data);
      })
      .catch(() => {
        onAlert("error", "Something went wrong. Please try again later");
      });
  }, []);

  const onSubmit = () => {
    Swal.fire({
      icon: "warning",
      html: `Confirm add training credits?`,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {
          credits: credits,
          professional_id: professional as number,
        };
        CCKMemberServices.addTrainingCredits(
          token as string,
          member.id + "",
          data
        )
          .then((res) => {
            setIsOpen(false);
            fetchData();
            onAlert("success", "Add credits success");
          })
          .catch((err) => {
            onAlert("error", err.response.data.message);
          });
      }
    });
  };

  const onClearTrainingCredits = useCallback(() => {
    Swal.fire({
      icon: "warning",
      html: `Confirm clear training credits?`,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      CCKMemberServices.clearTrainingCredits(token as string, member.id)
        .then((res) => {
          fetchData();
          onAlert("success", "Clear training credits success");
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    });
  }, [member]);

  return (
    <>
      <ButtonGroup variant="contained">
        <Button color="info" onClick={() => setIsOpen(true)}>
          Add
        </Button>
        <Button
          color="error"
          onClick={onClearTrainingCredits}
          disabled={!member.professional}
        >
          Clear
        </Button>
      </ButtonGroup>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            width: "350px",
            display: "inline",
            mx: "auto",
            py: 2,
            px: 4,
            borderRadius: "12px",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              mb: 2,
            }}
          >
            Add training credits
          </Typography>
          <TextField
            label={"credits *"}
            type="number"
            inputProps={{
              step: "any",
            }}
            sx={{ mb: 2, width: "100%" }}
            value={credits}
            onChange={(e) => setCredits(parseInt(e.target.value))}
          />
          <FormControl fullWidth disabled={!!member.professional}>
            <InputLabel id="professional">Professional</InputLabel>
            <Select
              labelId="professional"
              value={professional}
              label="Professional"
              onChange={(e) => setProfessional(e.target.value as number)}
            >
              {professionals.map((p) => {
                return (
                  <MenuItem
                    value={p.id}
                  >{`${p.first_name} ${p.last_name} (${p.type})`}</MenuItem>
                );
              })}
              {!!member.professional && !member.professional.is_active ? (
                <MenuItem
                  value={member.professional.id}
                >{`${member.professional.first_name} ${member.professional.last_name} (${member.professional.type})`}</MenuItem>
              ) : null}
            </Select>
          </FormControl>
          <Divider sx={{ my: 2 }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              type="button"
              variant="contained"
              onClick={() => setIsOpen(false)}
              color="error"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={onSubmit}
              disabled={credits <= 0 || !credits || !professional}
            >
              Add
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default withAuth(withPermission(ListMember, "MANAGE_SIMULATOR_BAY"));
