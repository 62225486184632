import { IPaginationRequest } from "interfaces/pagination";
import axios from "axios";

const MemberService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/member`,
});

const MemberServices = {
  getRanking: (token: string, line_oa_code: string) =>
    MemberService.get(`/${line_oa_code}/ranking`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getNumber: (token: string, line_oa_code: string) =>
    MemberService.get(`/${line_oa_code}/number`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getDetail: (token: string, line_oa_code: string, id: string) =>
    MemberService.get(`/${line_oa_code}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getAll: (
    token: string,
    line_oa_code: string,
    pagination: IPaginationRequest,
    search: string
  ) =>
    MemberService.get(
      `/${line_oa_code}/list?perPage=${pagination.per_page}&page=${pagination.page}&search=${search}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ),
  edit: (
    token: string,
    line_oa_code: string,
    id: string | number,
    data: {
      first_name: string;
      last_name: string;
      gender: string;
      birth_date: string;
      email: string;
      tel: string;
      bank_id?: number;
      bank_account_name?: string;
      bank_account_number?: string;
    }
  ) =>
    MemberService.put(`/${line_oa_code}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  addSpend: (
    token: string,
    line_oa_code: string,
    id: string | number,
    data: {
      spend: number;
    }
  ) =>
    MemberService.put(`/${line_oa_code}/${id}/spend`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  clearLineID: (
    token: string,
    line_oa_code: string,
    id: string | number,
  ) =>
    MemberService.put(`/${line_oa_code}/${id}/clear-line-id`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default MemberServices;
