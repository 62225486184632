import AdminDetail from "pages/manage-admin/admin/detail";
import CreateAdmin from "pages/manage-admin/admin/create";
import EditAdmin from "pages/manage-admin/admin/edit";
import ListAdmin from "pages/manage-admin/admin/list";
import { RouteObject } from "react-router-dom";

const AdminRouters: RouteObject[] = [
  {
    path: "/admin/list",
    element: <ListAdmin />,
  },
  {
    path: "/admin/create",
    element: <CreateAdmin />,
  },
  {
    path: "/admin/:id",
    element: <AdminDetail />,
  },
  {
    path: "/admin/:id/edit",
    element: <EditAdmin />,
  },
];

export default AdminRouters;
