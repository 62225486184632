import * as React from "react";

import Avatar from "@mui/material/Avatar";
import { Tooltip } from "@mui/material";

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  let letter =
    name.split(" ").length > 1
      ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
      : `${name.split(" ")[0][0]}`;
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: letter.toUpperCase(),
  };
}

interface Props {
  label: string;
  tooltip?: boolean;
  center?: boolean;
}

const AvatarLetter: React.FC<Props> = ({ label, tooltip, center }) => {
  if (tooltip) {
    return (
      <Tooltip title={label}>
        <Avatar
          style={center ? { marginLeft: "auto", marginRight: "auto" } : {}}
          {...stringAvatar(label)}
        />
      </Tooltip>
    );
  } else {
    return <Avatar {...stringAvatar(label)} />;
  }
};

export default AvatarLetter;
