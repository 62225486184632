import { IPaginationRequest } from "interfaces/pagination";
import axios from "axios";

const CobraStoreService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/cobra/store`,
});

const CobraStoreServices = {
  getDetail: (token: string, id: string) =>
    CobraStoreService.get(`/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getAll: (
    token: string,
    pagination: IPaginationRequest
  ) =>
    CobraStoreService.get(
      `/list?perPage=${pagination.per_page}&page=${pagination.page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ),
  create: (
    token: string,
    data: {
      name: string;
      lat: number;
      lng: number;
      address: string;
      tel: string;
      url: string;
    }
  ) =>
    CobraStoreService.post("", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  edit: (
    token: string,
    id: string | number,
    data: {
      name: string;
      lat: number;
      lng: number;
      address: string;
      tel: string;
      url: string;
    }
  ) =>
    CobraStoreService.put(`/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  delete: (token: string, id: string | number) =>
    CobraStoreService.delete(`/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default CobraStoreServices;
