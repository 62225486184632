import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import { useState } from "react";

const drawerWidth = 280;

const Layout = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer =
    (isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setIsOpen(isOpen);
    };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Navbar drawerWidth={drawerWidth} toggleDrawer={toggleDrawer} />
      <Box
        sx={{
          width: {
            xs: "auto",
            md: "280px",
          },
        }}
      >
        <Sidebar
          drawerWidth={drawerWidth}
          isOpen={isOpen}
          toggleDrawer={toggleDrawer}
        />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          px: 5,
          minHeight: "100vh",
          py: "92px",
          width: {
            xs: "100vw",
            lg: "calc(100% - 280px)",
          },
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
