import axios from "axios";

const FileService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/file`,
});

const FileServices = {
  create: (token: string, data: FormData) =>
    FileService.post(`/create`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default FileServices;
