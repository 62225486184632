import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import FieldList from "components/common/FieldList";
import Header from "components/common/Header";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import { ICobraProduct } from "interfaces/cobraProduct";
import CobraProductServices from "services/cobraProduct";

const ProductDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useToken();
  const { onAlert } = useAlert();

  const [product, setProduct] = useState<ICobraProduct | null>(null);

  useEffect(() => {
    const fetchData = () => {
      CobraProductServices.getDetail(
        token as string,
        id as string
      )
        .then((res) => {
          setProduct(res.data.data);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    };

    fetchData();
  }, [id, token, onAlert]);

  if (product) {
    return (
      <>
        <Header
          title="Product Master Details"
          breadcrumbs={[
            {
              label: "Dashboard",
              href: `/dashboard`,
            },
            {
              label: "Cobra",
              href: `/dashboard/cobra`,
            },
            {
              label: "Product Master",
              href: `/product/cobra/list`,
            },
            {
              label: product.name,
            },
          ]}
        />
        <FieldList
          type="detail"
          fields={[
            {
              label: "Product Category",
              type: "text",
              name: "category",
              defaultValue: product.category,
              col: 6,
            },
            {
              label: "Model Name",
              type: "text",
              name: "name",
              defaultValue: product.name,
              col: 6,
            },
            {
              label: "Year",
              type: "text",
              name: "year",
              defaultValue: product.year,
              col: 6,
            },
            {
              label: "Season",
              type: "text",
              name: "season",
              defaultValue: product.season,
              col: 6,
            },
            {
              label: "Image",
              type: "image",
              name: "image",
              defaultValue: product.image,
              col: 6,
            },
          ]}
          onBack={() => navigate(`/product/cobra/list`)}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default withAuth(withPermission(ProductDetail, "MANAGE_COBRA"));
