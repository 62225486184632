import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import FieldList from "components/common/FieldList";
import Header from "components/common/Header";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import { IMember } from "interfaces/member";
import CobraMemberServices from "services/cobraMember";

const MemberDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useToken();
  const { onAlert } = useAlert();

  const [member, serMember] = useState<IMember | null>(null);

  useEffect(() => {
    const fetchData = () => {
      CobraMemberServices.getDetail(
        token as string,
        id as string
      )
        .then((res) => {
          serMember(res.data.data);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    };

    fetchData();
  }, [id, onAlert, token]);

  if (member) {
    return (
      <>
        <Header
          title="Member Details"
          breadcrumbs={[
            {
              label: "Dashboard",
              href: `/dashboard`,
            },
            {
              label: "Cobra",
              href: `/dashboard/cobra`,
            },
            {
              label: "Member",
              href: `/member/cobra/list`,
            },
            {
              label: `${member.first_name} ${member.last_name}`,
            },
          ]}
        />
        <FieldList
          type="detail"
          fields={[
            {
              label: "Firstname",
              type: "text",
              name: "first_name",
              defaultValue: member?.first_name,
              col: 6,
            },
            {
              label: "Lastname",
              type: "text",
              name: "last_name",
              defaultValue: member?.last_name,
              col: 6,
            },
            {
              label: "Email",
              type: "text",
              name: "email",
              defaultValue: member?.email,
              col: 6,
            },
            {
              label: "Phone Number",
              type: "text",
              name: "tel",
              defaultValue: member?.tel,
              inputOptions: {
                required: "กรุณากรอกเบอร์โทรศัพท์",
              },
              col: 6,
            },
            {
              label: "Gender",
              type: "select",
              name: "gender",
              defaultValue: member?.gender,
              inputOptions: {
                required: "กรุณาเลือกเพศ",
              },
              items: [
                {
                  label: "Male",
                  value: "M",
                },
                {
                  label: "Female",
                  value: "F",
                },
                {
                  label: "Other",
                  value: "O",
                },
              ],
              col: 6,
            },
            {
              label: "Birth Date",
              type: "date",
              name: "birth_date",
              defaultValue: member?.birth_date,
              inputOptions: {
                required: "กรุณาเลือกวันเกิด",
              },
              col: 6,
            },
          ]}
          onBack={() => navigate(`/member/cobra/list`)}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default withAuth(withPermission(MemberDetail, "MANAGE_COBRA"));
