import { IPaginationRequest } from "interfaces/pagination";
import axios from "axios";

export type TCreateContactData = {
  label: string;
  value: string;
  image: string | null;
  contact_type_id: number;
};

const cobraContactService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/cobra/contact`,
});

const CobraContactServices = {
  getDetail: (token: string, id: string) =>
    cobraContactService.get(`/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getAll: (token: string, pagination: IPaginationRequest) =>
    cobraContactService.get(
      `/list?perPage=${pagination.per_page}&page=${pagination.page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ),
  create: (token: string, data: TCreateContactData) =>
    cobraContactService.post(``, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  edit: (token: string, id: string | number, data: TCreateContactData) =>
    cobraContactService.put(`/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  delete: (token: string, id: string | number) =>
    cobraContactService.delete(`/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default CobraContactServices;
