import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import BankServices from "services/bank";
import FieldList from "components/common/FieldList";
import Header from "components/common/Header";
import { IBank } from "interfaces/bank";
import { IMember } from "interfaces/member";
import MemberServices from "services/member";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

type TEditMember = {
  first_name: string;
  last_name: string;
  gender: string;
  birth_date: string;
  email: string;
  tel: string;
  // bank_id?: number;
  // bank_account_name?: string;
  // bank_account_number?: string;
};

const EditDetail = () => {
  let line_oa_code = "ONLY_GOLF";
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useToken();
  const { onAlert } = useAlert();

  const [member, serMember] = useState<IMember | null>(null);
  const [banks, setBanks] = useState<
    {
      label: string;
      value: any;
    }[]
  >([
    {
      label: "เลือกธนาคาร",
      value: 0,
    },
  ]);

  useEffect(() => {
    const fetchData = () => {
      BankServices.getAll(token as string)
        .then((res) => {
          setBanks((banks) => [
            ...banks,
            ...res.data.data.map((bank: IBank) => {
              return {
                label: bank.name,
                value: bank.id,
              };
            }),
          ]);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
      MemberServices.getDetail(
        token as string,
        line_oa_code as string,
        id as string
      )
        .then((res) => {
          serMember(res.data.data);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    };

    fetchData();
  }, [id, line_oa_code, onAlert, token]);

  const onSubmit = (data: any) => {
    let dataProps: TEditMember = { ...data };
    // if (
    //   dataProps.bank_id === 0 ||
    //   dataProps.bank_account_name === "" ||
    //   dataProps.bank_account_number === ""
    // ) {
    //   delete dataProps.bank_id;
    //   delete dataProps.bank_account_name;
    //   delete dataProps.bank_account_number;
    // }
    MemberServices.edit(
      token as string,
      line_oa_code as string,
      id as string,
      dataProps
    )
      .then((res) => {
        onAlert("success", "แก้ไข Member สำเร็จ");
        navigate(`/member/only-golf/list`);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
      });
  };

  if (member && banks) {
    return (
      <>
        <Header
          title="Edit Member"
          breadcrumbs={[
            {
              label: "Dashboard",
              href: `/dashboard`,
            },
            {
              label: "Only Golf",
              href: `/dashboard/only-golf`,
            },
            {
              label: "Member",
              href: `/member/only-golf/list`,
            },
            {
              label: `${member.first_name} ${member.last_name}`,
            },
          ]}
        />
        <FieldList
          type="edit"
          onSubmit={onSubmit}
          fields={[
            {
              label: "First Name",
              type: "text",
              name: "first_name",
              defaultValue: member?.first_name,
              col: 6,
              tooltip: "สำหรับแก้ไขข้อมูลชื่อของลูกค้า",
            },
            {
              label: "Last Name",
              type: "text",
              name: "last_name",
              defaultValue: member?.last_name,
              col: 6,
              tooltip: "สำหรับแก้ไขข้อมูลนามสกุลของลูกค้า",
            },
            {
              label: "Email",
              type: "text",
              name: "email",
              defaultValue: member?.email,
              col: 6,
              tooltip: "สำหรับแก้ไขข้อมูลอีเมลของลูกค้า",
            },
            {
              label: "Phone Number",
              type: "text",
              name: "tel",
              defaultValue: member?.tel,
              inputOptions: {
                required: "กรุณากรอกเบอร์โทรศัพท์",
              },
              col: 6,
              tooltip: "สำหรับแก้ไขข้อมูลหมายเลขโทรศัพท์ของลูกค้า",
            },
            {
              label: "Gender",
              type: "select",
              name: "gender",
              defaultValue: member?.gender,
              inputOptions: {
                required: "กรุณาเลือกเพศ",
              },
              items: [
                {
                  label: "Male",
                  value: "M",
                },
                {
                  label: "Female",
                  value: "F",
                },
                {
                  label: "Other",
                  value: "O",
                },
              ],
              col: 6,
              tooltip: "สำหรับแก้ไขข้อมูลเพศของลูกค้า",
            },
            {
              label: "Birth Date",
              type: "date",
              name: "birth_date",
              defaultValue: member?.birth_date,
              inputOptions: {
                required: "กรุณาเลือกวันเกิด",
              },
              col: 6,
              tooltip: "สำหรับแก้ไขข้อมูลวัน/เดือน/ปีเกิดของลูกค้า",
            },
            // {
            //   label: "Bank",
            //   type: "select",
            //   name: "bank_id",
            //   defaultValue: member?.bank?.bank.id || "",
            //   items: banks,
            //   col: 6,
            // },
            // {
            //   label: "",
            //   type: "box",
            //   name: "",
            //   col: 6,
            // },
            // {
            //   label: "Bank Account Name",
            //   type: "text",
            //   name: "bank_account_name",
            //   defaultValue: member?.bank?.account_name || "",
            //   col: 6,
            // },
            // {
            //   label: "Bank Account Number",
            //   type: "text",
            //   name: "bank_account_number",
            //   defaultValue: member?.bank?.account_number || "",
            //   col: 6,
            // },
          ]}
          onBack={() => navigate(`/member/only-golf/list`)}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default withAuth(withPermission(EditDetail, "MANAGE_ONLY_GOLF"));
