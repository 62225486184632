import axios from "axios";

const BankService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/bank`,
});

const BankServices = {
  getAll: (token: string) =>
    BankService.get(`/list`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default BankServices;
