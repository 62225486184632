import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import CCKBayServices from "services/cckBay";
import FieldList from "components/common/FieldList";
import Header from "components/common/Header";
import { ICCKBay } from "interfaces/cckBay";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

type TEditCCKBay = {
  bay_number: number;
  name: string;
};

const EditBay = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useToken();
  const { onAlert } = useAlert();

  const [bay, setBay] = useState<ICCKBay | null>(null);

  useEffect(() => {
    const fetchData = () => {
      CCKBayServices.getDetail(token as string, id as string)
        .then((res) => {
          setBay(res.data.data);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    };

    fetchData();
  }, [id, onAlert, token]);

  const onSubmit = (data: any) => {
    let dataProps: TEditCCKBay = {
      bay_number: parseInt(data.bay_number),
      name: data.name,
    };
    CCKBayServices.edit(token as string, id as string, dataProps)
      .then((res) => {
        onAlert("success", "แก้ไข Bay สำเร็จ");
        navigate(`/simulator-bay/bay`);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
      });
  };

  if (bay) {
    return (
      <>
        <Header
          title="Edit Bay"
          breadcrumbs={[
            {
              label: "Dashboard",
              href: `/dashboard`,
            },
            {
              label: "Simulator Bay",
              href: `/simulator-bay/dashboard`,
            },
            {
              label: "Member",
              href: `/simulator-bay/bay`,
            },
            {
              label: bay.name,
            },
          ]}
        />
        <FieldList
          type="edit"
          onSubmit={onSubmit}
          fields={[
            {
              label: "Bay number",
              type: "text",
              name: "bay_number",
              defaultValue: bay?.bay_number,
              col: 6,
              tooltip: "สำหรับแก้ไขเลข Bay",
            },
            {
              label: "Bay name",
              type: "text",
              name: "name",
              defaultValue: bay?.name,
              col: 6,
              tooltip: "สำหรับแก้ไขชื่อ Bay",
            },
          ]}
          onBack={() => navigate(`/simulator-bay/bay`)}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default withAuth(withPermission(EditBay, "MANAGE_SIMULATOR_BAY"));
