import { IPaginationRequest } from "interfaces/pagination";
import axios from "axios";

export type TCreateProductData = {
  name: string;
  category: string;
  year: string;
  season: string;
  image: string;
};

const cobraProductService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/cobra/product`,
});

const CobraProductServices = {
  getDetail: (token: string, id: string) =>
    cobraProductService.get(`/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getAll: (
    token: string,
    pagination: IPaginationRequest
  ) =>
    cobraProductService.get(
      `/list?perPage=${pagination.per_page}&page=${pagination.page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ),
  create: (token: string, data: TCreateProductData) =>
    cobraProductService.post("", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  edit: (
    token: string,
    id: string | number,
    data: TCreateProductData
  ) =>
    cobraProductService.put(`/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  delete: (token: string, id: string | number) =>
    cobraProductService.delete(`/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default CobraProductServices;
