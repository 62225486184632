import CCKBayServices from "services/cckBay";
import FieldList from "components/common/FieldList";
import Header from "components/common/Header";
import useAlert from "hooks/useAlert";
import { useNavigate } from "react-router-dom";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

type TCreateBay = {
  bay_number: number;
  name: string;
};

const CreateBay = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { onAlert } = useAlert();

  const onSubmit = (data: any) => {
    let dataProps: TCreateBay = {
      bay_number: parseInt(data.bay_number),
      name: data.name,
    };
    CCKBayServices.create(token as string, dataProps)
      .then((res) => {
        onAlert("success", "Create bay success");
        navigate(`/simulator-bay/bay`);
      })
      .catch((err) => {
        onAlert("error", "Please try again later");
        navigate(`/simulator-bay/bay`);
      });
  };

  return (
    <>
      <Header
        title="Create a new bay"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: `/dashboard`,
          },
          {
            label: "Simulator Bay",
            href: `/simulator-bay/dashboard`,
          },
          {
            label: "Bay",
            href: "/simulator-bay/bay",
          },
          {
            label: "New bay",
          },
        ]}
      />
      <FieldList
        type="create"
        onSubmit={onSubmit}
        fields={[
          {
            label: "Bay number",
            type: "number",
            name: "bay_number",
            inputOptions: {
              required: "กรุณากรอกเลข Bay",
            },
            col: 6,
            tooltip: "สำหรับแสดงเลข Bay",
          },
          {
            label: "Bay Name",
            type: "text",
            name: "name",
            inputOptions: {
              required: "กรุณากรอกชื่อ Bay",
            },
            col: 6,
            tooltip: "สำหรับแสดงชื่อ Bay",
          },
        ]}
        onBack={() => navigate(`/simulator-bay/bay`)}
      />
    </>
  );
};

export default withAuth(withPermission(CreateBay, "MANAGE_SIMULATOR_BAY"));
