import { IPaginationRequest } from "interfaces/pagination";
import axios from "axios";

const CouponTypeService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/coupon-type`,
});

const CouponTypeServices = {
  getDetail: (token: string, line_oa_code: string, id: string) =>
    CouponTypeService.get(`/${line_oa_code}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getAll: (
    token: string,
    line_oa_code: string,
    pagination: IPaginationRequest
  ) =>
    CouponTypeService.get(
      `/${line_oa_code}/list?perPage=${pagination.per_page}&page=${pagination.page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ),
  create: (token: string, line_oa_code: string, data: FormData) =>
    CouponTypeService.post(`/${line_oa_code}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    }),
  edit: (
    token: string,
    line_oa_code: string,
    id: string | number,
    data: FormData
  ) =>
    CouponTypeService.put(`/${line_oa_code}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  delete: (token: string, line_oa_code: string, id: string | number) =>
    CouponTypeService.delete(`/${line_oa_code}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default CouponTypeServices;
