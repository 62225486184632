import { useEffect, useState } from "react";

import FileServices from "services/file";
import Header from "components/common/Header";
import useAlert from "hooks/useAlert";
import { useNavigate } from "react-router-dom";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import CobraContactServices, { TCreateContactData } from "services/cobraContact";
import { ICobraContactType } from "interfaces/cobraContactType";
import FieldList from "components/common/FieldList";
import CobraContactTypeServices from "services/cobraContactType";

type TCreateContact = {
  label: string;
  value: string;
  image: FileList | undefined;
  contact_type_id: string;
};

const CreateContact = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [contactTypes, setContactTypes] = useState<
    {
      label: any;
      value: any;
    }[]
  >([]);

  useEffect(() => {
    const fetchData = () => {
      CobraContactTypeServices.getAll(token as string, {
        page: 1,
        per_page: 100,
      }).then((res) => {
        setContactTypes(
          res.data.data.map((contactType: ICobraContactType) => {
            return {
              label: contactType.name,
              value: contactType.id,
            };
          })
        );
      });
    };

    fetchData();
  }, [token]);

  const onSubmit = async (values: TCreateContact) => {
    let data: TCreateContactData = {
      label: values.label,
      value: values.value,
      image: null,
      contact_type_id: parseInt(values.contact_type_id),
    };
    let isError = false;
    if (values.image && values.image[0]) {
      let formData = new FormData();
      formData.append("file", values.image[0]);
      formData.append("prefix", "contact");
      formData.append("path", "/contact");
      await FileServices.create(token as string, formData)
        .then((res) => {
          data.image = res.data.data;
        })
        .catch((err) => {
          isError = true;
          onAlert("error", err.response.data.message);
        });
    }
    if (!isError) {
      CobraContactServices.create(token as string, data)
        .then((res) => {
          onAlert("success", "สร้าง Contact สำเร็จ");
          navigate(`/contact/cobra/list`);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    }
  };

  if (contactTypes) {
    return (
      <>
        <Header
          title="Create a new Contact"
          breadcrumbs={[
            {
              label: "Dashboard",
              href: "/dashboard",
            },
            {
              label: "Contact",
              href: "/contact/cobra/list",
            },
            {
              label: "New Contact",
            },
          ]}
        />
        <FieldList
          type="create"
          onSubmit={onSubmit}
          fields={[
            {
              label: "Cover Image (1:1)",
              type: "image",
              name: "image",
              col: 6,
              tooltip: "สำหรับกำหนดข้อมูลรูปภาพแสดงผลของ Contact",
            },
            {
              label: "",
              type: "box",
              name: "",
              col: 6,
            },
            {
              label: "Contact Type",
              type: "select",
              name: "contact_type_id",
              inputOptions: {
                required: "กรุณาเลือกประเภทของ Contact",
              },
              col: 6,
              items: contactTypes,
              defaultValue: "",
              tooltip: "ตัวเลือกสำหรับกำหนดประเภทของ Contact",
            },
            {
              label: "",
              type: "box",
              name: "",
              col: 6,
            },
            {
              label: "Contact Label",
              type: "text",
              name: "label",
              inputOptions: {
                required: "กรุณากรอกชื่อ Contact",
              },
              col: 6,
              tooltip: "สำหรับกำหนดชื่อ Contact",
            },
            {
              label: "Contact Value",
              type: "text",
              name: "value",
              inputOptions: {
                required: "กรุณากรอก Contact Value",
              },
              col: 6,
              tooltip: "สำหรับกำหนด Contact Value",
            },
          ]}
          onBack={() => navigate(`/contact/list`)}
        />
      </>
    );
  }
};

export default withAuth(withPermission(CreateContact, "MANAGE_COBRA"));
