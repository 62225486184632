import { IPaginationRequest } from "interfaces/pagination";
import axios from "axios";

const CobraMemberService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/cobra/member`,
});

const CobraMemberServices = {
  getNumber: (token: string) =>
    CobraMemberService.get(`/number`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getDetail: (token: string, id: string) =>
    CobraMemberService.get(`/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getAll: (
    token: string,
    pagination: IPaginationRequest,
    search: string
  ) =>
    CobraMemberService.get(
      `/list?perPage=${pagination.per_page}&page=${pagination.page}&search=${search}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ),
  edit: (
    token: string,
    id: string | number,
    data: {
      first_name: string;
      last_name: string;
      gender: string;
      birth_date: string;
      email: string;
      tel: string;
      bank_id?: number;
      bank_account_name?: string;
      bank_account_number?: string;
    }
  ) =>
    CobraMemberService.put(`/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  clearLineID: (
    token: string,
    id: string | number,
  ) =>
    CobraMemberService.put(`/${id}/clear-line-id`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default CobraMemberServices;
