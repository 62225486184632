import { FormControl, FormHelperText, TextField, Tooltip } from "@mui/material";

import { Box } from "@mui/system";
import { IField } from "./Field";
import { Info } from "@mui/icons-material";
import React from "react";

const UploadImage: React.FC<IField> = ({
  form,
  name,
  label,
  disabled,
  inputOptions,
  error,
  errorMessage,
  defaultValue,
  tooltip,
}) => {
  return (
    <>
      {(defaultValue ||
        ((form.watch(name) as FileList) &&
          (form.watch(name) as FileList)[0])) && (
        <img
          src={
            (form.watch(name) as FileList) && (form.watch(name) as FileList)[0]
              ? window.URL.createObjectURL((form.watch(name) as FileList)[0])
              : process.env.REACT_APP_FILE + defaultValue
          }
          style={{
            width: "100%",
            maxHeight: "300px",
            objectFit: "cover",
            borderRadius: "8px",
            marginBottom: "8px",
          }}
          alt={`preview-field-${name}`}
        />
      )}
      <FormControl key={"field-" + name} fullWidth error={error}>
        <input
          {...form.register(name, { ...inputOptions })}
          type="file"
          accept="image/*"
          disabled={disabled}
          style={{
            border: `1px solid ${error ? "#d32f2f" : "#e8ecee"}`,
            padding: "16px",
            borderRadius: "8px",
            width: "100%",
            cursor: "pointer",
          }}
        />
        {tooltip && (
          <Tooltip title={tooltip}>
            <Info
              sx={{
                color: "#757575",
                position: "absolute",
                right: 14,
                top: 15,
                cursor: "pointer",
              }}
            />
          </Tooltip>
        )}
        <FormHelperText
          sx={{
            position: "absolute",
            fontSize: 12,
            left: 9,
            top: -12,
            px: 0.5,
            bgcolor: "white",
            mx: 0,
          }}
        >
          {inputOptions?.required ? label + " *" : label}
        </FormHelperText>
        <FormHelperText>{errorMessage}</FormHelperText>
      </FormControl>
    </>
  );
};

export default UploadImage;
