import { useNavigate } from "react-router-dom";

import FieldList from "components/common/FieldList";
import Header from "components/common/Header";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import { useEffect } from "react";
import CobraStoreServices from "services/cobraStore";

type TCreateStore = {
  name: string;
  lat: string;
  lng: string;
  address: string;
  tel: string;
  url: string;
};

const CreateStore = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { onAlert } = useAlert();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {};

  const onSubmit = (data: any) => {
    let dataProps: TCreateStore = data;
    CobraStoreServices.create(token as string, {
      ...dataProps,
      lat: parseFloat(dataProps.lat),
      lng: parseFloat(dataProps.lng),
    })
      .then((res) => {
        onAlert("success", "สร้าง Store สำเร็จ");
        navigate(`/store/cobra/list`);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
      });
  };

  return (
    <>
      <Header
        title="Create a new store"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: `/dashboard`,
          },
          {
            label: "Cobra",
            href: `/dashboard/cobra`,
          },
          {
            label: "Store",
            href: "/store/cobra/list",
          },
          {
            label: "New store",
          },
        ]}
      />
      <FieldList
        type="create"
        onSubmit={onSubmit}
        fields={[
          {
            label: "Store Name",
            type: "text",
            name: "name",
            inputOptions: {
              required: "กรุณากรอกชื่อร้าน",
            },
            col: 6,
            tooltip: "สำหรับกำหนดชื่อที่จะแสดงผลของร้านค้า",
          },
          {
            label: "",
            type: "box",
            name: "",
            col: 6,
          },
          
          {
            label: "Latitude",
            type: "number",
            name: "lat",
            inputOptions: {
              required: "กรุณากรอก Latitude",
            },
            col: 6,
            tooltip: "สำหรับกรอกตำแหน่งละติจูดของร้านค้า",
          },
          {
            label: "Longitude",
            type: "number",
            name: "lng",
            inputOptions: {
              required: "กรุณากรอก Logitude",
            },
            col: 6,
            tooltip: "สำหรับกรอกตำแหน่งลองจิจูดของร้านค้า",
          },
          {
            label: "Address",
            type: "text",
            name: "address",
            inputOptions: {
              required: "กรุณากรอกที่อยู่",
            },
            col: 6,
            tooltip: "สำหรับกำหนดที่อยู่ที่จะแสดงผลของร้านค้า",
          },
          {
            label: "Store Phone Number",
            type: "text",
            name: "tel",
            inputOptions: {
              required: "กรุณากรอกเบอร์โทรศัพท์",
            },
            col: 6,
            tooltip: "สำหรับกำหนดหมายเลขโทรศัพท์ของร้านค้า",
          },
          {
            label: "Map URL",
            type: "text",
            name: "url",
            inputOptions: {
              required: "กรุณากรอก Map URL from google map",
            },
            col: 6,
            tooltip: "สำหรับกำหนด URL เพื่อไปยัง Google Map ของร้านค้า",
          },
        ]}
        onBack={() => navigate(`/store/cobra/list`)}
      />
    </>
  );
};

export default withAuth(withPermission(CreateStore, "MANAGE_COBRA"));
