import axios from "axios";

const CCKBookingBayService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/cck-booking-bay`,
});

const CCKBookingBayServices = {
  getDetail: (token: string, id: string) =>
    CCKBookingBayService.get(`/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default CCKBookingBayServices;
