import { Box } from "@mui/material";
import Content from "./Content/Content";
import Drawer from "@mui/material/Drawer";
import { IMenu } from "./Sidebar";

interface Props {
  drawerWidth: number;
  menu: IMenu[];
}

const SidebarDesktop: React.FC<Props> = ({ drawerWidth, menu }) => {
  return (
    <Box
      sx={{
        width: drawerWidth,
        // flexShrink: 0,
        // "& .MuiDrawer-paper": {
        //   width: drawerWidth,
        //   boxSizing: "border-box",
        //   borderRight: `1px dashed rgba(145, 158, 171, 0.24)`,
        //   bgcolor: "background.default",
        //   zIndex: 999,
        // },
        overflowY: "scroll",
        borderRight: `1px dashed rgba(145, 158, 171, 0.24)`,
      }}
      // variant="permanent"
      // anchor="left"
    >
      <Content menu={menu} />
    </Box>
  );
};

export default SidebarDesktop;
