import { IPaginationRequest } from "interfaces/pagination";
import axios from "axios";

const CCKMemberPointService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/cck-member-point`,
});

const CCKMemberPointServices = {
  getByCCKMemberID: (
    token: string,
    pagination: IPaginationRequest,
    cck_member_id: number
  ) =>
    CCKMemberPointService.get(
      `${cck_member_id}?perPage=${pagination.per_page}&page=${pagination.page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ),
  getBookings: (
    token: string,
    cck_member_point_id: number
  ) =>
    CCKMemberPointService.get(
      `${cck_member_point_id}/booking`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ),
};

export default CCKMemberPointServices;
