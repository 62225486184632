import { IPaginationRequest } from "interfaces/pagination";
import axios from "axios";

const CCKMemberService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/cck-member`,
});

const CCKMemberServices = {
  getNumber: (token: string) =>
    CCKMemberService.get(`/number`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getDetailByTel: (token: string, tel: string) =>
    CCKMemberService.get(`/tel/${tel}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getDetail: (token: string, id: string) =>
    CCKMemberService.get(`/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getAll: (token: string, pagination: IPaginationRequest, search: string) =>
    CCKMemberService.get(
      `list?perPage=${pagination.per_page}&page=${pagination.page}&search=${search}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ),
  edit: (
    token: string,
    id: string | number,
    data: {
      first_name: string;
      last_name: string;
      gender: string;
      birth_date: string;
      email: string;
      tel: string;
    }
  ) =>
    CCKMemberService.put(`/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  addCredits: (
    token: string,
    id: string | number,
    data: {
      credits: number;
      expired_at: string;
    }
  ) =>
    CCKMemberService.put(`/${id}/credits`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    cancelCredits: (token: string, id: string | number, credit_id: number) =>
    CCKMemberService.delete(`/${id}/credits/${credit_id}/cancel`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    addTrainingCredits: (
      token: string,
      id: string | number,
      data: {
        credits: number;
        professional_id: number;
      }
    ) =>
      CCKMemberService.put(`/${id}/training-credits`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    clearTrainingCredits: (
      token: string,
      id: string | number,
    ) =>
      CCKMemberService.put(`/${id}/training-credits/clear`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
};

export default CCKMemberServices;
