import { IPaginationRequest } from "interfaces/pagination";
import axios from "axios";

export type TCreateRewardData = {
  image: string;
  image_disable: string;
  spend: number;
  cashback: number;
};

const RewardService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/reward`,
});

const RewardServices = {
  getDetail: (token: string, line_oa_code: string, id: string) =>
    RewardService.get(`/${line_oa_code}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getAll: (
    token: string,
    line_oa_code: string,
    pagination: IPaginationRequest
  ) =>
    RewardService.get(
      `/${line_oa_code}/list?perPage=${pagination.per_page}&page=${pagination.page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ),
  create: (token: string, line_oa_code: string, data: TCreateRewardData) =>
    RewardService.post(`/${line_oa_code}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  edit: (
    token: string,
    line_oa_code: string,
    id: string | number,
    data: TCreateRewardData
  ) =>
    RewardService.put(`/${line_oa_code}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  delete: (token: string, line_oa_code: string, id: string | number) =>
    RewardService.delete(`/${line_oa_code}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default RewardServices;
