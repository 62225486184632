import { Box, Button, Divider, Modal, TextField } from "@mui/material";
import { IPagination, IPaginationRequest } from "interfaces/pagination";
import { useCallback, useEffect, useState } from "react";

import Header from "components/common/Header";
import { IMember } from "interfaces/member";
import MemberServices from "services/member";
import Swal from "sweetalert2";
import Table from "components/common/Table";
import Typography from "@mui/material/Typography";
import useAlert from "hooks/useAlert";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

interface IMemberData {
  id: number;
  line_id: string;
  name: string;
  tel: string;
  spend: number;
  total_spend: number;
  point: number;
  updated_at: string;
}

const ListMember = () => {
  let line_oa_code = "ONLY_GOLF";
  const navigate = useNavigate();
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [search, setSearch] = useState<string>("");
  const [data, setData] = useState<{
    data: IMemberData[];
    pagination: IPagination;
  }>({
    data: [],
    pagination: {
      page: 1,
      total_item: 0,
      total_page: 0,
    },
  });
  const [pagination, setPagination] = useState<IPaginationRequest>({
    page: 1,
    per_page: 10,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchData = useCallback(() => {
    MemberServices.getAll(
      token as string,
      line_oa_code as string,
      {
        per_page: pagination.per_page,
        page: pagination.page,
      },
      search
    )
      .then((res) => {
        let paginationRes: IPagination = res.data.pagination;
        setData({
          data: res.data.data.map((d: IMember) => {
            let value: IMemberData = {
              id: d.id,
              line_id: d.line_id,
              name: `${d.first_name} ${d.last_name}`,
              tel: d.tel,
              spend: d.spend,
              total_spend: d.total_spend,
              point: d.point,
              updated_at: d.updated_at,
            };
            return value;
          }),
          pagination: {
            page: paginationRes.page,
            total_item: paginationRes.total_item,
            total_page: paginationRes.total_page,
          },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
      });
  }, [pagination, line_oa_code, onAlert, token, search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Header
        title="Member"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: `/dashboard`,
          },
          {
            label: "Only Golf",
            href: `/dashboard/only-golf`,
          },
          {
            label: "Member",
            href: "/member/only-golf/list",
          },
          {
            label: "List",
          },
        ]}
      />
      <Table
        headers={[
          {
            key: "name",
            label: "Member name",
            // render: (value) => (
            //   <Box
            //     sx={{
            //       display: "flex",
            //       alignItems: "center",
            //     }}
            //   >
            //     <AvatarLetter label={value} />
            //     <Typography sx={{ ml: 2 }}>{value}</Typography>
            //   </Box>
            // ),
          },
          {
            key: "tel",
            label: "Phone Number",
          },
          {
            key: "spend",
            label: "Spending",
            render: (value: string) => (
              <Typography>{value.toLocaleString()}</Typography>
            ),
          },
          {
            key: "total_spend",
            label: "Total Spend",
            render: (value: string) => (
              <Typography>{value.toLocaleString()}</Typography>
            ),
          },
          {
            key: "point",
            label: "Point",
            render: (value: string) => (
              <Typography>{value.toLocaleString()}</Typography>
            ),
          },
          {
            key: "name",
            label: "Add spend",
            render: (value, index, member) => {
              return <ButtonAddPoint value={member} fetchData={fetchData} />;
            },
          },
          {
            key: "name",
            label: "Cancel line",
            render: (value, index, member) => {
              return <ButtonClearLineID value={member} fetchData={fetchData} />;
            },
          },
        ]}
        actions={{
          onDetail: (item: any) => navigate(`/member/only-golf/${item.id}`),
          onEdit: (item: any) => navigate(`/member/only-golf/${item.id}/edit`),
        }}
        onSearch={(searchText) => setSearch(searchText)}
        data={data.data}
        isLoading={isLoading}
        pagination={
          data.pagination
            ? {
                ...data.pagination,
                onChange: (page) =>
                  setPagination((pagination) => {
                    return {
                      ...pagination,
                      page,
                    };
                  }),
              }
            : undefined
        }
      />
    </>
  );
};

const ButtonAddPoint = ({
  value,
  fetchData,
}: {
  value: IMemberData;
  fetchData: () => void;
}) => {
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm<{ spend: string }>();
  const line_oa_code = "ONLY_GOLF";
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!isOpen) {
      resetField("spend");
    }
  }, [isOpen]);

  const onSubmit = (dataForm: { spend: string }) => {
    Swal.fire({
      icon: "warning",
      html: `ต้องการเพิ่มยอดสั่งซื้อใช่ หรือไม่?`,
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {
          spend: parseInt(dataForm.spend),
        };
        MemberServices.addSpend(
          token as string,
          line_oa_code,
          value.id + "",
          data
        )
          .then((res) => {
            setIsOpen(false);
            fetchData();
            onAlert("success", "เปลี่ยนสถานะสำเร็จ");
          })
          .catch((err) => {
            onAlert("error", err.response.data.message);
          });
      }
    });
  };

  return (
    <>
      <Button
        variant="contained"
        color="success"
        onClick={() => setIsOpen(true)}
      >
        เพิ่มยอดสั่งซื้อ
      </Button>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            width: "350px",
            display: "inline",
            mx: "auto",
            py: 2,
            px: 4,
            borderRadius: "12px",
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{
                mb: 2,
              }}
            >
              เพิ่มยอดสั่งซื้อ
            </Typography>
            <TextField
              label={"ยอดสั่งซื้อ *"}
              type="number"
              inputProps={{
                step: "any",
              }}
              sx={{ mb: 2, width: "100%" }}
              error={!!errors.spend?.message}
              helperText={errors.spend?.message}
              {...register("spend", {
                required: { value: true, message: "กรุณากรอกยอดสั่งซื้อ" },
              })}
            />
            <Divider sx={{ my: 2 }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                type="button"
                variant="contained"
                onClick={() => setIsOpen(false)}
                color="error"
              >
                Cancel
              </Button>
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};

const ButtonClearLineID = ({
  value,
  fetchData,
}: {
  value: IMemberData;
  fetchData: () => void;
}) => {
  const line_oa_code = "ONLY_GOLF";
  const { token } = useToken();
  const { onAlert } = useAlert();

  const onClearLineID = () => {
    Swal.fire({
      icon: "warning",
      html: `ต้องการยกเลิกลงทะเบียนใช่ หรือไม่?`,
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        MemberServices.clearLineID(token as string, line_oa_code, value.id + "")
          .then((res) => {
            fetchData();
            onAlert("success", "ยกเลิกลงทะเบียนสำเร็จ");
          })
          .catch((err) => {
            onAlert("error", err.response.data.message);
          });
      }
    });
  };

  return (
    <>
      <Button
        variant="contained"
        color="error"
        onClick={() => onClearLineID()}
        disabled={!value.line_id}
      >
        ยกเลิกลงทะเบียน
      </Button>
    </>
  );
};

export default withAuth(withPermission(ListMember, "MANAGE_ONLY_GOLF"));
