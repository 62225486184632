import { IPagination, IPaginationRequest } from "interfaces/pagination";
import { useCallback, useEffect, useState } from "react";

import AvatarLetter from "components/common/AvatarLetter";
import { Box } from "@mui/material";
import CouponRedemptionServices from "services/couponRedemption";
import Header from "components/common/Header";
import { ICoupon } from "interfaces/coupon";
import { ICouponRedemption } from "interfaces/couponRedemption";
import { ICouponType } from "interfaces/couponType";
import { IMember } from "interfaces/member";
import Table from "components/common/Table";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

interface ICouponRedemptionData {
  id: number;
  code: string;
  member: IMember;
  coupon: ICoupon;
  couponType: ICouponType;
  redeemed_at: string;
}

const ListCouponRedemption = () => {
  const line_oa_code = "ONLY_GOLF";
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [search, setSearch] = useState<string>("");
  const [filter, setFilter] = useState<{
    date: {
      start: Date | null;
      end: Date | null;
    };
  }>({ date: { start: null, end: null } });
  const [data, setData] = useState<{
    data: ICouponRedemptionData[];
    pagination: IPagination;
  }>({
    data: [],
    pagination: {
      page: 1,
      total_item: 0,
      total_page: 0,
    },
  });
  const [pagination, setPagination] = useState<IPaginationRequest>({
    page: 1,
    per_page: 10,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchData = useCallback(() => {
    CouponRedemptionServices.getAll(
      token as string,
      line_oa_code as string,
      pagination,
      search,
      {
        start: filter.date.start
          ? dayjs(filter.date.start.toString()).format("YYYY-MM-DD")
          : "",
        end: filter.date.end
          ? dayjs(filter.date.end.toString()).format("YYYY-MM-DD")
          : "",
      }
    )
      .then((res) => {
        let paginationRes: IPagination = res.data.pagination;
        setData({
          data: res.data.data.map((d: ICouponRedemption) => {
            return {
              id: d.id,
              code: d.code,
              member: d.member,
              coupon: d.coupon,
              couponType: d.coupon.coupon_type,
              redeemed_at: d.redeemed_at,
            } as ICouponRedemptionData;
          }),
          pagination: {
            page: paginationRes.page,
            total_item: paginationRes.total_item,
            total_page: paginationRes.total_page,
          },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
      });
  }, [pagination, onAlert, token, search, filter]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onExort = useCallback(() => {
    CouponRedemptionServices.export(
      token as string,
      line_oa_code as string,
      pagination,
      search,
      {
        start: filter.date.start
          ? dayjs(filter.date.start.toString()).format("YYYY-MM-DD")
          : "",
        end: filter.date.end
          ? dayjs(filter.date.end.toString()).format("YYYY-MM-DD")
          : "",
      }
    )
      .then((res) => {
        window.location.href = process.env.REACT_APP_FILE + res.data.data;
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
      });
  }, [pagination, token, search, filter, onAlert]);

  return (
    <>
      <Header
        title="Coupon Redemption"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: `/dashboard`,
          },
          {
            label: "Only Golf",
            href: `/dashboard/only-golf`,
          },
          {
            label: "Coupon Redemption",
            href: `/coupon-redemption/only-golf/list`,
          },
          {
            label: "List",
          },
        ]}
      />
      <Table
        headers={[
          {
            key: "member",
            label: "Member",
            render: (value, index, couponRedemption: ICouponRedemptionData) => (
              // <Box
              //   sx={{
              //     display: "flex",
              //     alignItems: "center",
              //   }}
              // >
              //   <AvatarLetter
              //     label={
              //       couponRedemption.member.first_name +
              //       " " +
              //       couponRedemption.member.last_name
              //     }
              //   />
              <Typography
              // sx={{ ml: 2 }}
              >
                {couponRedemption.member.first_name +
                  " " +
                  couponRedemption.member.last_name}
              </Typography>
              // </Box>
            ),
          },
          {
            key: "coupon",
            label: "Coupon",
            render: (value, index, couponRedemption: ICouponRedemptionData) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={
                    process.env.REACT_APP_FILE + couponRedemption.coupon.image
                  }
                  alt="coupon"
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "cover",
                  }}
                />
                <Typography sx={{ ml: 2 }}>
                  {couponRedemption.coupon.name}
                </Typography>
              </Box>
            ),
          },
          {
            key: "couponType",
            label: "Coupon Type",
            render: (value, index, couponRedemption: ICouponRedemptionData) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={
                    process.env.REACT_APP_FILE +
                    couponRedemption.couponType.image
                  }
                  alt="coupon"
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "cover",
                  }}
                />
                <Typography sx={{ ml: 2 }}>
                  {couponRedemption.couponType.name}
                </Typography>
              </Box>
            ),
          },
          {
            key: "code",
            label: "Code",
            render: (value) => <Typography>{value}</Typography>,
          },
          {
            key: "redeemed_at",
            label: "Redeemed At",
            render: (value) => (
              <Typography>{new Date(value).toLocaleString("th-TH")}</Typography>
            ),
          },
        ]}
        onSearch={(searchText) => setSearch(searchText)}
        onExport={onExort}
        filters={[
          {
            type: "daterange",
            name: "Created At",
            value: filter.date,
            onChange: (value: any) =>
              setFilter((filter) => {
                return { ...filter, date: value };
              }),
          },
        ]}
        data={data.data}
        isLoading={isLoading}
        pagination={
          data.pagination
            ? {
                ...data.pagination,
                onChange: (page) =>
                  setPagination((pagination) => {
                    return {
                      ...pagination,
                      page,
                    };
                  }),
              }
            : undefined
        }
      />
    </>
  );
};

export default withAuth(
  withPermission(ListCouponRedemption, "MANAGE_ONLY_GOLF")
);
