import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  colors,
} from "@mui/material";
import { ICCKBooking, ICCKTimeSlot } from "interfaces/cckBooking";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CCKBayServices from "services/cckBay";
import CCKBookingServices from "services/cckBooking";
import CCKTimeSlotServices from "services/cckTimeSlot";
import Header from "components/common/Header/Header";
import { ICCKBay } from "interfaces/cckBay";
import { ICCKBookingBay } from "interfaces/cckBookingBay";
import { Link } from "react-router-dom";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

const Dashboard = () => {
  const { token } = useToken();
  const [timeSlots, setTimeSlots] = useState<ICCKTimeSlot[]>([]);
  const [bays, setBays] = useState<ICCKBay[]>([]);
  const [date, setDate] = useState<string>(
    dayjs(new Date()).format("YYYY-MM-DD")
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<
    {
      id: number;
      start_time: string;
      bays: {
        bookings: {
          start_time: string;
          booking: ICCKBooking;
          bookingBay: ICCKBookingBay;
        }[];
      }[];
    }[]
  >([]);

  useEffect(() => {
    setIsLoading(true);
    CCKTimeSlotServices.getAll(token as string).then((res) =>
      setTimeSlots(res.data.data)
    );
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (timeSlots.length > 0) {
      setIsLoading(true);
      CCKBayServices.getAllActive(token as string, date).then((res) => {
        setBays(res.data.data);
        let baysData: ICCKBay[] = res.data.data;
        CCKBookingServices.getAllActiveByDate(token as string, date)
          .then((res) => {
            console.log(res.data.data);
            let bookings: ICCKBooking[] = res.data.data;
            let dataPropsList: {
              id: number;
              start_time: string;
              bays: {
                bookings: {
                  start_time: string;
                  booking: ICCKBooking;
                  bookingBay: ICCKBookingBay;
                }[];
              }[];
            }[] = [];
            timeSlots.forEach((timeSlot) => {
              let startTimeSlot = new Date(
                `${dayjs(date).format("YYYY-MM-DD")} ${timeSlot.time_start}`
              );
              let endTimeSlot = new Date(
                `${dayjs(date).format("YYYY-MM-DD")} ${timeSlot.time_end}`
              );
              let baysProps: {
                bookings: {
                  start_time: string;
                  booking: ICCKBooking;
                  bookingBay: ICCKBookingBay;
                }[];
              }[] = [];
              baysData.forEach((bay) => {
                let bookingBays: {
                  start_time: string;
                  booking: ICCKBooking;
                  bookingBay: ICCKBookingBay;
                }[] = [];
                bookings.forEach((booking) => {
                  booking.booking_bays.forEach((booking_bay) => {
                    console.log(
                      "bay: ",
                      bay.name,
                      "booking: ",
                      booking.code,
                      "booking_bay: ",
                      booking_bay.code
                    );
                    if (booking_bay.bay.id === bay.id) {
                      let startTime = new Date(
                        `${booking.booking_date} ${booking_bay.booking_start_time}`
                      );
                      let endTime = new Date(
                        `${booking.booking_date} ${booking_bay.booking_end_time}`
                      );
                      if (startTimeSlot < endTime && endTimeSlot > startTime) {
                        let hours = endTime.getHours() - startTime.getHours();
                        for (let i = 0; i < hours; i++) {
                          bookingBays.push({
                            start_time: booking_bay.booking_start_time,
                            booking: booking,
                            bookingBay: booking_bay,
                          });
                        }
                      }
                    }
                  });
                });
                baysProps.push({ bookings: bookingBays });
              });
              let dataProps = {
                id: timeSlot.id,
                start_time: timeSlot.time_start,
                bays: baysProps,
              };
              dataPropsList.push(dataProps);
            });
            setData(dataPropsList);
            console.log(dataPropsList);
          })
          .catch(() => {});
      });
      setIsLoading(false);
    }
  }, [token, date, timeSlots]);

  return (
    <>
      <Header
        title="Dashboard"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: `/dashboard`,
          },
          {
            label: "Simulator Bay",
          },
        ]}
      />
      <Paper>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Box
            sx={{
              mb: 2,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                label="Date"
                inputFormat="YYYY-MM-DD"
                onChange={(newValue: Dayjs | null) => {
                  setDate(newValue?.format("YYYY-MM-DD") as string);
                }}
                value={date}
                minDate={new Date() as any}
                renderInput={(params) => <TextField {...params} value={date} />}
              />
            </LocalizationProvider>
          </Box>

          <TableContainer>
            <Table
              sx={{ minWidth: 650, height: "1px" }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      backgroundColor: "#F4F6F8",
                      width: "50px",
                      borderRadius: "8px 0px 0px 8px",
                    }}
                  >
                    Time
                  </TableCell>
                  {bays.map((bay, index) => {
                    return (
                      <TableCell
                        sx={{
                          backgroundColor: "#F4F6F8",
                          px: "8px",
                          borderRadius:
                            index === bays.length - 1 ? "0px 8px 8px 0px" : "",
                        }}
                        align="center"
                      >
                        {bay.name}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoading &&
                  timeSlots.map((timeSlot, index) => (
                    <TableRow key={`row-${index}`}>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          height: "120px",
                          display: "flex",
                          alignItems: "start",
                          paddingTop: "0px !important",
                        }}
                      >
                        <span>{timeSlot.time_start.substring(0, 5)}</span>
                      </TableCell>
                      {data.length === timeSlots.length &&
                        data[index].bays.map((bay, indexBay) => {
                          if (bay.bookings.length > 0) {
                            if (
                              bay.bookings[0].start_time ===
                              data[index].start_time
                            ) {
                              let booking = bay.bookings[0].booking;
                              let bookingBay = bay.bookings[0].bookingBay;
                              return (
                                <TableCell
                                  key={`column-${indexBay}`}
                                  component="th"
                                  scope="row"
                                  rowSpan={bay.bookings.length}
                                  sx={{
                                    pt: "25px",
                                    pb: "8px",
                                    px: "8px",
                                  }}
                                >
                                  <Link
                                    to={`/simulator-bay/booking/${booking.id}`}
                                  >
                                    <Box
                                      sx={{
                                        backgroundColor: booking.professional ? "#2C7AEF" : "#EF2F2C",
                                        borderRadius: "8px",
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          textAlign: "center",
                                          width: "100%",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontWeight: "bold",
                                            color: "white",
                                          }}
                                        >
                                          Booking ID : {bookingBay.code}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontWeight: "bold",
                                            color: "white",
                                          }}
                                        >
                                          Member :{" "}
                                          {`${booking.first_name} ${booking.last_name}`}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontWeight: "bold",
                                            color: "white",
                                          }}
                                        >
                                          Phone number : {booking.tel}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontWeight: "bold",
                                            color: "white",
                                          }}
                                        >
                                          Time :{" "}
                                          {`${bookingBay.booking_start_time.substring(
                                            0,
                                            5
                                          )} - ${bookingBay.booking_end_time.substring(
                                            0,
                                            5
                                          )}`}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Link>
                                </TableCell>
                              );
                            } else {
                              return null;
                            }
                          } else {
                            let slotTimeDate = new Date(
                              `${date} ${timeSlot.time_start}`
                            );
                            let today = new Date();
                            let isExpired = false;
                            if (today >= slotTimeDate) {
                              isExpired = true;
                            }
                            return (
                              <TableCell
                                key={`column-${indexBay}`}
                                component="th"
                                scope="row"
                                sx={{
                                  pt: "25px",
                                  pb: "8px",
                                  px: "8px",
                                }}
                              >
                                {isExpired ? (
                                  <Box
                                    sx={{
                                      backgroundColor: "#D9D9D9",
                                      borderRadius: "8px",
                                      height: "104px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        textAlign: "center",
                                        width: "100%",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Time out
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Link
                                    to={`/simulator-bay/booking/create?date=${date}&start_time=${timeSlot.time_start}&bay_id=${bays[indexBay]?.id}`}
                                  >
                                    <Box
                                      sx={{
                                        backgroundColor: "#D9D9D9",
                                        borderRadius: "8px",
                                        height: "104px",
                                      }}
                                    ></Box>
                                  </Link>
                                )}
                              </TableCell>
                            );
                          }
                        })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100px",
                }}
              >
                Loading...
              </Box>
            ) : (
              <>
                {data.length <= 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100px",
                    }}
                  >
                    Not found
                  </Box>
                )}
              </>
            )}
          </TableContainer>
        </Box>
      </Paper>
    </>
  );
};

export default withAuth(withPermission(Dashboard, "MANAGE_SIMULATOR_BAY"));
