import { Navigate, createBrowserRouter } from "react-router-dom";

import ClubFittingRouters from "./clubFittingRouters";
import Dashboard from "pages/dashboard";
import Layout from "components/common/Layout";
import Login from "pages/login";
import OnlyGolfRouters from "./onlyGolfRouters";
import Settings from "pages/settings";
import SimulatorBayRouters from "./simulatorBayRouters";
import manageAdminRouters from "./manageAdminRouters";
import CobraRouters from "./cobraRouters";

const Routers = createBrowserRouter([
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/dashboard" replace />,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
      ...manageAdminRouters,
      ...OnlyGolfRouters,
      ...ClubFittingRouters,
      ...SimulatorBayRouters,
      ...CobraRouters,
    ],
  },
]);

export default Routers;
