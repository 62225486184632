import { InputAdornment, TextField, Tooltip } from "@mui/material";

import { IField } from "./Field";
import { Info } from "@mui/icons-material";

const NumberField: React.FC<IField> = ({
  form,
  name,
  label,
  type,
  disabled,
  inputOptions,
  error,
  errorMessage,
  defaultValue,
  tooltip,
}) => {
  return (
    <TextField
      key={"field-" + name}
      fullWidth
      label={inputOptions?.required ? label + " *" : label}
      type="number"
      {...form.register(name, {
        ...inputOptions,
      })}
      inputProps={{
        step: "any",
      }}
      error={error}
      helperText={errorMessage}
      defaultValue={defaultValue}
      disabled={disabled}
      InputProps={
        tooltip
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={tooltip}>
                    <Info sx={{ cursor: "pointer" }} />
                  </Tooltip>
                </InputAdornment>
              ),
            }
          : {}
      }
    />
  );
};

export default NumberField;
