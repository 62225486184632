import BookingCreate from "pages/cobra/booking/create";
import BookingDetail from "pages/cobra/booking/detail";
import BookingEdit from "pages/cobra/booking/edit";
import BookingList from "pages/cobra/booking/list";
import Dashboard from "pages/cobra/dashboard";
import ServiceCreate from "pages/cobra/service/create";
import ServiceDetail from "pages/cobra/service/detail";
import ServiceEdit from "pages/cobra/service/edit";
import ServiceList from "pages/cobra/service/list";
import ProductSerialNumberList from "pages/cobra/product-serial-number/list";
import ListMemberLog from "pages/cobra/manage-member/member-log/list";
import MemberDetail from "pages/cobra/manage-member/member/detail";
import ProductSerialNumberDetail from "pages/cobra/product-serial-number/detail";
import ProductDetail from "pages/cobra/product/detail";
import EditMember from "pages/cobra/manage-member/member/edit";
import ListMember from "pages/cobra/manage-member/member/list";
import CreatePromotion from "pages/cobra/promotion/create";
import PromotionDetail from "pages/cobra/promotion/detail";
import EditPromotion from "pages/cobra/promotion/edit";
import ListPromotion from "pages/cobra/promotion/list";
import CreateStore from "pages/cobra/store/create";
import CreateProduct from "pages/cobra/product/create";
import StoreDetail from "pages/cobra/store/detail";
import EditStore from "pages/cobra/store/edit";
import EditProduct from "pages/cobra/product/edit";
import EditProductSerialNumber from "pages/cobra/product-serial-number/edit";
import ListStore from "pages/cobra/store/list";
import { RouteObject } from "react-router-dom";
import ListContact from "pages/cobra/manage-contact/contact/list";
import ListProduct from "pages/cobra/product/list";
import ListContactType from "pages/cobra/manage-contact/contact-type/list";
import CreateContact from "pages/cobra/manage-contact/contact/create";
import EditContact from "pages/cobra/manage-contact/contact/edit";
import ContactDetail from "pages/cobra/manage-contact/contact/detail";
import ImportProductSerialNumber from "pages/cobra/product-serial-number/import";

const CobraRouters: RouteObject[] = [
  {
    path: "/calendar/cobra",
    element: <Dashboard />,
  },
  {
    path: "/member/cobra/list",
    element: <ListMember />,
  },
  {
    path: "/member/cobra/:id",
    element: <MemberDetail />,
  },
  {
    path: "/member/cobra/:id/edit",
    element: <EditMember />,
  },
  {
    path: "/member-log/cobra/list",
    element: <ListMemberLog />,
  },
  {
    path: "/contact-type/cobra/list",
    element: <ListContactType />,
  },
  {
    path: "/contact/cobra/list",
    element: <ListContact />,
  },
  {
    path: "/contact/cobra/:id",
    element: <ContactDetail />,
  },
  {
    path: "/contact/cobra/create",
    element: <CreateContact />,
  },
  {
    path: "/contact/cobra/:id/edit",
    element: <EditContact />,
  },
  {
    path: "/store/cobra/list",
    element: <ListStore />,
  },
  {
    path: "/store/cobra/:id",
    element: <StoreDetail />,
  },
  {
    path: "/store/cobra/create",
    element: <CreateStore />,
  },
  {
    path: "/store/cobra/:id/edit",
    element: <EditStore />,
  },
  {
    path: "/promotion/cobra/list",
    element: <ListPromotion />,
  },
  {
    path: "/promotion/cobra/:id",
    element: <PromotionDetail />,
  },
  {
    path: "/promotion/cobra/create",
    element: <CreatePromotion />,
  },
  {
    path: "/promotion/cobra/:id/edit",
    element: <EditPromotion />,
  },
  {
    path: "/service/cobra/list",
    element: <ServiceList />,
  },
  {
    path: "/service/cobra/create",
    element: <ServiceCreate />,
  },
  {
    path: "/service/cobra/:id/edit",
    element: <ServiceEdit />,
  },
  {
    path: "/service/cobra/:id",
    element: <ServiceDetail />,
  },
  {
    path: "/booking/cobra/list",
    element: <BookingList />,
  },
  {
    path: "/booking/cobra/create",
    element: <BookingCreate />,
  },
  {
    path: "/booking/cobra/:id/edit",
    element: <BookingEdit />,
  },
  {
    path: "/booking/cobra/:id",
    element: <BookingDetail />,
  },
  {
    path: "/product/cobra/list",
    element: <ListProduct />,
  },
  {
    path: "/product/cobra/:id",
    element: <ProductDetail />,
  },
  {
    path: "/product/cobra/create",
    element: <CreateProduct />,
  },
  {
    path: "/product/cobra/:id/edit",
    element: <EditProduct />,
  },
  {
    path: "/product-serial-number/cobra/list",
    element: <ProductSerialNumberList />,
  },
  {
    path: "/product-serial-number/cobra/:id",
    element: <ProductSerialNumberDetail />,
  },
  {
    path: "/product-serial-number/cobra/import",
    element: <ImportProductSerialNumber />,
  },
  {
    path: "/product-serial-number/cobra/:id/edit",
    element: <EditProductSerialNumber />,
  },
];

export default CobraRouters;
