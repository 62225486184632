import { Box, Paper, Step, StepLabel, Stepper } from "@mui/material";
import { useCallback, useMemo, useState } from "react";

import CCKBookingServices from "services/cckBooking";
import CCKMemberServices from "services/cckMember";
import Header from "components/common/Header";
import { ICCKMember } from "interfaces/cckMember";
import Step1 from "components/simulator-bay/booking/create/Step1";
import Step2 from "components/simulator-bay/booking/create/Step2";
import Step3 from "components/simulator-bay/booking/create/Step3";
import StepSelectBooking from "components/simulator-bay/booking/create/StepSelectBooking";
import Swal from "sweetalert2";
import TrainingStep1 from "components/simulator-bay/booking/create/TrainingStep/Step1";
import TrainingStep2 from "components/simulator-bay/booking/create/TrainingStep/Step2";
import TrainingStep3 from "components/simulator-bay/booking/create/TrainingStep/Step3";
import useAlert from "hooks/useAlert";
import { useNavigate } from "react-router-dom";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

const steps = [
  "Select booking type",
  "Input phone number",
  "Confirm member detail",
  "Booking",
];

const CreateBay = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [step, setStep] = useState<number>(3);
  const [member, setMember] = useState<ICCKMember | null>(null);

  const onSubmitStep1 = useCallback((tel: string) => {
    CCKMemberServices.getDetailByTel(token as string, tel)
      .then((res) => {
        setMember(res.data.data);
        setStep(1);
      })
      .catch((err) => {
        onAlert(
          "error",
          err.response.data?.message || "Please try again later"
        );
      });
  }, []);

  const onSubmitTrainingStep1 = useCallback((tel: string) => {
    CCKMemberServices.getDetailByTel(token as string, tel)
      .then((res) => {
        setMember(res.data.data);
        setStep(5);
      })
      .catch((err) => {
        onAlert(
          "error",
          err.response.data?.message || "Please try again later"
        );
      });
  }, []);

  const onSubmitTraining = useCallback(
    (data: {
      member_id: number;
      professional_id: number;
      booking_date: string;
      booking_bays: {
        bay_id: number;
        start_time: string;
        total_hour: number;
      }[];
    }) => {
      let isError = false;
      if (!data.booking_date) {
        isError = true;
      }
      data.booking_bays.forEach((d) => {
        if (!d.bay_id || !d.start_time || !d.total_hour) {
          isError = true;
        }
      });
      if (isError) {
        Swal.fire({
          icon: "error",
          text: "Plese enter field to complete",
        });
      } else {
        CCKBookingServices.create(token as string, data)
          .then((res) => {
            onAlert("success", "Create booking success");
            navigate(`/simulator-bay/booking`);
          })
          .catch((err) => {
            onAlert(
              "error",
              err.response.data?.message || "Please try again later"
            );
          });
      }
      console.log(data);
    },
    []
  );

  const onSubmit = useCallback(
    (data: {
      member_id: number;
      booking_date: string;
      booking_bays: {
        bay_id: number;
        start_time: string;
        total_hour: number;
      }[];
    }) => {
      let isError = false;
      if (!data.booking_date) {
        isError = true;
      }
      data.booking_bays.forEach((d) => {
        if (!d.bay_id || !d.start_time || !d.total_hour) {
          isError = true;
        }
      });
      if (isError) {
        Swal.fire({
          icon: "error",
          text: "Plese enter field to complete",
        });
      } else {
        CCKBookingServices.create(token as string, data)
          .then((res) => {
            onAlert("success", "Create booking success");
            navigate(`/simulator-bay/booking`);
          })
          .catch((err) => {
            onAlert(
              "error",
              err.response.data?.message || "Please try again later"
            );
          });
      }
      console.log(data);
    },
    []
  );

  const StepComponent = useMemo(() => {
    switch (step) {
      case 1:
        return (
          <Step2
            member={member as ICCKMember}
            step={step}
            onChangeStep={(nextStep: number) => setStep(nextStep)}
          />
        );
      case 2:
        return (
          <Step3
            member={member as ICCKMember}
            step={step}
            onChangeStep={(nextStep: number) => setStep(nextStep)}
            onSubmit={onSubmit}
          />
        );
      case 3:
        return (
          <StepSelectBooking
            onChangeStep={(nextStep: number) => setStep(nextStep)}
          />
        );
      case 4:
        return (
          <TrainingStep1
            step={step}
            onChangeStep={(nextStep: number) => setStep(nextStep)}
            onSubmitStep1={onSubmitTrainingStep1}
          />
        );
      case 5:
        return (
          <TrainingStep2
            member={member as ICCKMember}
            step={step}
            onChangeStep={(nextStep: number) => setStep(nextStep)}
          />
        );
      case 6:
        return (
          <TrainingStep3
            member={member as ICCKMember}
            step={step}
            onChangeStep={(nextStep: number) => setStep(nextStep)}
            onSubmit={onSubmitTraining}
          />
        );
      default:
        return (
          <Step1
            step={step}
            onChangeStep={(nextStep: number) => setStep(nextStep)}
            onSubmitStep1={onSubmitStep1}
          />
        );
    }
  }, [step, onSubmit, onSubmitStep1]);

  return (
    <>
      <Header
        title={step === 0 || step === 1 || step === 2 || step === 3? "Create a new booking" : "Create a new training booking"}
        breadcrumbs={[
          {
            label: "Dashboard",
            href: `/dashboard`,
          },
          {
            label: "Simulator Bay",
            href: `/simulator-bay/dashboard`,
          },
          {
            label: "Booking",
            href: "/simulator-bay/booking",
          },
          {
            label: "New booking",
          },
        ]}
      />
      <Paper>
        <Box
          sx={{
            pt: 2,
            px: 2,
          }}
        >
          <Stepper
            activeStep={
              step === 3
                ? 0
                : step === 0 || step === 4
                ? 1
                : step === 1 || step === 5
                ? 2
                : step === 6
                ? 3
                : step
            }
            alternativeLabel
          >
            {steps.map((label, index) => {
              return (
                <Step
                  key={label}
                  completed={
                    (step === 3
                      ? 0
                      : step === 0 || step === 4
                      ? 1
                      : step === 1 || step === 5
                      ? 2
                      : step === 6
                      ? 3
                      : step) > index
                  }
                >
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
        {StepComponent}
      </Paper>
    </>
  );
};

export default withAuth(withPermission(CreateBay, "MANAGE_SIMULATOR_BAY"));
