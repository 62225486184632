import { IPaginationRequest } from "interfaces/pagination";
import axios from "axios";

const BookingService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/booking`,
});

const BookingServices = {
  getDetail: (token: string, line_oa_code: string, id: string) =>
    BookingService.get(`/${line_oa_code}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getAllBooking: (token: string, line_oa_code: string) =>
    BookingService.get(`/${line_oa_code}/all`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getAll: (
    token: string,
    line_oa_code: string,
    pagination: IPaginationRequest,
    search: string,
    dateRange: {
      start: string;
      end: string;
    }
  ) =>
    BookingService.get(
      `/${line_oa_code}/list?perPage=${pagination.per_page}&page=${pagination.page}&search=${search}&startDate=${dateRange.start}&endDate=${dateRange.end}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ),
  create: (
    token: string,
    line_oa_code: string,
    data: {
      first_name: string;
      last_name: string;
      email?: string;
      tel: string;
      booking_date: string;
      services: number[];
      start_slot_time_id: number;
    }
  ) =>
    BookingService.post(`/${line_oa_code}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  cancel: (token: string, line_oa_code: string, id: string | number) =>
    BookingService.put(`/${line_oa_code}/${id}/cancel`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  edit: (
    token: string,
    line_oa_code: string,
    id: string | number,
    data: {
      first_name: string;
      last_name: string;
      tel: string;
      email?: string;
      services: number[];
    }
  ) =>
    BookingService.put(`/${line_oa_code}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  delete: (token: string, line_oa_code: string, id: string | number) =>
    BookingService.delete(`/${line_oa_code}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default BookingServices;
