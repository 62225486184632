import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import FieldList from "components/common/FieldList";
import Header from "components/common/Header";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import { ICobraContact } from "interfaces/cobraContact";
import CobraContactServices from "services/cobraContact";

const ContactDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useToken();
  const { onAlert } = useAlert();

  const [contact, setContact] = useState<ICobraContact | null>(null);

  useEffect(() => {
    const fetchData = () => {
      CobraContactServices.getDetail(token as string, id as string)
        .then((res) => {
          setContact(res.data.data);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    };

    fetchData();
  }, [id, onAlert, token]);

  if (contact) {
    return (
      <>
        <Header
          title="Contact Details"
          breadcrumbs={[
            {
              label: "Dashboard",
              href: "/dashboard",
            },
            {
              label: "Contact",
              href: `/contact/cobra/list`,
            },
            {
              label: contact.label,
            },
          ]}
        />
        <FieldList
          type="detail"
          fields={[
            {
              label: "Cover Image",
              type: "image",
              name: "image",
              defaultValue: contact.image,
              col: 6,
            },
            {
              label: "",
              type: "box",
              name: "",
              col: 6,
            },
            {
              label: "Contact Type",
              type: "text",
              name: "contact_type",
              defaultValue: contact.contact_type.name,
              col: 6,
            },
            {
              label: "",
              type: "box",
              name: "",
              col: 6,
            },
            {
              label: "Contact Label",
              type: "text",
              name: "label",
              defaultValue: contact.label,
              col: 6,
            },
            {
              label: "Contact Value",
              type: "text",
              name: "value",
              defaultValue: contact.value,
              col: 6,
            },
          ]}
          onBack={() => navigate(`/contact/list`)}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default withAuth(withPermission(ContactDetail, "MANAGE_COBRA"));
