import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";

import { IField } from "./Field";
import { Info } from "@mui/icons-material";

const SelectField: React.FC<IField> = ({
  form,
  name,
  label,
  inputOptions,
  error,
  errorMessage,
  items,
  defaultValue,
  tooltip,
  disabled,
}) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <FormControl key={"field-" + name} fullWidth error={error}>
        <InputLabel id={"label-" + name}>
          {inputOptions?.required ? label + " *" : label}
        </InputLabel>
        <Select
          disabled={disabled}
          label={inputOptions?.required ? label + " *" : label}
          labelId={"label-" + name}
          {...form.register(name, {
            ...inputOptions,
          })}
          defaultValue={defaultValue}
        >
          {items?.map((item, index) => {
            return (
              <MenuItem key={"select-item-" + item.label} value={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>{errorMessage}</FormHelperText>
      </FormControl>
      {tooltip && (
        <InputAdornment position="end">
          <Tooltip title={tooltip}>
            <Info sx={{ cursor: "pointer" }} />
          </Tooltip>
        </InputAdornment>
      )}
    </Box>
  );
};

export default SelectField;
