import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import FieldList from "components/common/FieldList";
import Header from "components/common/Header";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import { ICobraContact } from "interfaces/cobraContact";
import { ICobraContactType } from "interfaces/cobraContactType";
import CobraContactServices, { TCreateContactData } from "services/cobraContact";
import FileServices from "services/file";
import CobraContactTypeServices from "services/cobraContactType";

type TContactReward = {
  label: string;
  value: string;
  image: FileList | undefined;
  contact_type_id: string;
};

const EditContact = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useToken();
  const { onAlert } = useAlert();

  const [contactTypes, setContactTypes] = useState<
    | {
        label: string;
        value: number;
      }[]
    | null
  >(null);
  const [contact, setContact] = useState<ICobraContact | null>(null);

  useEffect(() => {
    const fetchData = () => {
      CobraContactTypeServices.getAll(token as string, {
        page: 1,
        per_page: 100,
      })
        .then((res) => {
          setContactTypes(
            res.data.data.map((contactType: ICobraContactType) => {
              return {
                label: contactType.name,
                value: contactType.id,
              };
            })
          );
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
        CobraContactServices.getDetail(token as string, id as string)
        .then((res) => {
          setContact(res.data.data);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    };

    fetchData();
  }, [id, onAlert, token]);

  const onSubmit = async (values: TContactReward) => {
    let data: TCreateContactData = {
      label: values.label,
      value: values.value,
      image: null,
      contact_type_id: parseInt(values.contact_type_id),
    };
    let isError = false;
    if (values.image && values.image[0]) {
      let formData = new FormData();
      formData.append("file", values.image[0]);
      formData.append("prefix", "contact");
      formData.append("path", "/contact");
      await FileServices.create(token as string, formData)
        .then((res) => {
          data.image = res.data.data;
        })
        .catch((err) => {
          isError = true;
          onAlert("error", err.response.data.message);
        });
    }
    if (!isError) {
      CobraContactServices.edit(token as string, id as string, data)
        .then((res) => {
          onAlert("success", "แก้ไข Contact สำเร็จ");
          navigate(`/contact/cobra/list`);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    }
  };

  if (contactTypes && contact) {
    return (
      <>
        <Header
          title="Edit Contact"
          breadcrumbs={[
            {
              label: "Dashboard",
              href: "/dashboard",
            },
            {
              label: "Contact",
              href: `/contact/cobra/list`,
            },
            {
              label: contact.label,
            },
          ]}
        />
        <FieldList
          type="edit"
          onSubmit={onSubmit}
          fields={[
            {
              label: "Cover Image (1:1)",
              type: "image",
              name: "image",
              defaultValue: contact.image,
              col: 6,
              tooltip: "สำหรับกำหนดข้อมูลรูปภาพแสดงผลของ Contact",
            },
            {
              label: "",
              type: "box",
              name: "",
              col: 6,
            },
            {
              label: "Contact Type",
              type: "select",
              name: "contact_type_id",
              inputOptions: {
                required: "กรุณาเลือกประเภทของ Contact",
              },
              defaultValue: contact.contact_type.id,
              col: 6,
              items: contactTypes,
              tooltip: "ตัวเลือกสำหรับกำหนดประเภทของ Contact",
            },
            {
              label: "",
              type: "box",
              name: "",
              col: 6,
            },
            {
              label: "Contact Label",
              type: "text",
              name: "label",
              inputOptions: {
                required: "กรุณากรอ Label",
              },
              defaultValue: contact.label,
              col: 6,
              tooltip: "สำหรับกำหนดข้อมูล Contact Label",
            },
            {
              label: "Contact Value",
              type: "text",
              name: "value",
              inputOptions: {
                required: "กรุณากรอกคำนำหน้าชื่อ",
              },
              defaultValue: contact.value,
              col: 6,
              tooltip: "สำหรับกำหนด Contact Value",
            },
          ]}
          onBack={() => navigate(`/contact/list`)}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default withAuth(withPermission(EditContact, "MANAGE_COBRA"));
