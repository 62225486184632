import { Box, Button, Chip } from "@mui/material";
import { IPagination, IPaginationRequest } from "interfaces/pagination";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import CCKBookingServices from "services/cckBooking";
import { Close } from "@mui/icons-material";
import Header from "components/common/Header";
import { ICCKBooking } from "interfaces/cckBooking";
import { ICCKProfessional } from "interfaces/cckProfessional";
import Swal from "sweetalert2";
import Table from "components/common/Table";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

interface ICCKBookingData {
  id: number;
  code: string;
  name: string;
  tel: string;
  email: string;
  booking_date: string;
  booking_start_time: string;
  booking_time: string;
  credit_consume: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  created_by: string;
  updated_by: string;
  number_of_bay: number;
  professional: ICCKProfessional | null;
}

const ListBooking = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [search, setSearch] = useState<string>("");
  const [filter, setFilter] = useState<{
    date: {
      start: Date | null;
      end: Date | null;
    };
    enter_date: {
      start: Date | null;
      end: Date | null;
    };
  }>({
    date: { start: new Date(), end: new Date() },
    enter_date: { start: null, end: null },
  });
  const [data, setData] = useState<{
    data: ICCKBookingData[];
    pagination: IPagination;
  }>({
    data: [],
    pagination: {
      page: 1,
      total_item: 0,
      total_page: 0,
    },
  });
  const [pagination, setPagination] = useState<IPaginationRequest>({
    page: 1,
    per_page: 10,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchData = useCallback(() => {
    CCKBookingServices.getAll(
      token as string,
      {
        per_page: pagination.per_page,
        page: pagination.page,
      },
      search,
      {
        start: filter.date.start
          ? dayjs(filter.date.start.toString()).format("YYYY-MM-DD")
          : "",
        end: filter.date.end
          ? dayjs(filter.date.end.toString()).format("YYYY-MM-DD")
          : "",
      },
      {
        start: filter.enter_date.start
          ? dayjs(filter.enter_date.start.toString()).format("YYYY-MM-DD")
          : "",
        end: filter.enter_date.end
          ? dayjs(filter.enter_date.end.toString()).format("YYYY-MM-DD")
          : "",
      }
    )
      .then((res) => {
        let paginationRes: IPagination = res.data.pagination;
        setData({
          data: res.data.data.map((d: ICCKBooking) => {
            let bookingBaysAvailable = d.booking_bays.filter(
              (b) => b.deleted_at === null
            );
            let totalHour = 0;
            bookingBaysAvailable.forEach((bookingBayAvailable) => {
              let startDate = new Date(
                `${d.booking_date} ${bookingBayAvailable.booking_start_time}`
              );
              let endDate = new Date(
                `${d.booking_date} ${bookingBayAvailable.booking_end_time}`
              );
              totalHour += (endDate.getTime() - startDate.getTime()) / 3600000;
            });
            let value: ICCKBookingData = {
              id: d.id,
              code: d.code,
              number_of_bay: d.booking_bays.filter((b) => b.deleted_at === null)
                .length,
              name: d.first_name + " " + d.last_name,
              tel: d.tel,
              email: d.email,
              booking_date: d.booking_date,
              booking_start_time: d.booking_start_time,
              booking_time:
                d.booking_start_time.substring(0, 5) +
                " - " +
                d.booking_end_time.substring(0, 5),
              credit_consume: totalHour,
              created_at: d.created_at,
              updated_at: d.updated_at,
              deleted_at: d.deleted_at,
              created_by: d.admin_created ? d.admin_created.name : "",
              updated_by: d.admin_edited ? d.admin_edited.name : "",
              professional: d.professional,
            };
            return value;
          }),
          pagination: {
            page: paginationRes.page,
            total_item: paginationRes.total_item,
            total_page: paginationRes.total_page,
          },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
        setIsLoading(false);
      });
  }, [pagination, onAlert, token, search, filter]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onCancel = (id: number) => {
    Swal.fire({
      icon: "warning",
      html: `Confirm to cancel this booking?`,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        CCKBookingServices.delete(token as string, id)
          .then((res) => {
            fetchData();
            onAlert("success", "Cancel booking success");
            setIsLoading(false);
          })
          .catch((err) => {
            onAlert("error", err.response.data.message);
            setIsLoading(false);
          });
      }
    });
  };

  const onExport = useCallback(() => {
    CCKBookingServices.export(
      token as string,
      search,
      {
        start: filter.date.start
          ? dayjs(filter.date.start.toString()).format("YYYY-MM-DD")
          : "",
        end: filter.date.end
          ? dayjs(filter.date.end.toString()).format("YYYY-MM-DD")
          : "",
      },
      {
        start: filter.enter_date.start
          ? dayjs(filter.enter_date.start.toString()).format("YYYY-MM-DD")
          : "",
        end: filter.enter_date.end
          ? dayjs(filter.enter_date.end.toString()).format("YYYY-MM-DD")
          : "",
      }
    )
      .then((res) => {
        window.location.href = process.env.REACT_APP_FILE + res.data.data;
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
      });
  }, [token, search, filter, onAlert]);

  return (
    <>
      <Header
        title="Booking"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: `/dashboard`,
          },
          {
            label: "Simulator Bay",
            href: `/simulator-bay/dashboard`,
          },
          {
            label: "Booking",
            href: "/simulator-bay/booking/list",
          },
          {
            label: "List",
          },
        ]}
        actions={[
          <Link to={`/simulator-bay/booking/create`}>
            <Button variant="contained" size="large">
              <AddIcon
                sx={{
                  fontSize: 14,
                  mr: 1,
                }}
              />
              <Typography>Create</Typography>
            </Button>
          </Link>,
        ]}
      />
      <Table
        headers={[
          {
            key: "code",
            label: "Booking ID",
            render: (value, index, item) => (
              <Box
                sx={{
                  color: "white",
                  backgroundColor: item.professional ? "#2C7AEF" : "#EF2F2C",
                  borderRadius: "8px",
                  p: 1,
                  fontWeight: 700,
                }}
              >
                {value}
              </Box>
            ),
          },
          {
            key: "name",
            label: "Member",
            render: (value, index, item) => (
              <Box>
                <Box>
                  <Typography fontWeight="bold">Name: </Typography>
                  <Typography>{item.name}</Typography>
                </Box>
                <Box>
                  <Typography fontWeight="bold">Phone: </Typography>
                  <Typography>{item.tel}</Typography>
                </Box>
                <Box>
                  <Typography fontWeight="bold">E-mail:</Typography>
                  <Typography>{item.email}</Typography>
                </Box>
              </Box>
            ),
          },
          {
            key: "booking_date",
            label: "Booking details",
            render: (value, index, item) => (
              <Box>
                <Box>
                  <Typography fontWeight="bold" component="span">
                    Date:{" "}
                  </Typography>
                  <Typography component="span">
                    {dayjs(new Date(value)).format("DD/MM/YYYY")}
                  </Typography>
                </Box>
                <Box>
                  <Typography fontWeight="bold" component="span">
                    Time:{" "}
                  </Typography>
                  <Typography component="span">{item.booking_time}</Typography>
                </Box>
                <Box>
                  <Typography fontWeight="bold" component="span">
                    Total bay:{" "}
                  </Typography>
                  <Typography component="span">{item.number_of_bay}</Typography>
                </Box>
                <Box>
                  <Typography fontWeight="bold" component="span">
                    Credit consume:{" "}
                  </Typography>
                  <Typography component="span">
                    {item.credit_consume}
                  </Typography>
                </Box>
              </Box>
            ),
          },
          {
            key: "professional",
            label: "Professional",
            render: (value: ICCKProfessional | null) => {
              return (
                <Typography>
                  {value ? `${value.first_name} ${value.last_name}` : "-"}
                </Typography>
              );
            },
          },
          {
            key: "created_at",
            label: "Enter date",
            render: (value) => {
              return (
                <Typography>
                  {dayjs(new Date(value)).format("DD/MM/YYYY")}
                </Typography>
              );
            },
          },
          {
            key: "deleted_at",
            label: "Status",
            render: (value, index, item: ICCKBookingData) => {
              let expiredDate = new Date(
                `${item.booking_date} ${item.booking_start_time}`
              );
              let today = new Date();
              let isExpired = false;
              if (today >= expiredDate) {
                isExpired = true;
              }
              let isCanceled = !!item.deleted_at;
              return (
                <Chip
                  label={
                    isCanceled ? "Canceled" : isExpired ? "Done" : "Active"
                  }
                  color={isCanceled ? "error" : isExpired ? "info" : "success"}
                />
              );
            },
          },
          {
            key: "created_by",
            label: "Channel",
            render: (value, index, member) => {
              return (
                <Chip
                  variant="outlined"
                  label={!value ? "LINE OA" : "Web Admin"}
                  color={!value ? "success" : "error"}
                />
              );
            },
          },
        ]}
        actions={{
          onDetail: (item: any) =>
            navigate(`/simulator-bay/booking/${item.id}`),
          others: [
            {
              icon(item, index) {
                return <Close />;
              },
              label(item, index) {
                return "Cancel";
              },
              onClick(item, index) {
                onCancel(item.id);
              },
              hidden(item: ICCKBookingData, index) {
                let expiredDate = new Date(
                  `${item.booking_date} ${item.booking_start_time}`
                );
                let today = new Date();
                let isExpired = false;
                if (today >= expiredDate) {
                  isExpired = true;
                }
                let isCanceled = !!item.deleted_at;
                return isExpired || isCanceled;
              },
            },
          ],
        }}
        data={data.data}
        isLoading={isLoading}
        onSearch={(searchText) => setSearch(searchText)}
        filters={[
          {
            type: "daterange",
            name: "Enter at",
            value: filter.enter_date,
            onChange: (value: any) =>
              setFilter((filter) => {
                return { ...filter, enter_date: value };
              }),
          },
          {
            type: "daterange",
            name: "Created at",
            value: filter.date,
            onChange: (value: any) =>
              setFilter((filter) => {
                return { ...filter, date: value };
              }),
          },
        ]}
        onExport={onExport}
        pagination={
          data.pagination
            ? {
                ...data.pagination,
                onChange: (page) =>
                  setPagination((pagination) => {
                    return {
                      ...pagination,
                      page,
                    };
                  }),
              }
            : undefined
        }
      />
    </>
  );
};

export default withAuth(withPermission(ListBooking, "MANAGE_SIMULATOR_BAY"));
