import BookingCreate from "pages/club-fitting/booking/create";
import BookingDetail from "pages/club-fitting/booking/detail";
import BookingEdit from "pages/club-fitting/booking/edit";
import BookingList from "pages/club-fitting/booking/list";
import Dashboard from "pages/club-fitting/dashboard";
import { RouteObject } from "react-router-dom";
import ServiceCreate from "pages/club-fitting/service/create";
import ServiceDetail from "pages/club-fitting/service/detail";
import ServiceEdit from "pages/club-fitting/service/edit";
import ServiceList from "pages/club-fitting/service/list";

const ClubFittingRouters: RouteObject[] = [
  {
    path: "/calendar/club-fitting",
    element: <Dashboard />,
  },
  {
    path: "/service/club-fitting/list",
    element: <ServiceList />,
  },
  {
    path: "/service/club-fitting/create",
    element: <ServiceCreate />,
  },
  {
    path: "/service/club-fitting/:id/edit",
    element: <ServiceEdit />,
  },
  {
    path: "/service/club-fitting/:id",
    element: <ServiceDetail />,
  },
  {
    path: "/booking/club-fitting/list",
    element: <BookingList />,
  },
  {
    path: "/booking/club-fitting/create",
    element: <BookingCreate />,
  },
  {
    path: "/booking/club-fitting/:id/edit",
    element: <BookingEdit />,
  },
  {
    path: "/booking/club-fitting/:id",
    element: <BookingDetail />,
  },
];

export default ClubFittingRouters;
