import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import FieldList from "components/common/FieldList";
import Header from "components/common/Header";
import { IReward } from "interfaces/reward";
import RewardServices from "services/reward";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

const RewardDetail = () => {
  let line_oa_code = "ONLY_GOLF";
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useToken();
  const { onAlert } = useAlert();

  const [reward, setReward] = useState<IReward | null>(null);

  useEffect(() => {
    const fetchData = () => {
      RewardServices.getDetail(
        token as string,
        line_oa_code as string,
        id as string
      )
        .then((res) => {
          setReward(res.data.data);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    };

    fetchData();
  }, [id, line_oa_code, onAlert, token]);

  if (reward) {
    return (
      <>
        <Header
          title="Reward Details"
          breadcrumbs={[
            {
              label: "Dashboard",
              href: `/dashboard`,
            },
            {
              label: "Only Golf",
              href: `/dashboard/only-golf`,
            },
            {
              label: "Reward",
              href: `/reward/only-golf/list`,
            },
            {
              label: `Cashback ${reward.cashback}`,
            },
          ]}
        />
        <FieldList
          type="detail"
          fields={[
            {
              label: "Cover Image (Available)",
              type: "image",
              name: "image",
              defaultValue: reward.image,
              col: 6,
            },
            {
              label: "Cover Image (Disable)",
              type: "image",
              name: "image_disable",
              defaultValue: reward.image_disable,
              col: 6,
            },
            {
              label: "Spending",
              type: "text",
              name: "spend",
              inputOptions: {
                required: "กรุณากรอกยอดสั่งซื้อสะสมที่ต้องใช้",
              },
              defaultValue: reward.spend,
              col: 6,
            },
            {
              label: "Cashback",
              type: "text",
              name: "cashback",
              inputOptions: {
                required: "กรุณากรอกเงินรางวัล",
              },
              defaultValue: reward.cashback,
              col: 6,
            },
          ]}
          onBack={() => navigate(`/reward/only-golf/list`)}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default withAuth(withPermission(RewardDetail, "MANAGE_ONLY_GOLF"));
