import {
  Box,
  Button,
  InputAdornment,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { ILineOA } from "interfaces/lineOA";
import { Info } from "@mui/icons-material";
import LineOAServices from "services/lineOA";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

const CouponConfig = () => {
  let line_oa_code = "ONLY_GOLF";
  const navigate = useNavigate();
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [lineOA, setLineOA] = useState<ILineOA | null>(null);
  const [error, setError] = useState<string>("");
  const [errorPointMultiplier, setErrorPointMultiplier] = useState<string>("");

  useEffect(() => {
    LineOAServices.getDetail(token as string, line_oa_code)
      .then((res) => {
        setLineOA(res.data.data);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
      });
  }, []);

  useEffect(() => {
    if (lineOA && lineOA.spend_per_point > 0) {
      setError("");
    }
  }, [lineOA]);

  const onSubmit = () => {
    let isError = false;
    if (lineOA?.is_used_point) {
      if (lineOA.spend_per_point === 0) {
        isError = true;
        setError("กรุณากรอกยอดสั่งซื้อขั้นต่ำเพื่อทำการสะสมเป็นคะแนน");
      }
      if (lineOA.point_multiplier === 0) {
        isError = true;
        setErrorPointMultiplier("กรุณากรอกตัวคูณคะแนน");
      }
    }
    if (!isError) {
      LineOAServices.edit(token as string, line_oa_code, {
        is_used_point: lineOA?.is_used_point as boolean,
        spend_per_point: lineOA?.spend_per_point as number,
        point_multiplier: lineOA?.point_multiplier as number,
      })
        .then(() => {
          onAlert("success", "ตั้งค่าคูปองสำเร็จ");
          navigate(`/coupon/only-golf/list`);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    }
  };

  if (lineOA) {
    return (
      <>
        <Typography fontWeight="bold" fontSize={24} sx={{ mb: 2 }}>
          Configuration
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography sx={{ mr: 1 }} fontWeight="bold">
            Collect Coupon Point{" "}
          </Typography>
          <Tooltip title="สำหรับกำหนดให้ระบบเริ่มเก็บสะสมคะแนนคูปอง โดยอ้างอิงจากยอดสั่งซื้อของลูกค้า (เปิด = ระบบเริ่มเก็บสะสมตามเงื่อนไขที่กำหนด / ปิด = ระบบไม่ทำการเก็บสะสมคะแนนของลูกค้า)">
            <Info
              sx={{
                // color: "#757575",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Switch
            defaultChecked={lineOA.is_used_point}
            onChange={(e) =>
              setLineOA({
                ...lineOA,
                is_used_point: e.target.checked,
                spend_per_point: 0,
              })
            }
          />
        </Box>
        <Box>
          <TextField
            label={"Spend *"}
            type="number"
            inputProps={{
              step: "any",
              min: 0,
            }}
            sx={{ mb: 2, width: "320px" }}
            error={!!error}
            helperText={error}
            value={lineOA.spend_per_point}
            defaultValue={lineOA.spend_per_point}
            disabled={!lineOA.is_used_point}
            onChange={(e) =>
              setLineOA({
                ...lineOA,
                spend_per_point: parseInt(e.target.value),
              })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={
                      "สำหรับกำหนดยอดสั่งซื้อขั้นต่ำเพื่อทำการสะสมเป็นคะแนนคูปอง 1 คะแนน เช่น ทุกๆการสั่งซื้อ 25 บาท จะแปลงเป็นคะแนนคูปอง 1 คะแนน"
                    }
                  >
                    <Info sx={{ cursor: "pointer" }} />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          <TextField
            label={"Point Multiplier *"}
            type="number"
            inputProps={{
              step: "any",
              min: 1,
            }}
            sx={{ mb: 2, width: "320px" }}
            error={!!errorPointMultiplier}
            helperText={errorPointMultiplier}
            value={lineOA.point_multiplier}
            defaultValue={lineOA.point_multiplier}
            disabled={!lineOA.is_used_point}
            onChange={(e) =>
              setLineOA({
                ...lineOA,
                point_multiplier: parseInt(e.target.value),
              })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={
                      "สำหรับกำหนดตัวคูณแต้ม เช่น ตัวคูณแต้มเท่ากับ 2 ทุกๆการสั่งซื้อ 25 บาท จะแปลงเป็นคะแนนคูปอง 1 คะแนน คะแนนคูปองที่ได้รับก็จะเป็น 2 คะแนน"
                    }
                  >
                    <Info sx={{ cursor: "pointer" }} />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Link to={"/coupon/only-golf/list"}>
            <Button variant="contained" type="button" color="error">
              กลับ
            </Button>
          </Link>
          <Button
            variant="contained"
            type="button"
            color="success"
            onClick={onSubmit}
          >
            แก้ไข
          </Button>
        </Box>
      </>
    );
  } else {
    return <></>;
  }
};

export default withAuth(withPermission(CouponConfig, "MANAGE_ONLY_GOLF"));
