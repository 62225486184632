import { Box, Chip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import CCKBayServices from "services/cckBay";
import CCKBookingServices from "services/cckBooking";
import { Close } from "@mui/icons-material";
import FieldList from "components/common/FieldList";
import Header from "components/common/Header";
import { ICCKBay } from "interfaces/cckBay";
import { ICCKBooking } from "interfaces/cckBooking";
import { ICCKBookingBay } from "interfaces/cckBookingBay";
import Swal from "sweetalert2";
import Table from "components/common/Table/Table";
import dayjs from "dayjs";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

const BookingDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useToken();
  const { onAlert } = useAlert();

  const [booking, setBooking] = useState<ICCKBooking | null>(null);

  useEffect(() => {
    fetchData();
  }, [id, token]);

  const fetchData = () => {
    CCKBookingServices.getDetail(token as string, id as string)
      .then((res) => {
        setBooking(res.data.data);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
      });
  };

  const onCancel = (booking_bay_id: number) => {
    Swal.fire({
      icon: "warning",
      html: `Confirm to cancel this booking bay?`,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        CCKBookingServices.cancelBookingBay(
          token as string,
          id as string,
          booking_bay_id
        )
          .then((res) => {
            fetchData();
            onAlert("success", "Cancel booking bay success");
          })
          .catch((err) => {
            onAlert("error", err.response.data.message);
          });
      }
    });
  };

  return (
    <>
      <Header
        title="Booking Details"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: `/dashboard`,
          },
          {
            label: "Simulator Bay",
            href: `/simulator-bay/dashboard`,
          },
          {
            label: "Booking",
            href: `/simulator-bay/booking`,
          },
          {
            label: booking ? booking.code : "",
          },
        ]}
      />
      {booking ? (
        <>
          <Box>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "bold",
                mb: 2,
              }}
            >
              Booking items
            </Typography>
            <Table
              headers={[
                {
                  key: "code",
                  label: "Booking item ID",
                },
                {
                  key: "bay",
                  label: "Bay number",
                  render: (value, index, item) => {
                    return <Typography>{value.bay_number}</Typography>;
                  },
                },
                {
                  key: "code",
                  label: "Expired At",
                  render: (value) => (
                    <Typography>
                      {dayjs(booking.booking_date)
                        .add(543, "year")
                        .format("DD/MM/YYYY")}
                    </Typography>
                  ),
                },
                {
                  key: "booking_start_time",
                  label: "Booking Time",
                  render: (value, index, item) => (
                    <Typography>{`${item.booking_start_time.substring(
                      0,
                      5
                    )} - ${item.booking_end_time.substring(0, 5)}`}</Typography>
                  ),
                },
                {
                  key: "deleted_at",
                  label: "Status",
                  render: (value, index, item: ICCKBookingBay) => {
                    let expiredDate = new Date(
                      `${booking.booking_date} ${item.booking_start_time}`
                    );
                    let today = new Date();
                    let isExpired = false;
                    let isCanceled = !!item.deleted_at;
                    if (today >= expiredDate) {
                      isExpired = true;
                    }
                    return (
                      <>
                        <Chip
                          label={
                            isCanceled
                              ? "Canceled"
                              : isExpired
                              ? "Done"
                              : "Active"
                          }
                          color={
                            isCanceled
                              ? "error"
                              : isExpired
                              ? "info"
                              : "success"
                          }
                        />
                      </>
                    );
                  },
                },
              ]}
              actions={{
                onDetail: (item: any) =>
                  navigate(`/simulator-bay/booking-bay/${item.id}`),
                others: [
                  {
                    icon(item, index) {
                      return <Close />;
                    },
                    label(item, index) {
                      return "Cancel";
                    },
                    onClick(item, index) {
                      onCancel(item.id);
                    },
                    hidden(item: ICCKBookingBay, index) {
                      let expiredDate = new Date(
                        `${booking.booking_date} ${item.booking_start_time}`
                      );
                      let today = new Date();
                      let isExpired = false;
                      let isCanceled = !!item.deleted_at;
                      if (today >= expiredDate) {
                        isExpired = true;
                      }
                      return isExpired || isCanceled;
                    },
                  },
                ],
              }}
              data={booking.booking_bays}
              // isLoading={memberPointLoading}
            />
          </Box>
          <Box
            sx={{
              my: 4,
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "bold",
                mb: 2,
              }}
            >
              Booking Details
            </Typography>
            <FieldList
              type="detail"
              fields={[
                {
                  label: "First name",
                  type: "text",
                  name: "first_name",
                  defaultValue: booking?.first_name,
                  col: 6,
                },
                {
                  label: "Last name",
                  type: "text",
                  name: "last_name",
                  defaultValue: booking?.last_name,
                  col: 6,
                },
                {
                  label: "Phone number",
                  type: "text",
                  name: "tel",
                  defaultValue: booking?.tel,
                  col: 6,
                },
                {
                  label: "Email",
                  type: "text",
                  name: "email",
                  defaultValue: booking?.email,
                  col: 6,
                },
              ]}
              onBack={() => navigate(`/simulator-bay/booking`)}
            />
          </Box>
        </>
      ) : (
        <Typography sx={{ textAlign: "center" }}>Loading...</Typography>
      )}
    </>
  );
};

export default withAuth(withPermission(BookingDetail, "MANAGE_SIMULATOR_BAY"));
