import { IPaginationRequest } from "interfaces/pagination";
import axios from "axios";

const CouponRedemptionService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/coupon-redemption`,
});

const CouponRedemptionServices = {
  getAll: (
    token: string,
    line_oa_code: string,
    pagination: IPaginationRequest,
    search: string,
    dateRange: {
      start: string;
      end: string;
    }
  ) =>
    CouponRedemptionService.get(
      `/${line_oa_code}/list?perPage=${pagination.per_page}&page=${pagination.page}&search=${search}&startDate=${dateRange.start}&endDate=${dateRange.end}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ),
  export: (
    token: string,
    line_oa_code: string,
    pagination: IPaginationRequest,
    search: string,
    dateRange: {
      start: string;
      end: string;
    }
  ) =>
    CouponRedemptionService.get(
      `/${line_oa_code}/export?perPage=${pagination.per_page}&page=${pagination.page}&search=${search}&startDate=${dateRange.start}&endDate=${dateRange.end}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ),
};

export default CouponRedemptionServices;
