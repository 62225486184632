import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup as RadioGroupMUI,
  Tooltip,
} from "@mui/material";

import { IField } from "./Field";
import { Info } from "@mui/icons-material";

const RadioGroup: React.FC<IField> = ({
  form,
  name,
  label,
  inputOptions,
  error,
  errorMessage,
  items,
  defaultValue,
  tooltip,
  disabled,
}) => {
  return (
    <FormControl key={"field-" + name} fullWidth error={error}>
      <Box
        sx={{
          border: "1px solid #e8ecee",
          borderColor: disabled ? "#bdbdbd" : "",
          borderRadius: "8px",
          padding: "12px",
          paddingRight: "44px",
        }}
      >
        <FormHelperText
          sx={{
            position: "absolute",
            fontSize: 12,
            left: 9,
            top: -12,
            px: 0.5,
            bgcolor: "white",
            mx: 0,
          }}
        >
          {inputOptions?.required ? label + " *" : label}
        </FormHelperText>
        {tooltip && (
          <Tooltip title={tooltip}>
            <Info
              sx={{
                color: "#757575",
                position: "absolute",
                right: 14,
                top: 15,
                cursor: "pointer",
              }}
            />
          </Tooltip>
        )}
        <RadioGroupMUI
          aria-labelledby={`radio-group-${name}`}
          defaultValue={defaultValue}
        >
          {items?.map((item) => {
            return (
              <FormControlLabel
                key={item.value}
                value={item.value}
                control={
                  <Radio
                    disabled={disabled}
                    {...form.register(name, {
                      ...inputOptions,
                    })}
                  />
                }
                label={item.label}
              />
            );
          })}
        </RadioGroupMUI>
      </Box>
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  );
};

export default RadioGroup;
