import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import FieldList from "components/common/FieldList";
import Header from "components/common/Header";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import { IMember } from "interfaces/member";
import CobraMemberServices from "services/cobraMember";

type TEditMember = {
  first_name: string;
  last_name: string;
  gender: string;
  birth_date: string;
  email: string;
  tel: string;
};

const EditDetail = () => {
  let line_oa_code = "ONLY_GOLF";
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useToken();
  const { onAlert } = useAlert();

  const [member, serMember] = useState<IMember | null>(null);

  useEffect(() => {
    const fetchData = () => {
      CobraMemberServices.getDetail(
        token as string,
        id as string
      )
        .then((res) => {
          serMember(res.data.data);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    };

    fetchData();
  }, [id, line_oa_code, onAlert, token]);

  const onSubmit = (data: any) => {
    let dataProps: TEditMember = { ...data };
    CobraMemberServices.edit(
      token as string,
      id as string,
      dataProps
    )
      .then((res) => {
        onAlert("success", "แก้ไข Member สำเร็จ");
        navigate(`/member/cobra/list`);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
      });
  };

  if (member) {
    return (
      <>
        <Header
          title="Edit Member"
          breadcrumbs={[
            {
              label: "Dashboard",
              href: `/dashboard`,
            },
            {
              label: "Cobra",
              href: `/dashboard/cobra`,
            },
            {
              label: "Member",
              href: `/member/cobra/list`,
            },
            {
              label: `${member.first_name} ${member.last_name}`,
            },
          ]}
        />
        <FieldList
          type="edit"
          onSubmit={onSubmit}
          fields={[
            {
              label: "First Name",
              type: "text",
              name: "first_name",
              defaultValue: member?.first_name,
              col: 6,
              tooltip: "สำหรับแก้ไขข้อมูลชื่อของลูกค้า",
            },
            {
              label: "Last Name",
              type: "text",
              name: "last_name",
              defaultValue: member?.last_name,
              col: 6,
              tooltip: "สำหรับแก้ไขข้อมูลนามสกุลของลูกค้า",
            },
            {
              label: "Email",
              type: "text",
              name: "email",
              defaultValue: member?.email,
              col: 6,
              tooltip: "สำหรับแก้ไขข้อมูลอีเมลของลูกค้า",
            },
            {
              label: "Phone Number",
              type: "text",
              name: "tel",
              defaultValue: member?.tel,
              inputOptions: {
                required: "กรุณากรอกเบอร์โทรศัพท์",
              },
              col: 6,
              tooltip: "สำหรับแก้ไขข้อมูลหมายเลขโทรศัพท์ของลูกค้า",
            },
            {
              label: "Gender",
              type: "select",
              name: "gender",
              defaultValue: member?.gender,
              inputOptions: {
                required: "กรุณาเลือกเพศ",
              },
              items: [
                {
                  label: "Male",
                  value: "M",
                },
                {
                  label: "Female",
                  value: "F",
                },
                {
                  label: "Other",
                  value: "O",
                },
              ],
              col: 6,
              tooltip: "สำหรับแก้ไขข้อมูลเพศของลูกค้า",
            },
            {
              label: "Birth Date",
              type: "date",
              name: "birth_date",
              defaultValue: member?.birth_date,
              inputOptions: {
                required: "กรุณาเลือกวันเกิด",
              },
              col: 6,
              tooltip: "สำหรับแก้ไขข้อมูลวัน/เดือน/ปีเกิดของลูกค้า",
            },
          ]}
          onBack={() => navigate(`/member/cobra/list`)}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default withAuth(withPermission(EditDetail, "MANAGE_COBRA"));
