import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
  Box,
  FormControl,
  FormHelperText,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import { useEffect, useState } from "react";

import { Editor } from "react-draft-wysiwyg";
import { IField } from "./Field";
import { Info } from "@mui/icons-material";
import draftToHtml from "draftjs-to-html";

const TextEditor: React.FC<IField> = ({
  form,
  name,
  label,
  type,
  disabled,
  inputOptions,
  error,
  errorMessage,
  defaultValue,
  tooltip,
  maxLength,
}) => {
  const [content, setContent] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const [isReady, setIsReady] = useState<boolean>(false);

  const onEnterComment = (editorState: EditorState) => {
    setContent(editorState);
  };

  useEffect(() => {
    if (!defaultValue) {
      setContent(EditorState.createEmpty());
    } else {
      setContent(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(defaultValue).contentBlocks
          )
        )
      );
    }
    setIsReady(true);
  }, [defaultValue]);

  useEffect(() => {
    if (isReady) {
      let htmlText = draftToHtml(convertToRaw(content.getCurrentContent()));
      let text = htmlText.replace(/(<([^>]+)>)/gi, "").replace(/[\r\n]/gm, "");
      if (!maxLength || (maxLength && text.length <= maxLength)) {
        form.setValue(name + "_clone", htmlText);
        form.setValue(name, text);
      }
    }
  }, [content]);

  useEffect(() => {
    form.clearErrors(name);
  }, [content]);

  return (
    <FormControl key={"field-" + name} fullWidth error={error}>
      <Editor
        toolbarHidden
        stripPastedStyles
        editorState={content}
        onEditorStateChange={onEnterComment}
        editorClassName={`${
          disabled
            ? "text-editor-css-disable"
            : `text-editor-css ${error ? "error" : ""}`
        } `}
        readOnly={disabled}
      />
      <input
        {...form.register(name, {
          ...inputOptions,
        })}
        hidden
      />
      <FormHelperText
        sx={{
          position: "absolute",
          fontSize: 12,
          left: 9,
          top: -12,
          px: 0.5,
          bgcolor: "white",
          mx: 0,
        }}
      >
        {inputOptions?.required ? label + " *" : label}
      </FormHelperText>
      {tooltip && (
        <Tooltip title={tooltip}>
          <Info
            sx={{
              color: "#757575",
              position: "absolute",
              right: 14,
              top: 15,
              cursor: "pointer",
            }}
          />
        </Tooltip>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <FormHelperText>{errorMessage}</FormHelperText>
        {maxLength && (
          <Typography color={"#757575"}>
            <>
              {(form.watch(name) &&
                form
                  .watch(name)
                  .replace(/(<([^>]+)>)/gi, "")
                  .replace(/[\r\n]/gm, "").length) ||
                0}
              /{maxLength}
            </>
          </Typography>
        )}
      </Box>
    </FormControl>
  );
};

export default TextEditor;
