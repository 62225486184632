import { Box, Divider, Grid, Paper } from "@mui/material";
import { UseFormWatch, useForm } from "react-hook-form";

import Field from "components/common/FieldList/Field";
import Footer from "components/common/FieldList/Footer";
import Swal from "sweetalert2";
import { useEffect } from "react";

type TField = {
  label: string;
  type: string;
  name: string;
  defaultValue?: any;
  disabled?: boolean;
  inputOptions?: any;
  maxLength?: number;
  pattern?: string;
  tooltip?: string;
  col?: number;
  items?: {
    label: any;
    value: any;
    checked?: boolean;
  }[];
};

interface Props {
  fields: TField[];
  onSubmit: (data: any) => void;
  onBack: () => void;
  onChangeBookingDate: (date: string) => void;
  onChangeStartTimeSlot: (value: number) => void;
}

const CreateFieldList: React.FC<Props> = ({
  onSubmit,
  fields,
  onBack,
  onChangeBookingDate,
  onChangeStartTimeSlot,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<any>();

  useEffect(() => {
    onChangeBookingDate(watch("booking_date"));
  }, [watch("booking_date")]);

  useEffect(() => {
    onChangeStartTimeSlot(watch("start_slot_time_id"));
  }, [watch("start_slot_time_id")]);

  const onConfirm = (data: any) => {
    Swal.fire({
      icon: "warning",
      html: `ต้องการสร้างใช่ หรือไม่?`,
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        onSubmit(data);
      }
    });
  };

  return (
    <Paper>
      <form onSubmit={handleSubmit(onConfirm)}>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Grid container spacing={2}>
            {fields.map((field, index) => {
              let minDate = undefined;
              if (field.name === "booking_date") {
                minDate = new Date();
              }
              return (
                <Grid key={index + 1} item xs={field.col || 12}>
                  <Field
                    {...field}
                    inputOptions={{ ...field.inputOptions, minDate }}
                    fieldListType={"create"}
                    form={{
                      register,
                      setValue,
                      watch,
                      setError,
                      clearErrors,
                    }}
                    error={!!errors[field.name]}
                    errorMessage={errors[field.name]?.message as string}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Divider />
        <Footer type={"create"} onBack={onBack} />
      </form>
    </Paper>
  );
};

export default CreateFieldList;
