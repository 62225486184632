import * as React from "react";

import {
  Close,
  Delete as DeleteIcon,
  Download,
  Edit as EditIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import TableMUI from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

type MinTableItem = { id: string | number };

type Props<T extends MinTableItem> = {
  headers: {
    key: keyof T;
    label?: string;
    center?: boolean;
    width?: number;
    render?: (value: any, index: number, item: T) => React.ReactNode;
  }[];
  onSearch?: (search: string) => void;
  filters?: {
    type: "select" | "daterange" | "date";
    name: string;
    value: string | { start: Date | null; end: Date | null } | Date | null;
    items?: {
      label: string;
      value: string;
    }[];
    onChange: (
      value: string | { start: Date | null; end: Date | null } | Date | null
    ) => void;
  }[];
  onExport?: () => void;
  data: T[];
  isLoading?: boolean;
  actions?: {
    onDelete?: (item: any, index: number) => void;
    onEdit?: (item: any, index: number) => void;
    onDetail?: (item: any, index: number) => void;
    others?: {
      icon: (item: any, index: number) => React.ReactNode;
      label: (item: any, index: number) => string;
      onClick: (item: any, index: number) => void;
      hidden?: (item: any, index: number) => boolean;
    }[];
    width?: number;
  };
  pagination?: {
    page: number;
    total_page: number;
    total_item: number;
    onChange: (page: number) => void;
  };
  contentHeaders?: React.ReactNode;
  contentFooters?: React.ReactNode;
};

const Table = <T extends MinTableItem>({
  contentHeaders,
  contentFooters,
  headers,
  onSearch,
  filters,
  onExport,
  data,
  isLoading,
  actions,
  pagination,
}: Props<T>) => {
  const [search, setSearch] = React.useState<string>("");

  React.useEffect(() => {
    let onSearchTimeout: any;
    if (onSearch) {
      onSearchTimeout = setTimeout(() => {
        onSearch(search);
      }, 500);
    }
    return () => {
      clearTimeout(onSearchTimeout);
    };
  }, [search, onSearch]);

  const tableWidth = React.useMemo(() => {
    let width = 0;
    headers.map((h) => {
      width += h.width || 0;
    });
    return width;
  }, [headers]);

  return (
    <Paper>
      <Box
        sx={{
          p: 2,
        }}
      >
        {contentHeaders}
        <TableContainer>
          <Box
            sx={{
              pt: 2,
              mb: 2,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              {onSearch && (
                <TextField
                  placeholder="Search"
                  size="small"
                  onChange={(e) => setSearch(e.target.value)}
                />
              )}
            </div>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
              }}
            >
              {filters?.map((filter) => {
                switch (filter.type) {
                  case "daterange":
                    let data: {
                      start: Date | null;
                      end: Date | null;
                    } = filter.value as {
                      start: Date | null;
                      end: Date | null;
                    };
                    return (
                      <Box
                        sx={{
                          mb: 2,
                          ml: 1,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            label={filter.name + " start"}
                            inputFormat="YYYY-MM-DD"
                            value={data.start}
                            onChange={(newValue: Date | null) =>
                              filter.onChange({
                                ...(filter.value as {
                                  start: Date | null;
                                  end: Date | null;
                                }),
                                start: newValue,
                              })
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                value={data.start}
                              />
                            )}
                          />
                          {data.start && (
                            <Button
                              color="error"
                              sx={{ minWidth: "auto", borderRadius: "100px" }}
                              onClick={() =>
                                filter.onChange({
                                  ...(filter.value as {
                                    start: Date | null;
                                    end: Date | null;
                                  }),
                                  start: null,
                                })
                              }
                            >
                              <Close />
                            </Button>
                          )}
                          <Typography sx={{ mx: 1 }}>-</Typography>
                          <DesktopDatePicker
                            label={filter.name + " end"}
                            inputFormat="YYYY-MM-DD"
                            value={data.end}
                            onChange={(newValue: Date | null) =>
                              filter.onChange({
                                ...(filter.value as {
                                  start: Date | null;
                                  end: Date | null;
                                }),
                                end: newValue,
                              })
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                value={data.end}
                              />
                            )}
                          />
                          {data.end && (
                            <Button
                              color="error"
                              sx={{ minWidth: "auto", borderRadius: "100px" }}
                              onClick={() =>
                                filter.onChange({
                                  ...(filter.value as {
                                    start: Date | null;
                                    end: Date | null;
                                  }),
                                  end: null,
                                })
                              }
                            >
                              <Close />
                            </Button>
                          )}
                        </LocalizationProvider>
                      </Box>
                    );
                  case "date":
                    return (
                      <Box
                        sx={{ ml: 1, display: "flex", alignItems: "center" }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            label={filter.name}
                            inputFormat="YYYY-MM-DD"
                            value={filter.value as Date | null}
                            onChange={(newValue: Date | null) =>
                              filter.onChange(newValue)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                value={filter.value}
                              />
                            )}
                          />
                          {filter.value && (
                            <Button
                              color="error"
                              sx={{ minWidth: "auto", borderRadius: "100px" }}
                              onClick={() => filter.onChange(null)}
                            >
                              <Close />
                            </Button>
                          )}
                        </LocalizationProvider>
                      </Box>
                    );
                  case "select":
                    return (
                      <>
                        <FormControl sx={{ ml: 1 }}>
                          <InputLabel id={"label-" + filter.name}>
                            {filter.name}
                          </InputLabel>
                          <Select
                            size="small"
                            labelId={"label-" + filter.name}
                            label={filter.name}
                            value={filter.value}
                            onChange={(e) => filter.onChange(e.target.value)}
                          >
                            {filter.items?.map((item, index) => {
                              return (
                                <MenuItem
                                  key={"select-item-" + item.label}
                                  value={item.value}
                                >
                                  {item.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </>
                    );
                  default:
                    return <></>;
                }
              })}
              {onExport && (
                <Button onClick={onExport}>
                  <Download />
                  Export
                </Button>
              )}
            </Box>
          </Box>
          <Paper
            sx={{
              width: "100%",
              overflowX: "auto",
            }}
          >
            <TableMUI
              sx={{
                width: tableWidth || "100%",
              }}
              aria-label="table"
            >
              <TableHead>
                <TableRow>
                  {headers.map((header, index) => {
                    return (
                      <TableCell
                        key={`header-${header.key as string}`}
                        sx={{
                          backgroundColor: "#F4F6F8",
                          borderRadius:
                            index === 0
                              ? "8px 0px 0px 8px"
                              : !actions && index === headers.length - 1
                              ? "0px 8px 8px 0px"
                              : "0px",
                          width: header.width || "auto",
                        }}
                      >
                        {header.label}
                      </TableCell>
                    );
                  })}
                  {actions && (
                    <TableCell
                      sx={{
                        position: tableWidth? "sticky" : "unset",
                        right: 0,
                        backgroundColor: "#F4F6F8",
                        borderRadius: "0px 8px 8px 0px",
                        width: actions.width || "auto",
                        filter: tableWidth
                              ? "drop-shadow(-10px 10px 10px #F4F6F8)"
                              : "none",
                            "-webkit-box-shadow": tableWidth
                              ? "-10px 10px 10px #F4F6F8"
                              : "none",
                            "box-shadow": tableWidth
                              ? "-10px 10px 10px #F4F6F8"
                              : "none",
                      }}
                    >
                      Actions
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoading &&
                  data.map((item, index) => (
                    <TableRow
                      key={`row-${index}`}
                      sx={{
                        ":hover": {
                          backgroundColor: "rgba(145, 158, 171, 0.08);",
                        },
                      }}
                    >
                      {headers.map((header, indexColumn) => {
                        return (
                          <TableCell
                            key={`column-${indexColumn}`}
                            component="th"
                            scope="row"
                          >
                            <>
                              {header.render
                                ? header.render(item[header.key], index, item)
                                : item[header.key]}
                            </>
                          </TableCell>
                        );
                      })}
                      {actions && (
                        <TableCell
                          sx={{
                            position: tableWidth ? "sticky" : "unset",
                            right: 0,
                            bgcolor: "white",
                            filter: tableWidth
                              ? "drop-shadow(-10px 10px 10px #F4F6F8)"
                              : "none",
                            "-webkit-box-shadow": tableWidth
                              ? "-10px 10px 10px #F4F6F8"
                              : "none",
                            "box-shadow": tableWidth
                              ? "-10px 10px 10px #F4F6F8"
                              : "none",
                          }}
                          component="th"
                          scope="row"
                        >
                          {actions.onDelete && (
                            <Tooltip
                              title="Delete"
                              onClick={() =>
                                actions.onDelete &&
                                actions.onDelete(item, index)
                              }
                            >
                              <IconButton>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {actions.onEdit && (
                            <Tooltip
                              title="Edit"
                              onClick={() =>
                                actions.onEdit && actions.onEdit(item, index)
                              }
                            >
                              <IconButton>
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {actions.onDetail && (
                            <Tooltip
                              title="View"
                              onClick={() =>
                                actions.onDetail &&
                                actions.onDetail(item, index)
                              }
                            >
                              <IconButton>
                                <RemoveRedEyeIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {actions.others &&
                            actions.others.map((action, actionIndex) => {
                              if (action.hidden && action.hidden(item, index)) {
                                return <div key={`action-${actionIndex}`}></div>;
                              } else {
                                return (
                                  <Tooltip
                                    key={`action-${actionIndex}`}
                                    title={action.label(item, index)}
                                    onClick={() => action.onClick(item, index)}
                                  >
                                    <IconButton>
                                      {action.icon(item, index)}
                                    </IconButton>
                                  </Tooltip>
                                );
                              }
                            })}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </TableMUI>
          </Paper>

          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100px",
              }}
            >
              Loading...
            </Box>
          ) : (
            <>
              {data.length <= 0 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100px",
                  }}
                >
                  No data
                </Box>
              )}
            </>
          )}
        </TableContainer>
        {contentFooters}
      </Box>
      {pagination && (
        <>
          <Divider />
          <Box
            sx={{
              py: 1,
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Pagination
              count={pagination.total_page}
              page={pagination.page}
              onChange={(e, page) => pagination.onChange(page)}
              size="large"
            />
          </Box>
        </>
      )}
    </Paper>
  );
};

export default Table;
