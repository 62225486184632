import { Add, Check, KeyboardReturn } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import { ICCKMember } from "interfaces/cckMember";
import dayjs from "dayjs";

interface Props {
  member: ICCKMember;
  step: number;
  onChangeStep: (step: number) => void;
}
const Step2: React.FC<Props> = ({ member, step, onChangeStep }) => {
  return (
    <>
      <Box
        sx={{
          px: 2,
          py: 5,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="First Name"
              value={member.first_name}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Last Name"
              value={member.last_name}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Phone Number"
              value={member.tel}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label="Email" value={member.email} disabled />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Gender"
              value={
                member.gender === "M"
                  ? "Male"
                  : member.gender === "F"
                  ? "Female"
                  : "Other"
              }
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Birth Date"
              value={dayjs(member.birth_date).format("DD/MM/YYYY")}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Credits"
              value={member.credits.toLocaleString()}
              disabled
            />
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          type="button"
          variant="contained"
          size="large"
          color="error"
          onClick={() => onChangeStep(step - 1)}
        >
          <KeyboardReturn
            sx={{
              fontSize: 16,
              mr: 1,
            }}
          />
          <Typography>Back</Typography>
        </Button>
        <Button
          type="button"
          variant="contained"
          size="large"
          color="success"
          onClick={() => onChangeStep(step + 1)}
          disabled={member.credits <= 0}
        >
          <Check
            sx={{
              fontSize: 16,
              mr: 1,
            }}
          />
          <Typography>Confirm</Typography>
        </Button>
      </Box>
    </>
  );
};

export default Step2;
