import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import CouponServices from "services/coupon";
import FieldList from "components/common/FieldList";
import Header from "components/common/Header";
import { ICoupon } from "interfaces/coupon";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

const CouponDetail = () => {
  let line_oa_code = "ONLY_GOLF";
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useToken();
  const { onAlert } = useAlert();

  const [coupon, setCoupon] = useState<ICoupon | null>(null);

  useEffect(() => {
    const fetchData = () => {
      CouponServices.getDetail(
        token as string,
        line_oa_code as string,
        id as string
      )
        .then((res) => {
          setCoupon(res.data.data);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    };

    fetchData();
  }, [id, line_oa_code, onAlert, token]);

  if (coupon) {
    return (
      <>
        <Header
          title="Coupon Details"
          breadcrumbs={[
            {
              label: "Dashboard",
              href: `/dashboard`,
            },
            {
              label: "Only Golf",
              href: `/dashboard/only-golf`,
            },
            {
              label: "Coupon",
              href: `/coupon/only-golf/list`,
            },
            {
              label: coupon.name,
            },
          ]}
        />
        <FieldList
          type="detail"
          fields={[
            {
              label: "Cover Image",
              type: "image",
              name: "image",
              defaultValue: coupon.image,
              col: 6,
            },
            {
              label: "",
              type: "box",
              name: "",
              col: 6,
            },
            {
              label: "Coupon Type",
              type: "text",
              name: "coupon_type",
              defaultValue: coupon.coupon_type.name,
              col: 6,
            },
            {
              label: "",
              type: "box",
              name: "",
              col: 6,
            },
            {
              label: "Coupon Name",
              type: "text",
              name: "name",
              defaultValue: coupon.name,
              col: 6,
            },
            {
              label: "Point",
              type: "number",
              name: "point",
              defaultValue: coupon.point,
              col: 6,
            },
            {
              label: "Start At",
              type: "date",
              name: "started_at",
              defaultValue: coupon.started_at,
              col: 6,
            },
            {
              label: "End At",
              type: "date",
              name: "ended_at",
              defaultValue: coupon.ended_at,
              col: 6,
            },
            {
              label: "Short Description",
              type: "textarea",
              name: "short_description",
              defaultValue: coupon.short_description,
              col: 6,
            },
            {
              label: "Long Description",
              type: "text-editor",
              name: "description",
              defaultValue: coupon.description,
              col: 6,
            },
            {
              label: "1 Time Per Month",
              type: "radiogroup",
              name: "is_user_per_month",
              col: 6,
              defaultValue: coupon.is_user_per_month ? "yes" : "no",
              items: [
                {
                  label: "Yes",
                  value: "yes",
                },
                {
                  label: "No",
                  value: "no",
                },
              ],
            },
            {
              label: "",
              type: "box",
              name: "",
              col: 6,
            },
            {
              label: "Supply",
              type: "number",
              name: "limit",
              defaultValue: coupon.limit,
              col: 6,
            },
            {
              label: "Limit Per User",
              type: "number",
              name: "limit_per_user",
              defaultValue: coupon.limit_per_user,
              col: 6,
            },
          ]}
          onBack={() => navigate(`/coupon/only-golf/list`)}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default withAuth(withPermission(CouponDetail, "MANAGE_ONLY_GOLF"));
