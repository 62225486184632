import {
  Badge,
  Box,
  Button,
  Chip,
  Divider,
  Modal,
  Typography,
} from "@mui/material";
import { IPagination, IPaginationRequest } from "interfaces/pagination";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import AvatarLetter from "components/common/AvatarLetter/AvatarLetter";
import CCKMemberPointServices from "services/cckMemberPoint";
import CCKMemberServices from "services/cckMember";
import { Close } from "@mui/icons-material";
import FieldList from "components/common/FieldList";
import Header from "components/common/Header";
import { ICCKBooking } from "interfaces/cckBooking";
import { ICCKMember } from "interfaces/cckMember";
import { ICCKMemberPoint } from "interfaces/cckMemberPoint";
import { ICCKMemberPointBooking } from "interfaces/cckMemberPointBooking";
import Swal from "sweetalert2";
import Table from "components/common/Table/Table";
import dayjs from "dayjs";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

const MemberDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useToken();
  const { onAlert } = useAlert();

  const [member, setMember] = useState<ICCKMember | null>(null);
  const [memberPoint, setMemberPoint] = useState<{
    data: ICCKMemberPoint[];
    pagination: IPagination;
  }>({
    data: [],
    pagination: {
      page: 1,
      total_item: 0,
      total_page: 0,
    },
  });
  const [memberPointLoading, setMemberPointLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<IPaginationRequest>({
    page: 1,
    per_page: 10,
  });

  useEffect(() => {
    fetchData();
  }, [id, onAlert, token]);

  const fetchData = () => {
    setMember(null);
    CCKMemberServices.getDetail(token as string, id as string)
      .then((res) => {
        setMember(res.data.data);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
      });
  };

  useEffect(() => {
    fetchMemberPoint();
  }, [id, onAlert, token, pagination]);

  const fetchMemberPoint = () => {
    CCKMemberPointServices.getByCCKMemberID(
      token as string,
      pagination,
      parseInt(id as string)
    )
      .then((res) => {
        setMemberPoint(res.data);
        setMemberPointLoading(false);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
        setMemberPointLoading(false);
      });
  };

  const onCancelPoint = (id: number) => {
    Swal.fire({
      icon: "warning",
      html: `Confirm to cancel this credits?`,
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        CCKMemberServices.cancelCredits(
          token as string,
          member?.id as number,
          id
        )
          .then((res) => {
            fetchData();
            fetchMemberPoint();
            onAlert("success", "Cancel credits success");
          })
          .catch((err) => {
            onAlert("error", err.response.data.message);
          });
      }
    });
  };

  return (
    <>
      <Header
        title="Member Details"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: `/dashboard`,
          },
          {
            label: "Only Golf",
            href: `/simulator-bay/dashboard`,
          },
          {
            label: "Member",
            href: `/simulator-bay/member`,
          },
          {
            label: member ? `${member.first_name} ${member.last_name}` : "",
          },
        ]}
      />
      {member ? (
        <Box
          sx={{
            mb: 4,
          }}
        >
          <FieldList
            type="detail"
            fields={[
              {
                label: "Firstname",
                type: "text",
                name: "first_name",
                defaultValue: member?.first_name,
                col: 6,
              },
              {
                label: "Lastname",
                type: "text",
                name: "last_name",
                defaultValue: member?.last_name,
                col: 6,
              },
              {
                label: "Email",
                type: "text",
                name: "email",
                defaultValue: member?.email,
                col: 6,
              },
              {
                label: "Phone number",
                type: "text",
                name: "tel",
                defaultValue: member?.tel,
                col: 6,
              },
              {
                label: "Gender",
                type: "text",
                name: "gender",
                defaultValue:
                  member?.gender === "M"
                    ? "Male"
                    : member.gender === "F"
                    ? "Female"
                    : "Other",
                col: 6,
              },
              {
                label: "Birth date",
                type: "date",
                name: "birth_date",
                defaultValue: member?.birth_date,
                col: 6,
              },
              {
                label: "Credits",
                type: "text",
                name: "credits",
                defaultValue: member?.credits.toLocaleString(),
                col: 6,
              },
            ]}
            onBack={() => navigate(`/simulator-bay/member`)}
          />
        </Box>
      ) : (
        <Typography sx={{ textAlign: "center" }}>Loading...</Typography>
      )}
      <Box>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "bold",
            mb: 2,
          }}
        >
          Credits logs
        </Typography>
        <Table
          headers={[
            {
              key: "current_point",
              label: "Current Point",
              render: (value, index, item) => {
                let expiredDate = new Date(item.expired_at);
                let today = new Date();
                let isExpired = false;
                if (today > expiredDate) {
                  isExpired = true;
                }
                let isUsed = item.current_point === 0;
                let isCanceled = !!item.deleted_at;
                return (
                  <Typography
                    color={isExpired || isCanceled || isUsed ? "gray" : "green"}
                    display="flex"
                  >
                    <Typography
                      color={
                        isCanceled || isExpired || isUsed
                          ? "gray"
                          : item.current_point < item.point
                          ? "red"
                          : "green"
                      }
                    >
                      {item.current_point.toLocaleString()}
                    </Typography>
                    /{item.point.toLocaleString()}
                  </Typography>
                );
              },
            },
            {
              key: "current_point",
              label: "Status",
              render: (value, index, item) => {
                let expiredDate = new Date(item.expired_at);
                let today = new Date();
                let isExpired = false;
                if (today > expiredDate) {
                  isExpired = true;
                }
                let isUsed = item.current_point === 0;
                let isCanceled = !!item.deleted_at;
                return (
                  <Chip
                    label={
                      isCanceled
                        ? "Canceled"
                        : isUsed
                        ? "Used"
                        : isExpired
                        ? "Expired"
                        : item.current_point < item.point
                        ? "Using"
                        : "Available"
                    }
                    color={
                      isCanceled || isUsed
                        ? "default"
                        : isExpired
                        ? "error"
                        : item.current_point < item.point
                        ? "warning"
                        : "success"
                    }
                  />
                );
              },
            },
            {
              key: "expired_at",
              label: "Expired At",
              render: (value) => (
                <>{dayjs(value).add(543, "year").format("DD/MM/YYYY")}</>
              ),
            },
            {
              key: "created_at",
              label: "Created At",
              render: (value) => (
                <>{dayjs(value).add(543, "year").format("DD/MM/YYYY")}</>
              ),
            },
            {
              key: "updated_at",
              label: "Updated At",
              render: (value) => (
                <>{dayjs(value).add(543, "year").format("DD/MM/YYYY")}</>
              ),
            },
            {
              key: "deleted_at",
              label: "Canceled At",
              render: (value) => (
                <>
                  {value
                    ? dayjs(value).add(543, "year").format("DD/MM/YYYY")
                    : "-"}
                </>
              ),
            },
            {
              key: "admin",
              label: "Logs",
              render: (value, index, item) => (
                <ButtonShowPointBookings value={item} />
              ),
            },
            {
              key: "admin",
              label: "Edited By",
              render: (value) => <AvatarLetter label={value.name} tooltip />,
            },
          ]}
          actions={{
            others: [
              {
                icon(item, index) {
                  return <Close />;
                },
                label(item, index) {
                  return "Cancel";
                },
                onClick(item, index) {
                  onCancelPoint(item.id);
                },
                hidden(item, index) {
                  let expiredDate = new Date(item.expired_at);
                  let today = new Date();
                  let isExpired = false;
                  let isCanceled = !!item.deleted_at;
                  if (today > expiredDate) {
                    isExpired = true;
                  }
                  return isExpired || isCanceled;
                },
              },
            ],
          }}
          data={memberPoint.data}
          isLoading={memberPointLoading}
          pagination={
            memberPoint.pagination
              ? {
                  ...memberPoint.pagination,
                  onChange: (page) =>
                    setPagination((pagination) => {
                      return {
                        ...pagination,
                        page,
                      };
                    }),
                }
              : undefined
          }
        />
      </Box>
    </>
  );
};

const ButtonShowPointBookings = ({ value }: { value: ICCKMemberPoint }) => {
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [bookings, setBookings] = useState<ICCKMemberPointBooking[]>([]);

  useEffect(() => {
    if (isOpen) {
      CCKMemberPointServices.getBookings(token as string, value.id).then(
        (res) => {
          console.log(res.data);
          setBookings(res.data.data);
          setIsLoading(false);
        }
      );
    } else {
      setBookings([]);
      setIsLoading(true);
    }
  }, [isOpen, value]);

  return (
    <>
      <Button
        variant="contained"
        color="success"
        onClick={() => setIsOpen(true)}
      >
        Logs
      </Button>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            width: "800px",
            display: "inline",
            mx: "auto",
            py: 2,
            px: 4,
            borderRadius: "12px",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              mb: 2,
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            Logs
          </Typography>
          <Table
            headers={[
              {
                key: "booking",
                label: "Booking ID",
                render: (value: ICCKBooking, index, item) => {
                  return (
                    <Box
                      sx={{
                        color: "white",
                        backgroundColor: "#EF2F2C",
                        borderRadius: "8px",
                        p: 1,
                        fontWeight: 700,
                        display: "inline-block",
                      }}
                    >
                      {value.code}
                    </Box>
                  );
                },
              },
              {
                key: "booking",
                label: "Booking details",
                render: (value: ICCKBooking, index, item) => (
                  <Box>
                    <Box>
                      <Typography fontWeight="bold" component="span">
                        Date:{" "}
                      </Typography>
                      <Typography component="span">
                        {dayjs(new Date(value.booking_date)).format(
                          "DD/MM/YYYY"
                        )}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography fontWeight="bold" component="span">
                        Time:{" "}
                      </Typography>
                      <Typography component="span">{`${value.booking_start_time} - ${value.booking_end_time}`}</Typography>
                    </Box>
                  </Box>
                ),
              },
              {
                key: "booking",
                label: "Status",
                render: (value: ICCKBooking) => {
                  let expiredDate = new Date(
                    `${value.booking_date} ${value.booking_start_time}`
                  );
                  let today = new Date();
                  let isExpired = false;
                  if (today >= expiredDate) {
                    isExpired = true;
                  }
                  let isCanceled = !!value.deleted_at;
                  return (
                    <Chip
                      label={
                        isCanceled ? "Canceled" : isExpired ? "Done" : "Active"
                      }
                      color={
                        isCanceled ? "error" : isExpired ? "info" : "success"
                      }
                    />
                  );
                },
              },
              {
                key: "point",
                label: "Used",
              },
              {
                key: "created_at",
                label: "Used At",
                render: (value, index, item) => {
                  return (
                    <Typography component="span">
                      {dayjs(new Date(value)).format("DD/MM/YYYY")}
                    </Typography>
                  );
                },
              },
              // {
              //   key: "created_at",
              //   label: "Created At",
              //   render: (value) => (
              //     <>{dayjs(value).add(543, "year").format("DD/MM/YYYY")}</>
              //   ),
              // },
              // {
              //   key: "updated_at",
              //   label: "Updated At",
              //   render: (value) => (
              //     <>{dayjs(value).add(543, "year").format("DD/MM/YYYY")}</>
              //   ),
              // },
              // {
              //   key: "deleted_at",
              //   label: "Canceled At",
              //   render: (value) => (
              //     <>
              //       {value
              //         ? dayjs(value).add(543, "year").format("DD/MM/YYYY")
              //         : "-"}
              //     </>
              //   ),
              // },
              // {
              //   key: "admin",
              //   label: "Logs",
              //   render: (value, index, item) => (
              //     <ButtonShowPointBookings value={item} />
              //   ),
              // },
              // {
              //   key: "admin",
              //   label: "Edited By",
              //   render: (value) => <AvatarLetter label={value.name} tooltip />,
              // },
            ]}
            // actions={{
            //   others: [
            //     {
            //       icon(item, index) {
            //         return <Close />;
            //       },
            //       label(item, index) {
            //         return "Cancel";
            //       },
            //       onClick(item, index) {
            //         onCancelPoint(item.id);
            //       },
            //       hidden(item, index) {
            //         let expiredDate = new Date(item.expired_at);
            //         let today = new Date();
            //         let isExpired = false;
            //         let isCanceled = !!item.deleted_at;
            //         if (today > expiredDate) {
            //           isExpired = true;
            //         }
            //         return isExpired || isCanceled;
            //       },
            //     },
            //   ],
            // }}
            data={bookings}
            isLoading={isLoading}
            // pagination={
            //   memberPoint.pagination
            //     ? {
            //         ...memberPoint.pagination,
            //         onChange: (page) =>
            //           setPagination((pagination) => {
            //             return {
            //               ...pagination,
            //               page,
            //             };
            //           }),
            //       }
            //     : undefined
            // }
          />
          <Divider sx={{ my: 2 }} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              type="button"
              variant="contained"
              onClick={() => setIsOpen(false)}
              color="error"
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default withAuth(withPermission(MemberDetail, "MANAGE_SIMULATOR_BAY"));
