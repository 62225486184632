import { Add, KeyboardReturn } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BookingBay from "./BookingBay";
import CCKBayServices from "services/cckBay";
import { ICCKBay } from "interfaces/cckBay";
import { ICCKMember } from "interfaces/cckMember";
import { useSearchParams } from "react-router-dom";
import useToken from "hooks/useToken";

interface Props {
  member: ICCKMember;
  step: number;
  onChangeStep: (step: number) => void;
  onSubmit: (data: {
    member_id: number;
    booking_date: string;
    booking_bays: {
      bay_id: number;
      start_time: string;
      total_hour: number;
    }[];
  }) => void;
}
const Step3: React.FC<Props> = ({ member, step, onChangeStep, onSubmit }) => {
  const { token } = useToken();
  const [searchParams, setSearchParams] = useSearchParams();
  const [bookingDate, setBookingDate] = useState<string>(
    dayjs(new Date()).format("YYYY-MM-DD")
  );
  const [bookingBays, setBookingBays] = useState<
    {
      bay_id: number;
      start_time: string;
      total_hour: number;
    }[]
  >([]);
  const [bays, setBays] = useState<ICCKBay[]>([]);

  useEffect(() => {
    let date =
      searchParams.get("date") || dayjs(new Date()).format("YYYY-MM-DD");
    let start_time = searchParams.get("start_time") || "";
    let bay_id = parseInt(searchParams.get("bay_id") || "0");
    let booking_date = bookingDate
    if (bookingBays.length === 0) {
      setBookingDate(date);
      booking_date = date
    }
    CCKBayServices.getAllActive(token as string, booking_date)
      .then((res) => {
        let baysData: ICCKBay[] = res.data.data;
        setBays(baysData);
        if (bookingBays.length === 0) {
          let hasBay = false;
          baysData.forEach((bayData) => {
            if (bayData.id === bay_id) {
              hasBay = true;
            }
          });
          if (!hasBay) {
            bay_id = baysData.length > 0 ? baysData[0].id : 0;
          }
          setBookingBays([
            ...bookingBays,
            {
              bay_id: bay_id,
              start_time: start_time || "",
              total_hour: 0,
            },
          ]);
        }
      })
      .catch(() => {});
  }, [bookingDate]);

  return (
    <>
      <Box
        sx={{
          px: 2,
          py: 5,
        }}
      >
        <Box
          sx={{
            mb: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "bold",
              mb: 2,
            }}
          >
            Member booking detail
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="First Name"
                value={member.first_name}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Last Name"
                value={member.last_name}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Phone Number"
                value={member.tel}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Email"
                value={member.email}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Credits"
                value={member.credits.toLocaleString()}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  label="Booking date"
                  inputFormat="YYYY-MM-DD"
                  onChange={(newValue: Dayjs | null) => {
                    setBookingDate(newValue?.format("YYYY-MM-DD") as string);
                  }}
                  value={bookingDate}
                  minDate={new Date() as any}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} value={bookingDate} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Box>
        {bookingBays.map((bookingBay, index) => {
          return (
            <BookingBay
              bays={bays}
              member={member}
              index={index}
              bookingDate={bookingDate}
              bookingBay={bookingBay}
              onChange={(data, index) => {
                bookingBays[index] = data;
                setBookingBays([...bookingBays]);
              }}
              onRemove={
                bookingBays.length > 1
                  ? (index) => {
                      setBookingBays((bookingBays) =>
                        bookingBays.filter((bb, indexBB) => indexBB !== index)
                      );
                    }
                  : null
              }
            />
          );
        })}
        <Button
          fullWidth
          variant="outlined"
          size="large"
          onClick={() => {
            setBookingBays([
              ...bookingBays,
              {
                bay_id: bays[0].id,
                start_time: "",
                total_hour: 0,
              },
            ]);
          }}
        >
          <Add />
          Add item
        </Button>
      </Box>
      <Divider />
      <Box
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          type="button"
          variant="contained"
          size="large"
          color="error"
          onClick={() => onChangeStep(step - 1)}
        >
          <KeyboardReturn
            sx={{
              fontSize: 16,
              mr: 1,
            }}
          />
          <Typography>Back</Typography>
        </Button>
        <Button
          type="button"
          variant="contained"
          size="large"
          color="success"
          onClick={() =>
            onSubmit({
              member_id: member.id,
              booking_date: bookingDate,
              booking_bays: bookingBays,
            })
          }
        >
          <Add
            sx={{
              fontSize: 16,
              mr: 1,
            }}
          />
          <Typography>Verify</Typography>
        </Button>
      </Box>
    </>
  );
};

export default Step3;
