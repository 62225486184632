import RewardServices, { TCreateRewardData } from "services/reward";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import FieldList from "components/common/FieldList";
import FileServices from "services/file";
import Header from "components/common/Header";
import { IReward } from "interfaces/reward";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";

type TEditReward = {
  image: FileList | undefined;
  image_disable: FileList | undefined;
  spend: string;
  cashback: string;
};

const EditReward = () => {
  let line_oa_code = "ONLY_GOLF";
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useToken();
  const { onAlert } = useAlert();

  const [reward, setReward] = useState<IReward | null>(null);

  useEffect(() => {
    const fetchData = () => {
      RewardServices.getDetail(
        token as string,
        line_oa_code as string,
        id as string
      )
        .then((res) => {
          setReward(res.data.data);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    };

    fetchData();
  }, [id, line_oa_code, onAlert, token]);

  const onSubmit = async (values: TEditReward) => {
    let data: TCreateRewardData = {
      image: reward?.image as string,
      image_disable: reward?.image_disable as string,
      spend: parseInt(values.spend),
      cashback: parseInt(values.cashback),
    };

    let isError = false;
    if (values.image && values.image[0]) {
      let formDataImage = new FormData();
      formDataImage.append("file", values.image[0]);
      formDataImage.append("prefix", "reward");
      formDataImage.append("path", "/reward");
      await FileServices.create(token as string, formDataImage)
        .then((res) => {
          data.image = res.data.data;
        })
        .catch((err) => {
          isError = true;
          onAlert("error", err.response.data.message);
        });
    }
    if (values.image_disable && values.image_disable[0]) {
      let formDataImageDisable = new FormData();
      formDataImageDisable.append("file", values.image_disable[0]);
      formDataImageDisable.append("prefix", "reward");
      formDataImageDisable.append("path", "/reward");
      await FileServices.create(token as string, formDataImageDisable)
        .then((res) => {
          data.image_disable = res.data.data;
        })
        .catch((err) => {
          isError = true;
          onAlert("error", err.response.data.message);
        });
    }
    if (!isError) {
      RewardServices.edit(
        token as string,
        line_oa_code as string,
        id as string,
        data
      )
        .then((res) => {
          onAlert("success", "แก้ไขรางวัลสำเร็จ");
          navigate(`/reward/only-golf/list`);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    }
  };

  if (reward) {
    return (
      <>
        <Header
          title="Edit Reward"
          breadcrumbs={[
            {
              label: "Dashboard",
              href: `/dashboard`,
            },
            {
              label: "Only Golf",
              href: `/dashboard/only-golf`,
            },
            {
              label: "Reward",
              href: `/reward/only-golf/list`,
            },
            {
              label: "Cashback " + reward.cashback,
            },
          ]}
        />
        <FieldList
          type="edit"
          onSubmit={onSubmit}
          fields={[
            {
              label: "Cover Image (Available)",
              type: "image",
              name: "image",
              defaultValue: reward.image,
              col: 6,
              tooltip:
                "สำหรับกำหนดรูปภาพแสดงผลของรางวัล เมื่อลูกค้ามียอดสั่งซื้อถึงจำนวนกำหนด",
            },
            {
              label: "Cover Image (Disable)",
              type: "image",
              name: "image_disable",
              defaultValue: reward.image_disable,
              col: 6,
              tooltip:
                "สำหรับกำหนดรูปภาพแสดงผลของรางวัล เมื่อลูกค้ายอดสั่งซื้อไม่ถึงจำนวนที่กำหนด",
            },
            {
              label: "Spending",
              type: "text",
              name: "spend",
              inputOptions: {
                required: "กรุณากรอกยอดสั่งซื้อสะสมที่ต้องใช้",
              },
              defaultValue: reward.spend,
              col: 6,
              tooltip:
                "สำหรับกำหนดยอดสั่งซื้อสะสมของรางวัล ที่ลูกค้าต้องสะสมให้ถึงจำนวนเพื่อรับรางวัล",
            },
            {
              label: "Cashback",
              type: "text",
              name: "cashback",
              inputOptions: {
                required: "กรุณากรอกเงินรางวัล",
              },
              defaultValue: reward.cashback,
              col: 6,
              tooltip: "สำหรับกำหนดจำนวนเงินรางวัลที่ลูกค้าจะได้รับ",
            },
          ]}
          onBack={() => navigate(`/reward/only-golf/list`)}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default withAuth(withPermission(EditReward, "MANAGE_ONLY_GOLF"));
